<script setup lang="ts">
// components
import DeskTop from './DeskTop.vue';
import DeskTopHeader from './DeskTopHeader.vue';
import { QuestionViewerEditor } from '@/components/kit/QuestionViewer';
import { Label } from '@/components/kit';
// store
import { useUserStore } from '@/store';
const userStore = useUserStore();


const logoutBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    userStore.logout()
}
</script>


<template>
    <DeskTop>
        <DeskTopHeader>
            <h1>Account</h1>
        </DeskTopHeader>

        <div class="options-bar">
            <div class="options-bar-box">
                <div class="box">
                    <div class="box-title">
                        <h2><i class="icon-cog" style="padding-right: 5px;"></i>Options</h2>
                    </div>

                    <div class="box-body">
                        <div class="user-page-opts">
                            <div class="form-group">
                                <Label for="account-page-name">name</Label>
                                <QuestionViewerEditor id="account-page-name" v-model="userStore.name"/>
                            </div>
                            <div class="form-group">
                                <Label for="account-page-email">email</Label>
                                <QuestionViewerEditor id="account-page-email" v-model="userStore.email"/>
                            </div>
                            <div class="form-group">
                                <Label for="account-page-telegram">telegram</Label>
                                <QuestionViewerEditor id="account-page-telegram" v-model="userStore.telegram"/>
                            </div>
                            <div class="form-group">
                                <button class="ui-btn" @click="logoutBtnClickHandler" id="logout-btn">
                                    <i class="icon-cancel-circled"></i>
                                    <span>Sign out</span>
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </DeskTop>

</template>

<style lang="scss">
.user-page-opts {
    max-width: 400px;

    #logout-btn {
        background: #ff000080;
        width: 95px;
    }
}
</style>