import { defineStore } from "pinia";
import LogsService from "../service";

export type TDataState = {
    $loading: boolean;
    pipelineName: string;
    pipelineId: string;
	logId: string;
	logs: string;
	createdAt: string;
	updatedAt: string;
	isSuccess: boolean;
}

export const useLogsStore = defineStore("logs", {

    state: (): TDataState => ({
        $loading: true,
        pipelineName: '',
        pipelineId: '',
        logId: '',
        logs: '',
        createdAt: '',
        updatedAt: '',
        isSuccess: false,
    }),

    actions: {
        async getData(pipelineId: string, logId: string) {
            this.$loading = true;
            LogsService.getLogItem(pipelineId, logId).then(log => {
                // mapping
                const mappedData: TDataState = {
                    $loading: this.$loading,
                    pipelineName: log.pipeline_name,
                    pipelineId: log.pipeline_id,
                    logId: log.log_id,
                    logs: log.logs,
                    createdAt: log.created_at,
                    updatedAt: log.updated_at,
                    isSuccess: log.is_success,
                }
                Object.assign(this, mappedData);
                
            })
            .catch((e)=>{
                alert(`Get log data error.\n${e?.error}\n${e?.code}\n${e?.requestId}`)
            })
            .finally(() => {
                this.$loading = false;
            })
        }
    },
});
