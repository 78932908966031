<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { appBus } from '@/utils';
// components
import JobConsole from './JobConsole.vue';
import JobOptionsEditor from './JobOptionsEditor.vue';
import JobCode from './JobCode.vue';
import JobCodeText from './JobCodeText.vue';
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


// get active tab worker
const getActiveWorker = () => navyStore.chainPage?.workersList.find(i => i.active);
const getStoreActiveWorker = () => {
    if (!worker.value) { return void 0 }
    const id = worker.value.id;
    return store.jobs?.find(i => i.id === id);
}
const worker = ref(getActiveWorker());
const storeWorker = ref(getStoreActiveWorker());
watch(getActiveWorker, (aw) => { 
    worker.value = aw;
    storeWorker.value = getStoreActiveWorker();
});

// navy
onMounted(()=>{
    const openOptionsHandler = ()=>changeWorkSpaceType('options');
    const openPayloadHandler = ()=>changeWorkSpaceType('code');
    const openUnionHandler = ()=>changeWorkSpaceType('split');
    const toggleConsoleHandler = ()=>{
        if (!worker.value) { return }
        if (worker.value.consoleOpened) {
            worker.value.consoleMaximized = false;
            worker.value.consoleOpened = false;
        }
        else {
            worker.value.consoleOpened = true;
        }
    };

    appBus.subscribe(appBus.eventList.optionsToggle, openOptionsHandler);
    appBus.subscribe(appBus.eventList.payloadToggle, openPayloadHandler);
    appBus.subscribe(appBus.eventList.unionToggle, openUnionHandler);
    appBus.subscribe(appBus.eventList.consoleToggle, toggleConsoleHandler);
    appBus.subscribe(appBus.eventList.ctrlS, saveWorkerHandler);

    onUnmounted(()=>{
        appBus.unsubscribe(appBus.eventList.optionsToggle, openOptionsHandler);
        appBus.unsubscribe(appBus.eventList.payloadToggle, openPayloadHandler);
        appBus.unsubscribe(appBus.eventList.unionToggle, openUnionHandler);
        appBus.unsubscribe(appBus.eventList.consoleToggle, toggleConsoleHandler);
        appBus.unsubscribe(appBus.eventList.ctrlS, saveWorkerHandler);
    });
});

// handlers
const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    saveWorkerHandler();
}

const saveWorkerHandler = () => {
    if (!worker.value || !storeWorker.value) { return }
    if (!worker.value.unsaved) { return }

    if (worker.value.options.codeInvalid) {
        alert("The worker`s code contains errors.\nSaving is not possible!");
        return;
    }

    store.updateJob({
        id: storeWorker.value.id,
        pipelineId: store.pipelineId,
        name: worker.value.name,
        type: storeWorker.value.type,
        data: storeWorker.value.data,
        code: worker.value.options.code,
        env: worker.value.options.env,
        enabled: !!worker.value.enabled,
        returnedData: '',
        tested: storeWorker.value.tested,

        // hack
        $loading: storeWorker.value.$loading,
        $saved: storeWorker.value.$saved,
        $testing: storeWorker.value.$testing,
    });
}

const changeWorkSpaceType = (type: 'options' | 'code' | 'split')=>{
    if (!worker.value) { return }
    worker.value.editorViewType = type;
}

const consoleOpenClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!worker.value) { return }
    worker.value.consoleOpened = true;
}
</script>


<template>
    <div class="options-bar options-bar-chain-page" v-if="worker && navyStore.chainPage">
        <div class="options-bar-box">
            <div class="box">
                <!-- worker editor navy -->
                <div class="box-title">
                    <div class="ctrl-btns">
                        <button @click="changeWorkSpaceType('options')" title="Worker`s options" class="ui-btn">
                            <i class="icon-cog"></i>
                            <span>Options</span>
                        </button>

                        <button @click="changeWorkSpaceType('code')" title="Worker`s code" class="ui-btn">
                            <i class="icon-code"></i>
                            <span>Code</span>
                        </button>

                        <button v-if="!worker.consoleOpened" @click="consoleOpenClickHandler" title="Test console" class="ui-btn">
                            <i class="icon-terminal"></i>
                            <span>Console</span>
                        </button>

                        <button @click="changeWorkSpaceType('split')" title="Split Editor right" class="ui-btn">
                            <i class="icon-columns"></i>
                        </button>
                    </div>
                </div>

                <!-- editor -->
                <div class="box-body pipeline-work-space" :class="`--${worker.editorViewType || 'split'}`">
                    <div class="options">
                        <JobOptionsEditor :key="worker.id" />
                    </div>
                    <div class="code">
                        <JobCodeText v-if="worker.type === 'text'" :key="worker.id" />
                        <JobCode v-else :key="worker.id" />
                    </div>
                </div>

                <!-- save btn -->
                <div id="save-btn">
                    <button class="ui-btn" title="Save chain" :disabled="!worker.unsaved" @click="saveBtnClickHandler">
                        <i class="icon-ok"></i>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <JobConsole v-if="worker && navyStore.chainPage" :key="worker.id" />
</template>