export * from './dates';
export * from './string';
export * from './code';
export * from './localStorage';
export * from './appBus';
export * from './number';
export * from './url';
export * from './workers';

export const getUniqId = () => {
    return 'u' + Math.random().toString(36).slice(2)
}

export const comparingRecordObjects = (a: Record<string, string>, b: Record<string, string>): boolean => {
    let result = true;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    new Array( Math.max( aKeys.length, bKeys.length ) )
        .fill(1)
        .forEach((i, k)=>{
            if (!aKeys?.[k] || !bKeys?.[k]) {
                result = false;
                return;
            }

            const key = aKeys[k];
            if ( a?.[key] !== b?.[key] ) {
                result = false;
                return;
            }
        });

    return result;
}