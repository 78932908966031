import { defineStore } from "pinia";
import Service from "../service";
import { useNavyStore } from '@/store';


export type TJob = {
    id: string;
    pipelineId: string;
    name: string;
    type: string;
    data: string;
    code: string;
    env: Record<string, string>;
    enabled: boolean;
    returnedData: string;
    tested: boolean;
    testResult?: {
        status: boolean;
        result: string;
        error_message?: string;
        console_log: string[];
        duration: number;
    };

    $loading: boolean;
    $saved: boolean;
    $testing?: boolean;
};

export type TDataState = {
    $loading: boolean;
    $loadingError: string;
    $testing?: boolean;

    pipelineId: string;
    pipelineName: string;

    trigger: {
        type: string;
        options: Record<string, string>;
    };

    env: Record<string, string>;

    jobs: TJob[];

    version: string;
    tested: boolean;
    status: boolean;
    stoppedVersion: string;
    errorCounter: number;

    onerrorWorker: string;
    serviceStoreID: string;

    testResult?: {
        execute_id: string;
        pipeline_id: string;
        pipeline_name: string;
        pipeline_version: string;
        status: boolean;
        start_date: string;
        end_date: string;
        duration: number;
        logs: {
            job_id: string;
            job_name: string;
            status: boolean;
            duration: number;
            console_log: any[];
            error_message?: string;
        }[];
        returned_data: string;
    }

    createdAt: string;
    updatedAt: string;
}

export enum ETriggerTypes {
    webhook = 'webhook',
    cron = 'cron',
    syncWebhook = 'sync-webhook'
}

export const triggerTypes = Object.values(ETriggerTypes) as string[];

export const usePipelineStore = defineStore("pipeline", {

    state: (): TDataState => ({
        $loading: true,
        $loadingError: '',

        pipelineId: '',
        pipelineName: '',

        trigger: {
            type: '',
            options: {}
        },

        env: {},

        jobs: [],

        version: '',
        tested: false,
        status: false,
        stoppedVersion: '',
        errorCounter: 0,

        onerrorWorker: '',
        serviceStoreID: '',

        createdAt: '',
        updatedAt: '',
    }),


    actions: {
        async getPipeline(pipelineId: string, meta?: { updateJobs?: boolean; updatedJobId?: string }) {
            this.$loading = true;

            Service.getPipeline(pipelineId)
                .then(responseData => {
                    this.$loadingError = '';

                    let mappedJobs = [...this.jobs];
                    if (meta?.updateJobs) {
                        responseData.jobs.forEach(item => {
                            const found = mappedJobs.find(i => i.id === item.id);
                            if (found) {
                                found.tested = item.tested;
                                found.enabled = item.enabled;
                                found.data = item.data;
                            }
                        });
                    } else {
                        mappedJobs = responseData.jobs.map(item => {
                            return {
                                id: item.id,
                                pipelineId: item.pipeline_id,
                                type: item.type,
                                name: item.name,
                                code: item.code,
                                env: item.env,
                                data: item.data,
                                enabled: item.enabled,
                                returnedData: item.returned_data,
                                tested: item.tested,
                                $loading: false,
                                $saved: true,
                            }
                        })
                    }

                    // keep test results
                    mappedJobs.forEach((item, index) => {
                        const found = this.jobs.find(i => i.id === item.id);
                        if (found) {
                            mappedJobs[index].testResult = found.testResult
                        }
                    })

                    const pipelineData = {

                        pipelineId: responseData.id,
                        pipelineName: responseData.name,

                        trigger: {
                            type: triggerTypes.includes(responseData.trigger.type) ? responseData.trigger.type : 'unknown',
                            options: responseData.trigger.options
                        },

                        env: JSON.parse(JSON.stringify(responseData.env)),

                        version: responseData.version,
                        tested: responseData.tested,
                        status: responseData.enabled,
                        stoppedVersion: responseData.stopped_version,
                        errorCounter: responseData.error_counter,

                        jobs: mappedJobs,

                        onerrorWorker: responseData.onerror_worker,
                        serviceStoreID: responseData.service_store_id,

                        createdAt: responseData.created_at,
                        updatedAt: responseData.updated_at,
                    }


                    Object.assign(this, pipelineData);
                })
                .catch((e) => {
                    this.$loadingError = `${e.code} - ${e.error}. Request ID ${e.requestId}`;
                })
                .finally(() => {
                    chainNavyUpdate({ afterJobUpdate: !!meta?.updateJobs, updatedJobId: meta?.updatedJobId });
                    this.$loading = false;
                })
        },

        async updatePipeline() {
            this.$loading = true;
            Service.updatePipeline(this.pipelineId, {
                name: this.pipelineName,
                trigger: {
                    type: this.trigger.type,
                    options: this.trigger.options
                },
                env: this.env,
                enabled: this.status,
            }).then(() => {
                this.getPipeline(this.pipelineId)
            }).catch((e) => {
                alert('update pipeline error');
                console.log('update pipeline error');
                console.log(e);
            }).finally(() => {
                this.$loading = false;
            })
        },

        async createJob(newJob: TJob, parentJob?: TJob): Promise<void> {
            const job = JSON.parse(JSON.stringify(newJob));
            job.$loading = true;
            job.pipelineId = this.pipelineId;
            job.data = parentJob?.returnedData || '';

            const newJobIndex = this.jobs.findIndex(i => i.id === parentJob?.id) + 1;
            this.jobs.splice(newJobIndex, 0, job);
            const storeInJob = this.jobs[newJobIndex];

            Service.createJob({
                parent_id: (parentJob && newJobIndex) ? parentJob.id : void 0,
                pipeline_id: this.pipelineId,
                code: job.code,
                env: job.env,
                name: job.name,
                type: job.type,
            })
                .then(r => {
                    storeInJob.id = r.id
                    this.getPipeline(this.pipelineId, { updateJobs: true });
                })
                .catch(() => {
                    alert('create job error');
                    this.deleteJobFromStoreById(storeInJob.id)
                })
                .finally(() => {
                    storeInJob.$loading = false;
                });
        },

        async updateJob(job: TJob): Promise<void> {

            const jobInStore = this.jobs.find(i => i.id === job.id);
            if (!jobInStore) { return }

            Object.assign(jobInStore, {
                ...JSON.parse(JSON.stringify(job)),
                $loading: true,
            });

            const mappedData = {
                pipeline_id: job.pipelineId,
                type: job.type,
                name: job.name,
                env: job.env,
                data: job.data,
                code: job.code,
                enabled: job.enabled,
            }
            Service.updateJob(this.pipelineId, job.id, mappedData)
                .then(response => {
                    jobInStore.tested = response.tested;
                    this.getPipeline(this.pipelineId, { updateJobs: true, updatedJobId: job.id, });
                })
                .catch(() => {
                    alert('update error');
                })
                .finally(() => {
                    jobInStore.$loading = false;
                })
        },

        async runJobTest(jobId: string, testData: string, jobEnv: Record<string, string>, jobType: string, jobCode: string) {
            const job = this.jobs.find(item => item.id === jobId);
            if (!job) throw new Error(`Job not found: ${jobId}`);
            job.$testing = true;
            Service.runJobTest({
                pipeline_env: this.env,
                data: testData,
                env: jobEnv,
                type: jobType,
                code: jobCode,
                pipeline_id: this.pipelineId,
            })
                .then(r => {
                    job.testResult = {
                        status: r.status,
                        result: r.result ?? '',
                        error_message: r.error_message,
                        console_log: r.console_log,
                        duration: r.duration
                    };
                })
                .catch((e: Error) => {
                    alert("job test error: " + e.message)
                })
                .finally(() => {
                    job.$testing = false;
                })
        },

        async runPipelineTest(data: string) {
            this.$testing = true;
            Service.runPipelineTest(this.pipelineId, data)
                .then(r => {
                    this.testResult = {
                        execute_id: r.execute_id,
                        pipeline_id: r.pipeline_id,
                        pipeline_name: r.pipeline_name,
                        pipeline_version: r.pipeline_version,
                        status: r.status,
                        start_date: r.start_date,
                        end_date: r.end_date,
                        duration: r.duration,
                        logs: r.logs.map((log) => ({
                            job_id: log.job_id,
                            job_name: log.job_name,
                            status: log.status,
                            duration: log.duration,
                            console_log: log.console_log,
                            error_message: log.error_message
                        })),
                        returned_data: r.returned_data,
                    };
                    Service.getPipeline(this.pipelineId).then(r => {
                        this.tested = r.tested;
                        this.updatedAt = r.updated_at;

                        this.jobs.forEach(job=>{
                            const responseJob = r.jobs.find(j=>j.id === job.id);
                            if (!responseJob) { return }
                            job.data = responseJob.data;
                            job.tested = responseJob.tested;
                        })
                    }).catch((e: Error) => {
                        alert("test update pipeline error: " + e.message)
                        console.log("test update pipeline error")
                        console.log(e)
                    })
                })
                .catch((e: Error) => {
                    alert("pipeline test error: " + e.message)
                    console.log("pipeline test error")
                    console.log(e)
                })
                .finally(() => {
                    this.$testing = false;
                })
        },

        deleteJobFromStoreById(jobId: string) {
            const jobIndex = this.jobs.findIndex(i => i.id === jobId);
            if (~jobIndex) {
                this.jobs.splice(jobIndex, 1);
            }
        },

        setUntestedAfter(job: TJob) {
            const jobIndex = this.jobs.findIndex(i => i.id === job.id);
            if (~jobIndex) {
                const length = this.jobs.length;
                for (let i = jobIndex; i < length; i++) {
                    this.jobs[i].tested = false
                }
            }
        }
    },
});


const chainNavyUpdate = (
    { afterJobUpdate, updatedJobId }: {
        afterJobUpdate?: boolean,
        afterPipelineUpdate?: boolean,
        updatedJobId?: string,
    }
) => {
    const pipeStore = usePipelineStore();
    const navyStore = useNavyStore();

    const chainUnsaved = (updatedJobId || afterJobUpdate) ? navyStore?.chainPage?.unsaved : false;

    navyStore.updateChainNavy({
        chainId: pipeStore.pipelineId,
        name: pipeStore.pipelineName,
        enabled: pipeStore.status,

        unsaved: chainUnsaved,

        options: {
            env: JSON.parse(JSON.stringify(pipeStore.env)),
            trigger: JSON.parse(JSON.stringify(pipeStore.trigger)),
        },

        workersList: pipeStore.jobs.map(worker => {
            const openedWorker = navyStore.chainPage?.workersList.find(i=>i.id === worker.id && i.opened);

            if (openedWorker) {
                if (updatedJobId && updatedJobId === openedWorker.id) {
                    openedWorker.unsaved = false;
                }
                return openedWorker
            }

            return {
                id: worker.id,
                name: worker.name,
                enabled: worker.enabled,
                type: worker.type,

                options: {
                    data: worker.data,
                    code: worker.code,
                    env: JSON.parse(JSON.stringify(worker.env)),
                },

            }
        })
    })
}