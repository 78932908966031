<script setup lang="ts">

</script>


<template>
    <div class="desktop">
        <slot></slot>
    </div>
</template>


<style lang="scss">
.desktop {
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;

    .options-bar {
        // background: rgb(59, 59, 59);
        height: 100%;
        overflow: hidden;
        transition-duration: .3s;
        position: relative;

        .options-bar-box {
            background-color: rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            .box-title {
                .ctrl-btns {
                    display: flex;
                    margin: 0 -5px;

                    .ui-btn {
                        margin: 0 5px;
                    }
                }
            }
        }
    }

    .console-bar {
        // background: rgb(22, 22, 22);
        height: 0%;
        overflow: hidden;
        transition-duration: .3s;

        .console-bar-box {
            background-color: rgba(0, 0, 0, .2);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            height: 100%;
            margin-top: 5px;

            .box-title {

                padding-right: 60px;
                position: relative;

                .ctrl-btns {
                    display: flex;
                    position: absolute;
                    width: 60px;
                    height: 30px;
                    top: -5px;
                    right: -5px;
                }
            }
        }
    }

    &.--console-opened {
        .console-bar {
            height: 50%;
        }
    }

    &.--console-maximized {
        .console-bar {
            height: 100%;
            margin-top: -5px;
        }

        .options-bar {
            height: 0%;
        }
    }

    .options-tab {
        // overflow-x: auto;
        // overflow-y: unset;
        width: 100%;
        height: 45px;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 5px;

        .options-tab-list {

            background-color: rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            width: 100%;
            position: relative;
            height: 40px;
            overflow-x: auto;
            overflow-y: hidden;
            box-sizing: border-box;

            #active-chain-tab {
                & a>i {
                    padding: 0 5px 0 2px;
                    color: #373e40;
                }

                &.--options-tab-list-enabled a>i {
                    color: #0bbb08;
                }
            }

            ul {
                display: flex;
                width: 100%;
                position: absolute;

                li {
                    color: #aeb3b4;
                    // letter-spacing: .5px;
                    margin-right: 5px;
                    // background-color: rgba(0, 0, 0, 0.1);
                    min-width: 135px;
                    max-width: 220px;
                    overflow: hidden;
                    display: block;
                    // height: 40px;
                    box-sizing: border-box;
                    // line-height: 40px;
                    // padding: 0 5px;
                    // padding-left: 12.5px;
                    position: relative;

                    font-size: 14px;
                    font-size: 14.5px;

                    &:last-child {
                        margin-right: 0;
                    }

                    & a {
                        color: inherit;
                        text-decoration: none;
                        padding: 0 10px 0 10px;
                        display: flex;
                        height: 40px;
                        background-color: rgba(0, 0, 0, 0.1);
                        letter-spacing: .5px;
                        line-height: 40px;
                        user-select: none;
                        cursor: pointer;
                        transition: .3s;
                        outline: none;

                        &.--options-tab-list-active {
                            background-color: rgba(0, 0, 0, 0.5);
                            color: #fff;
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.25);
                        }

                        &:focus {
                            background-color: rgba(0, 0, 0, 0.25);
                        }

                        &.--options-tab-list-active:focus {
                            background-color: rgba(0, 0, 0, 0.5);
                            color: #fff;
                            // font-weight: bold;
                        }

                        span {
                            position: relative;
                            padding-left: 3px;
                            overflow: hidden;
                            white-space: nowrap;
                            display: block;
                            text-overflow: ellipsis;
                            letter-spacing: 0.35px;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 0;
                                width: 98%;
                                top: calc(50% + 1px);
                                height: 0;
                                border-bottom: 1px solid #363e40;
                                transition: 0.3s;
                            }
                        }
                    }


                    // unsaved
                    &.--options-tab-list-unsaved {

                        &::after {
                            content: "";
                            position: absolute;
                            top: 3px;
                            left: 5px;
                            width: 5px;
                            height: 5px;
                            border-radius: 100%;
                            background-color: orange;
                            z-index: 1;

                            // border: 1px solid orange;
                        }
                    }

                    // enabled
                    &.--options-tab-list-enabled {
                        span {
                            &::after {
                                width: 0%;
                            }
                        }
                    }

                    &.worker-tab-title {
                        a {
                            padding-right: 14px;
                        }

                        position: relative;

                        .worker-tab-title-close-btn {
                            position: absolute;
                            top: 12px;
                            right: 2px;
                            padding: 0;
                            width: 15px;
                            height: 15px;
                            line-height: 15px;
                            display: block;
                            cursor: pointer;
                            opacity: 0;
                            transition-duration: 0.3s;
                            border: 0;
                            outline: 2px #ffa50000 solid;
                            color: #aeb3b4;
                            background: none;
                            border-radius: 2px;

                            &:hover {
                                color: #fff;
                            }

                            &:active {
                                outline: 2px #ffa500dd solid;
                            }

                            &:focus {
                                opacity: 1;
                                outline: 2px #ffa500dd solid;
                            }
                        }

                        &:hover {
                            .worker-tab-title-close-btn {
                                opacity: 1;
                            }
                        }

                        &.--options-tab-list-active {
                            .worker-tab-title-close-btn {
                                opacity: 1;
                                transition-duration: 0s;
                            }
                        }

                        .worker-tab-title-type {
                            position: relative;
                            padding-left: 21px;

                            svg {
                                position: absolute;
                                width: 18px;
                                top: 11px;
                                left: 2px;
                            }
                        }
                    }
                }
            }

            h1 {
                overflow: hidden;
                white-space: nowrap;
                display: block;
                text-overflow: ellipsis;
                line-height: 40px;
                height: 40px;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: .35px;
                padding: 0 10px;
                color: #fff;
            }
        }

        .mobile-navy-btn {
            display: none;

            button {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: none;
                background: none;
                cursor: pointer;
            }

            div {
                width: 25px;
                height: 3px;
                background: #aeb3b4;
                position: absolute;
                left: 6.5px;
            }

            & div:first-child {
                top: 11px;
            }

            & div:nth-child(2) {
                top: 19px;
            }

            & div:last-child {
                top: 27px;
            }
        }

        @media screen and (max-width: 1023px) {
            .mobile-navy-btn {
                display: initial;
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 40px;
                background: #313b41;
                opacity: 1;
                z-index: 3;
            }

            .options-tab-list {
                padding-left: 45px;
            }
        }

    }
}

.box {
    padding: 10px 12px;
    box-sizing: border-box;
    color: #aeb3b4;

    .box-title {
        margin-bottom: 10px;

        h2 {
            font-size: 16px;
            line-height: 20px;
            height: 20px;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .box-title + .box-body {
        margin-top: 15px;
    }

    .box-body + .box-body {
        margin-top: 10px;
    }

    .box-navy {
        ul {
            li {
                a {
                    color: #fff;
                }
            }
        }
    }

    .box-body-tabs {
        color: #fff;
    }

}

</style>