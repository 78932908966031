<script setup lang="ts">
import { ref, watch } from 'vue';
import { routeNames } from '@/router';
// components
import { Label, Input, LabelDescription } from '@/components/kit';
import Loader from '@/components/Loader.vue';
// store
import { useNavyStore } from '@/store';
import { usePipelineStore } from "../store";
const navyStore = useNavyStore();
const store = usePipelineStore();


const testData = ref<string>('');
const isDev = ~window.location.search.indexOf('kerd=1');


const consoleMaxClickHandler = ()=>{
    if (!navyStore.chainPage) { return }
    navyStore.chainPage.consoleMaximized = !navyStore.chainPage.consoleMaximized;
}

const consoleCloseClickHandler = ()=>{
    if (!navyStore.chainPage) { return }
    navyStore.chainPage.consoleMaximized = false;
    navyStore.chainPage.consoleOpened = false;
}


const runBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    store.runPipelineTest(testData.value);
}

const jsonBeautifierHelper = (data: any): string => {

    const result = data;

    try {
        return JSON.stringify( JSON.parse(result), null, "\t" )
    }
    catch(e) {
        return result;
    }
    
}

const returnedData = ref<string>('');
const logsData = ref<string>('');
const devInfo = ref<string>('');
watch(()=>[store.testResult?.returned_data], ()=>{
    if (!store.testResult) { return }
    returnedData.value = jsonBeautifierHelper(store.testResult.returned_data);
    logsData.value = store.testResult.logs.map(i=>i.console_log.join("\n")).join("\n");
    isDev && (devInfo.value = jsonBeautifierHelper( JSON.stringify(store.testResult) ) )
})
</script>


<template>
    <!-- console bar -->
    <div class="console-bar" v-if="navyStore.chainPage">
        <div class="console-bar-box box">
            <div class="box-title">
                <h2>Chain testing console</h2>

                <div class="ctrl-btns">
                    <button 
                        @click="consoleMaxClickHandler"
                        :title="navyStore.chainPage.consoleMaximized ? 'restore panel size' : 'maximize panel size'" 
                        class="ui-btn"
                    >
                        <i :class="{
                            'icon-resize-small': navyStore.chainPage.consoleMaximized,
                            'icon-resize-full': !navyStore.chainPage.consoleMaximized
                        }"></i>
                    </button>
                    <button @click="consoleCloseClickHandler" title="close panel" class="ui-btn">
                        <i class="icon-cancel"></i>
                    </button>
                </div>
            </div>

            <div class="box-body qa-console-body">

                <div class="qa-console">

                    <Loader v-if="store.$testing" />
                    <template v-else>
                        <div class="form-group">
                            <Label>
                                Test`s input data
                                <LabelDescription>JSON or String</LabelDescription>
                            </Label>
    
                            <Input v-model="testData" type="textarea" />
                        </div>

                        <button 
                            @click="runBtnClickHandler"
                            class="ui-btn run-test-btn"
                        >
                            <i class="icon-right-big"></i>
                            <span>Run</span>
                        </button>

                        <div class="qa-result" v-if="store.testResult">
                            <p v-if="store.testResult?.status" class="--log-viewer-success">
                                <i class="icon-ok-circled"></i>
                                <span>success</span>
                            </p>
                            <p v-else class="--log-viewer-error">
                                <i class="icon-cancel-circled"></i>
                                <span>error</span>
                            </p>

                            <div class="form-group">
                                <Label>
                                    Worker`s result list
                                </Label>
                            </div>

                            <router-link v-for="(log, index) of store.testResult.logs" :key="index"
                                :to="{
                                    name: routeNames.chainEditor, 
                                    params: { chainID: store.pipelineId },
                                    query: { worker_id: log.job_id, ot: navyStore.openedWorkers }
                                }" 
                                class="qa-worker-result"
                            >
                                <i v-if="log.status" class="icon-ok-circled --success"></i>
                                <i v-else class="icon-cancel-circled --error"></i>
                                <span class="name">{{ log.job_name }}.</span>
                                <span class="duration">{{ log.duration }}ms.</span>
                                <span class="error" v-if="log.error_message">{{ log.error_message }}</span>
                            </router-link>

                            <div class="form-group">
                                <Label>
                                    Result Data
                                </Label>
                                <Input v-model="returnedData" type="textarea" disabled />
                            </div>

                            <div class="form-group">
                                <Label>
                                    Logs
                                </Label>
                                <Input v-model="logsData" type="textarea" disabled />
                            </div>

                            <div class="form-group dev-info" v-if="isDev">
                                <Label>
                                    Dev / QA info
                                </Label>
                                <Input v-model="devInfo" type="textarea" disabled />
                            </div>
                        </div>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>


<style lang="scss">
.qa-console-body {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.qa-console {
    @media print {
        display: none;
    }

    position: relative;
    padding: 10px 0 30px;

    .run-test-btn {

    }

    .qa-worker-result {
        margin: 10px 0;
        padding-left: 10px;
        text-decoration: none;
        display: block;

        .--success {
            color: #0bbb08;
        }

        .--error {
            color: #d10607;
        }

        .name {
            margin: 0 8px;
        }

        .duration {
            opacity: .6;
        }

        .error {
            padding-left: 20px;
        }

        .form-group {
            margin: 30px 0 0;
        }

    }

    .form-group {

        textarea {
            resize: vertical;
            min-height: 50px;
            line-height: 21px;
        }
    }

    .qa-result {
        margin: 20px 0;

        .form-group {
            margin: 30px 0 0;
        }

        p {
            // margin: 25px 0;
            line-height: 1.35em;

            & > a {
                text-decoration: none;
            }

            b {
                font-weight: 600;
                color: #fff;
            }
        }

        p.--log-viewer-success {
            color: #0bbb08;
            margin-bottom: 20px;

            span {
                letter-spacing: .5px;
                padding-left: 7px;
            }
        }

        p.--log-viewer-error {
            color: #d10607;
            margin-bottom: 20px;

            span {
                letter-spacing: .5px;
                padding-left: 7px;
            }
        }

        .dev-info textarea {
            height: 300px;
        }
    }
}

@media print {
    
}
</style>