<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();

</script>

<template>
    <td :class="props.class || ''" class="pipeline-page-status-td" :title="props.value ? 'on' : 'off'">
        <template v-if="props.value">
            <i class="icon-off-1 --on"></i>
            <span>on</span>
        </template>
        <i v-else class="icon-off-1"></i>
    </td>
</template>

<style lang="scss">
.pipeline-page-status-td {
    width: 45px;

    i {
        font-size: 14px;
        color: #3b464b;

        &.--on {
            font-size: 15px;
            color: #0bbb08;
        }
        
        & + span {
            padding-left: 7px;
            color: #0bbb08;
        }
    }
}
</style>