import { getAuthKey } from '@/service/authKey';
import { config } from '@/config';


const {
	host,
	headerAuthKey,
} = config.bff;


export type TBffResponseError = {
	error: string;
	requestId: string;
	code?: number;
}

type THeaders = Record<string, string>;

export const postWithoutAuth = async <TReq, TRes>(endpoint: string, body: TReq, headers?: THeaders): Promise<TRes> => {

	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			method: 'POST',
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
			body: JSON.stringify(body),
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}

export const putWithoutAuth = async <TReq, TRes>(endpoint: string, body: TReq, headers?: THeaders): Promise<TRes> => {

	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			method: 'PUT',
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
			body: JSON.stringify(body),
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}

export const getWithoutAuth = async <TRes>(endpoint: string, headers?: THeaders): Promise<TRes> => {
	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}


export const get = <TReq>(endpoint: string): Promise<TReq> => {
	const userAuthKey = getAuthKey();

	return getWithoutAuth(endpoint, {
		'auth-key': userAuthKey,
	})
}

export const put = <TReq, TRes>(endpoint: string, data: TRes): Promise<TReq> => {
	const userAuthKey = getAuthKey();

	return putWithoutAuth(endpoint, data, {
		'auth-key': userAuthKey,
	})
}

export const post = <TReq, TRes>(endpoint: string, data: TRes): Promise<TReq> => {
	const userAuthKey = getAuthKey();

	return postWithoutAuth(endpoint, data, {
		'auth-key': userAuthKey,
	})
}

