<script setup lang="ts">
import { defineComponent, defineProps, ref, watch } from 'vue';
import { appBus } from '@/utils';
// components
// types
import { ITablePagination } from './types';
import { TDataRecord } from './tableStore'
// helpers
import { TTableButtonList, mathPages, mathPagesButtons } from './helpers';

interface ITablePaginationAppBus<T extends TDataRecord> extends ITablePagination<T> {
    eventName?: string;
}

const props = defineProps<ITablePaginationAppBus<any>>();

defineComponent({ name: 'table-pagination' });

const store = props.store();

// создаём ref, чтобы не пересчитывать каждый раз
const page = ref<number>(store.paginatePage);
watch(() => store.paginatePage, () => (page.value = store.paginatePage));

const perPage = ref<number>(store.paginatePerPage);
watch(() => store.paginatePerPage, () => (perPage.value = store.paginatePerPage));

const filtered = ref<number>(store.paginateFiltered);
watch(() => store.paginateFiltered, () => (filtered.value = store.paginateFiltered));

const viewed = ref<number>(store.paginateViewed);
watch(() => store.paginateViewed, () => (viewed.value = store.paginateViewed));

const pages = ref<number>(mathPages(filtered.value, perPage.value));
const btnList = ref<TTableButtonList>(mathPagesButtons(pages.value, page.value, props?.btnNumber || 0));

watch([filtered, viewed, perPage, page], () => {
    pages.value = mathPages(filtered.value, perPage.value);
    btnList.value = mathPagesButtons(pages.value, page.value, props?.btnNumber || 0)
})

const btnClickHandler = (e: Event, pageIndex: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (store.paginatePage === pageIndex) { return }
    if (0 < pageIndex && pageIndex <= pages.value) {
        appBus.dispatch(props.eventName ?? 'table-pagination', { page: pageIndex });
    }
}
</script>

<template>
    <slot />
    
    <component
        :is="props.prevBtn" 
        @click="(e: Event)=>btnClickHandler(e, page - 1)"
        :isActive="page - 1 > 0"
    />

    <component
        :is="props.btn" 
        v-for="(btn, k) of btnList[0]"
        :key="k" @click="(e: Event)=>btnClickHandler(e, btn)"
        :isActive="btn === page"
        :index="btn"
    />

    <component
        v-if="props.delimeter && btnList[1].length"
        :is="props.delimeter" 
    />
    
    <component
        :is="props.btn" 
        v-for="(btn, k) of btnList[1]"
        :key="k" @click="(e: Event)=>btnClickHandler(e, btn)"
        :isActive="btn === page"
        :index="btn"
    />

    <component
        v-if="props.delimeter && btnList[2].length"
        :is="props.delimeter" 
    />
    
    <component
        :is="props.btn" 
        v-for="(btn, k) of btnList[2]"
        :key="k" @click="(e: Event)=>btnClickHandler(e, btn)"
        :isActive="btn === page"
        :index="btn"
    />

    <component
        :is="props.nextBtn" 
        @click="(e: Event)=>btnClickHandler(e, page + 1)"
        :isActive="page < pages"
    />
</template>