<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref, watch } from 'vue';
import { ICheckbox } from './types';
import { getCheckedFromEvent } from './helpers';


// props
const props = defineProps<ICheckbox>();
const value = ref(props.modelValue || false);

defineComponent({ name: 'check-box' });

// emits
const emits = defineEmits(['update:modelValue']);

// methods
const inputHandler = (e: Event) => {
    const checked = getCheckedFromEvent(e);
    emits('update:modelValue', checked);
}

watch(()=>props.modelValue, (v) =>{ value.value = v });
</script>

<template>
    <div class="form-check" :class="{ '--form-check-disabled': disabled }">
        <label class="form-check-label">
            <input
                class="form-check-input"
                type="checkbox"
                :required="Boolean(required)" 
                :disabled="Boolean(disabled)"
                :id="props.id"
                
                v-model="value" 
                @input="inputHandler"
            />
            <slot />
            <i class="input-helper"></i>
        </label>
    </div>
</template>