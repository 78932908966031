<script setup lang="ts">
// store
import { useNavyStore } from '@/store';
const navyStore = useNavyStore();


const chainNavyClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    navyStore.toggleLeftNavy();
}
</script>


<template>
    <div class="options-tab">
        <div @click="chainNavyClickHandler" class="mobile-navy-btn">
            <button>
                <div></div>
                <div></div>
                <div></div>
            </button>
        </div>

        <div class="options-tab-list">
            <slot></slot>
        </div>
    </div>
</template>


<style lang="ts">
</style>