import { defineStore, StoreDefinition, _GettersTree } from 'pinia';
import { Component, watch, ref } from 'vue';
import {
    TRender,
    ITableTHeadColumn,
    ITableCellProps,
    ITableRowOpts,
    ITableBodyEmpty,
} from './types';


const generateTableStoreId = () => {
    return Math.random().toString(35).replace('0.', 'data-store-') + Date.now()
}

// types

export type TDataRecord = Record<string, any>;

export type TDefaultStore = TSyncDataTableStore<TDataRecord>;

type DeepPartial<T> = T extends TDataRecord ? {
    [K in keyof T]?: DeepPartial<T[K]>
} : T;

export enum ETableOrder {
    asc = 'asc',
    desc = 'desc',
}

export type TTableStoreColumn<TRowData extends TDataRecord, Key extends keyof TRowData = keyof TRowData> = {
    // data
    key: Key;

    searchable?: boolean;
    searchingCb?: (search: string, value: any, row: TRowData) => boolean;

    filterable?: boolean;
    filteringCb?: (search: any[], value: any, row: TRowData) => boolean;
    filteringOrder?: number;

    sortable?: boolean;
    sortingCb?: (a: TRowData[Key], b: TRowData[Key], asc: boolean) => number;

    // cell
    cellClass?: string;
    render?: TRender;
    component?: Component<ITableCellProps>;

    // head cell
    title: string;
    headComponent?: Component<ITableTHeadColumn>;
    headClass?: string;
    headOrderedClass?: string;
    headOrderedClassAsc?: string;
    headOrderedClassDesc?: string;
}

export type TTableStoreRow = {
    class?: string;
    oddClass?: string;
    component?: Component<ITableRowOpts> | null;
}

export type TTableOrder<TRowData> = { column: keyof TRowData; type: ETableOrder; }
export type TTableFilter<TRowData> = { column: keyof TRowData; value: any[] }

type TTableState<TRowData extends TDataRecord> = {
    loading: boolean;

    paginatePerPage: number;
    paginatePage: number;
    paginateInfoTpl: string;

    search: string;
    filters: (TTableFilter<TRowData> | null)[];
    order: (TTableOrder<TRowData> | null)[];

    data: TRowData[];

    columns: TTableStoreColumn<TRowData>[];

    row?: TTableStoreRow;

    head?: {
        class?: string;
        component?: Component;
    }

    body?: {
        class?: string;
        emptyTitle?: string;
        emptyComponent?: Component<ITableBodyEmpty>;
    }
};

type TSyncDataTableActions<TRowData> = {
    filterBy: (index: number, value: any[] | null) => void;
    searchBy: (search: string) => void;
    sortToogle: (index: number) => void;
    sortBy: (index: number, type: ETableOrder) => void;
    setPage: (index: number) => void;
    setPerPage: (index: number) => void;
    clearFilters: () => void;
}

type TSyncDataTableGetters<TRowData> = {
    viewData: () => TRowData[];
    filteredData: () => TRowData[];
    paginateInfo: () => string;
    paginateTotal: () => number;
    paginateViewed: () => number;
    paginateFiltered: () => number;
}

export type TSyncDataTableStore<TRowData extends TDataRecord> = StoreDefinition<
    string,
    TTableState<TRowData>,
    TSyncDataTableGetters<TRowData>,
    TSyncDataTableActions<TRowData>
>;

// для изначальных данных, установленных при инициализации стора
type TDefaultSyncTableState<TRowData extends TDataRecord> = DeepPartial<TTableState<TRowData>>;

interface ITableSyncStateInitOpts<TRowData extends TDataRecord> {
    initialState?: TDefaultSyncTableState<TRowData>;
    onDataStateChange?: (store: TSyncDataTableStore<TRowData>) => void;
}

export const generateSyncDataTableStore = <TRowData extends TDataRecord>(
    {
        initialState = {},
        onDataStateChange = () => { },
    }: ITableSyncStateInitOpts<TRowData>
)
    : TSyncDataTableStore<TRowData> => {
    const state: TTableState<TRowData> = {
        loading: false,

        paginatePerPage: 10,
        paginatePage: 1,
        paginateInfoTpl: 'show _DATA_ of _FILTERED_',

        filters: [],
        order: [],
        search: '',

        data: [],

        columns: [],
    };

    Object.assign(state, initialState);

    const store = defineStore({
        id: generateTableStoreId(),
        state: (): TTableState<TRowData> => state,

        getters: {
            filteredData(): TRowData[] {
                this.loading = true;
                const order = this.order;

                let data = [...this.data] as TRowData[];

                const search = this.search;

                // filtering
                const filterableColumnsLength = this.columns.filter(i => i.filterable).length;
                const filterable = filterableColumnsLength;
                if (filterable) {
                    const filterOrders: Map<number, number> = new Map();
                    this.columns
                        .forEach((column, index) => column?.filterable && filterOrders.set(column?.filteringOrder || -1 * filterableColumnsLength + index, index));

                    [...filterOrders.keys()]
                        .sort((a, b) => a < b ? -1 : 1)
                        .forEach((filteredIndex) => {
                            const columnIndex = filterOrders.get(filteredIndex);
                            if (!columnIndex) { return }
                            const column = this.columns[columnIndex];
                            if (!column) { return }
                            const filter = this.filters[columnIndex];
                            if (!filter) { return }

                            const key = column.key as keyof TRowData;

                            const filteringCb = column.filteringCb;
                            if (filteringCb) {
                                data = data.filter(item => filteringCb(filter.value, item[key], item))
                            }
                            else {
                                const filterList = filter.value.map(s => s.toString());
                                data = data.filter(item => {
                                    const cellData = (item[key] || '') + '';
                                    return ~filterList.indexOf(cellData);
                                })
                            }
                        });
                }

                // searching
                if (search) {
                    const dataMap: Map<number, TRowData> = new Map();
                    let searchableColumns = 0;

                    this.columns.forEach((column) => {
                        if (!column.searchable) { return }
                        searchableColumns++;

                        const key = column.key as keyof TRowData;

                        const searchingCb = column.searchingCb;

                        if (searchingCb) {
                            data.forEach((item, dataIndex) => {
                                if (searchingCb(search, item[key], item)) {
                                    dataMap.set(dataIndex, item);
                                }
                            })
                        }
                        else {
                            data.forEach((item, dataIndex) => {
                                if (~item[key].toString().indexOf(search)) {
                                    dataMap.set(dataIndex, item);
                                }
                            })
                        }
                    });

                    searchableColumns && (data = [...dataMap.values()])
                }

                // sorting
                this.columns.forEach((column, index) => {
                    const key = column.key as keyof TRowData;

                    // sorting
                    if (column.sortable) {
                        const columnOrder = order?.[index];
                        if (columnOrder) {
                            const asc = columnOrder.type === ETableOrder.asc;
                            const cb = column.sortingCb;

                            if (cb) {
                                data = data.sort((a, b) => cb(a[key], b[key], asc));
                            }
                            else {
                                const resT = asc ? 1 : -1;
                                const resF = ~resT;

                                data = data.sort((a, b) => {
                                    return a[key].toString() > b[key].toString() ? resT : resF;
                                });
                            }
                        }
                    }
                });

                return data;
            },

            viewData(): TRowData[] {
                // для стора с data без api
                const page = this.paginatePage - 1;
                const start = this.paginatePerPage * (page);
                const end = start + this.paginatePerPage;

                const data = this.filteredData;

                this.loading = false;
                return data.slice(start, end);
            },

            paginateInfo(): string {
                return this.paginateInfoTpl
                    .replace(/_TOTAL_/g, this.paginateTotal.toString())
                    .replace(/_PAGE_/g, this.paginatePage.toString())
                    .replace(/_PERPAGE_/g, this.paginatePerPage.toString())
                    .replace(/_FILTERED_/g, this.paginateFiltered.toString())
                    .replace(/_DATA_/g, this.paginateViewed.toString())
            },

            orderColumns(): (keyof TRowData)[] {
                return this.columns.filter(i => i.sortable).map(i => i.key) as (keyof TRowData)[]
            },

            paginateTotal(): number {
                return this.data.length;
            },

            paginateViewed(): number {
                return this.viewData.length
            },

            paginateFiltered(): number {
                return this.filteredData.length
            }
        },

        actions: {
            searchBy(search: string) {
                this.search = search;
                this.setPage(1);
            },

            sortToogle(index: number) {
                const columnData = this.columns?.[index];
                if (!columnData || columnData && !columnData.sortable) {
                    return;
                }

                const order = this.order;

                const orderData = order?.[index];
                if (orderData) {
                    const preOrder = orderData as TTableOrder<TRowData>;

                    if (preOrder.type === ETableOrder.asc) {
                        preOrder.type = ETableOrder.desc;
                    }
                    else if (preOrder.type === ETableOrder.desc) {
                        preOrder.type = ETableOrder.asc;
                    }
                }
                else {
                    const newArr = new Array(this.columns.length).fill(null) as typeof order;
                    newArr[index] = {
                        column: columnData.key,
                        type: ETableOrder.asc
                    };
                    this.order = newArr
                }

                this.setPage(1);
            },

            sortBy(index: number, type: ETableOrder) {
                const columnData = this.columns?.[index];
                if (!columnData || columnData && !columnData.sortable) {
                    return;
                }

                const order = this.order;
                const newArr = new Array(this.columns.length).fill(null) as typeof order;
                newArr[index] = {
                    column: columnData.key,
                    type
                };
                this.order = newArr;

                this.setPage(1);
            },

            filterBy(index: number, value: any[] | null) {
                const column = this.columns?.[index];
                if (!column || !column?.filterable) { return }

                if (!value) {
                    this.filters[index] = null;
                }
                else {
                    this.filters[index] = {
                        column: column.key,
                        value
                    }
                }

                this.setPage(1);
            },

            setPage(index: number) {
                if (!index || index < 0) { return }
                if (!Number.isInteger(index)) { return }

                const filtered = this.paginateFiltered;
                const perPage = this.paginatePerPage;
                const maxPage = Math.trunc(filtered / perPage) + (filtered % perPage ? 1 : 0);
                if (1 <= index && index <= maxPage || maxPage === 0 && index === 1) {
                    this.paginatePage = index;
                    onDataStateChange(store);
                }
            },

            setPerPage(perPage: number) {
                if (!Number.isInteger(perPage)) { return }
                if (perPage < 1) { return }

                this.paginatePerPage = perPage;
                this.setPage(1);
            },

            clearFilters() {
                this.filters = [];
                this.search = '';
                this.order = [];
                this.setPage(1);
            }
        }
    });

    return store;
}


// Async store

type TAsyncState<TRowData extends TDataRecord> = TTableState<TRowData> & {
    paginateTotal: number;
    paginateFiltered: number;
}

type TAsyncDataTableActions<TRowData extends TDataRecord> = TSyncDataTableActions<TRowData> & {
    getData: () => void;
};

type TAsyncDataTableGetters<TRowData extends TDataRecord> = Omit<TSyncDataTableGetters<TRowData>, 'paginateTotal' | 'paginateFiltered'>;

// для изначальных данных, установленных при инициализации стора
type TDefaultAsyncTableState<TRowData extends TDataRecord> = DeepPartial<TAsyncState<TRowData>>;

interface ITableAsyncStateInitOpts<TRowData extends TDataRecord> {
    dataGetter: (store: TAsyncDataTableStore<TRowData>) => Promise<any>;
    initialState?: TDefaultAsyncTableState<TRowData>;
    onDataStateChange?: (store: TAsyncDataTableStore<TRowData>) => void;
}

export type TAsyncDataTableStore<TRowData extends TDataRecord> = StoreDefinition<
    string,
    TAsyncState<TRowData>,
    TAsyncDataTableGetters<TRowData>,
    TAsyncDataTableActions<TRowData>
>;

export const generateAsyncDataTableStore = <TRowData extends TDataRecord>(
    {
        dataGetter,
        initialState = {},
        onDataStateChange = () => { },
    }: ITableAsyncStateInitOpts<TRowData>
): TAsyncDataTableStore<TRowData> => {
    const state: TAsyncState<TRowData> = {
        loading: true,

        paginateTotal: 0,
        paginateFiltered: 0,
        paginatePerPage: 10,
        paginatePage: 1,
        paginateInfoTpl: 'show _DATA_ of _FILTERED_',

        filters: [],
        order: [],
        search: '',

        data: [],

        columns: [],
    }

    Object.assign(state, initialState);

    const store = defineStore({
        id: generateTableStoreId(),
        state: (): TAsyncState<TRowData> => state,

        getters: {
            filteredData(): TRowData[] {
                return this.data as TRowData[]; // temp fix 
            },

            viewData(): TRowData[] {
                const end = this.paginatePerPage;

                const data = this.filteredData;
                return data.slice(0, end);
            },

            paginateInfo(): string {
                return this.paginateInfoTpl
                    .replace(/_TOTAL_/g, this.paginateTotal.toString())
                    .replace(/_PAGE_/g, this.paginatePage.toString())
                    .replace(/_PERPAGE_/g, this.paginatePerPage.toString())
                    .replace(/_FILTERED_/g, this.paginateFiltered.toString())
                    .replace(/_DATA_/g, this.paginateViewed.toString())
            },

            paginateViewed(): number {
                return this.data.length
            },
        },

        actions: {
            filterBy(index: number, value: any[] | null) {
                const column = this.columns?.[index];
                if (!column || !column?.filterable) { return }

                if (!value) {
                    this.filters[index] = null;
                }
                else {
                    this.filters[index] = {
                        column: column.key,
                        value
                    }
                }

                this.setPage(1);
            },

            searchBy(search: string) {
                this.search = search;
                this.setPage(1);
            },

            sortToogle(index: number) {
                const columnData = this.columns?.[index];
                if (!columnData || columnData && !columnData.sortable) {
                    return;
                }

                const order = this.order;

                const orderData = order?.[index];
                if (orderData) {
                    const preOrder = orderData as TTableOrder<TRowData>;

                    if (preOrder.type === ETableOrder.asc) {
                        preOrder.type = ETableOrder.desc;
                    }
                    else if (preOrder.type === ETableOrder.desc) {
                        preOrder.type = ETableOrder.asc;
                    }
                }
                else {
                    const newArr = [...order]; //new Array(this.columns.length).fill(null) as typeof order;
                    newArr[index] = {
                        column: columnData.key,
                        type: ETableOrder.asc
                    };
                    this.order = newArr
                }

                this.setPage(1);
            },

            sortBy(index: number, type: ETableOrder) {
                const columnData = this.columns?.[index];
                if (!columnData || columnData && !columnData.sortable) {
                    return;
                }

                const order = this.order;
                const newArr = [...order]; //new Array(this.columns.length).fill(null) as typeof order;
                newArr[index] = {
                    column: columnData.key,
                    type
                };
                this.order = newArr;

                this.setPage(1);
            },

            setPage(index: number) {
                if (!index || index < 0) { return }
                if (!Number.isInteger(index)) { return }

                const filtered = this.paginateFiltered;
                const perPage = this.paginatePerPage;
                const maxPage = Math.trunc(filtered / perPage) + (filtered % perPage ? 1 : 0);
                if (1 <= index && index <= maxPage || maxPage === 0 && index === 1) {
                    this.paginatePage = index;
                    this.getData();
                }
            },

            setPerPage(perPage: number) {
                if (!Number.isInteger(perPage)) { return }
                if (perPage < 1) { return }

                this.paginatePerPage = perPage;
                this.setPage(1);
            },

            clearFilters() {
                this.filters = [];
                this.search = '';
                this.order = [];
                this.setPage(1);
            },

            getData() {
                this.loading = true;
                dataGetter(store)
                    .finally(() => {
                        this.loading = false;
                        onDataStateChange(store);
                    })
            },
        }
    });

    return store;
}


export type TTableStore<TRowData extends TDataRecord> = TSyncDataTableStore<TRowData> | TAsyncDataTableStore<TRowData>;


// helpers
export const useTableSearch = <T extends TTableStore<any>>(useStore: T, initialState: string = '') => {
    const store = useStore();

    const r = ref<string>(initialState);

    watch(() => store.search, () => {
        const search = store.search;
        if (search !== r.value) { r.value = search }
    });

    watch(r, () => {
        const search = store.search;
        if (search !== r.value) { store.searchBy(r.value) }
    });

    return r;
}

export const definePromiseQueue = <DTO>(
    delay: number = 300
) => {
    let getterTimeout: number;

    return {
        last: 0,

        wait(
            getter: (useStore: TAsyncDataTableStore<any>) => Promise<DTO>,
            useStore: TAsyncDataTableStore<any>,
        ) {

            const id = this.last + 1;
            this.last = id;

            const timeout = delay > 0 ? delay : 300;

            return new Promise<DTO>((res, rej) => {
                getterTimeout && clearTimeout(getterTimeout);
                getterTimeout = setTimeout(() => {
                    getter(useStore)
                        .then(r => res(r))
                        .catch((e: Error) => { rej(e) })
                }, timeout);
            });
        },
    }
}

// // @dev: 
// // можно реализовать работу с location
// // как плагин через хук onDataStateChange, меняя location через router
//         location: {
//             search: 'search',
//             filters: {
//                 'key of column': 'age_filter',
//                 key2: 'date_filter',
//             },
//             order: {
//                 'key1': 'sort_name',
//                 'key2': 'sort_age',
//                 'key3': 'sort_date',
//             },
//             paginate: {
//                 // total: 'p_total',
//                 perPage: 'p_per_page',
//                 page: 'page',
//             },
//         }