<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationPrevNextButton } from '@/components/Table/types';

defineComponent({name: 'kit-table-pagination-nextbtn'});

const props = defineProps<ITablePaginationPrevNextButton>();

</script>

<template>
    <li class="paginate_button page-item next" :class="props.isActive ? '' : 'disabled'">
        <a class="page-link">
            <i class="icon-right-open"></i>
        </a>
    </li>
</template>

<style lang="scss">
li.next {
    & {
        user-select: none;
        cursor: pointer;
    }
    &.disabled {
        cursor: no-drop;

        a {
            opacity: .35;
        }
    }
}
</style>