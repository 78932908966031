<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// types
import { ITableThead } from './types';
import { ETableOrder } from './tableStore';
// components
import Tr from './Tr.vue';
import THeadColumn from './THeadColumn.vue';


const props = defineProps<ITableThead<any>>();
defineComponent({ name: 'table-thead' });

// store
const store = props.store();
const componentMode = Boolean(store?.head?.component);


const tdClassHelper = (index: number): string => {
    const result: string[] = [];

    const colData = store.columns?.[index];

    if (!colData) { return '' }

    if (colData.headClass) {
        result.push(colData.headClass)
    }

    if (colData.sortable && (colData.headOrderedClass || colData.headOrderedClassAsc || colData.headOrderedClassDesc)) {

        if (colData.headOrderedClass) {
            result.push(colData.headOrderedClass)
        }

        const colOrder = store.order?.[index];
        if (colOrder) {
            switch (colOrder.type) {
                case ETableOrder.asc:
                    result.push(colData.headOrderedClassAsc || '')
                    break;
                case ETableOrder.desc:
                    result.push(colData.headOrderedClassDesc || '')
                    break;
                default:
                    break;
            }
        }

    }

    return result.join(' ');
}

const orderHandler = (index: number) => {
    store.sortToogle(index);
}
</script>

<template>
    <component
        v-if="componentMode"
        :is="store?.head?.component"
        :class="store?.head?.class || ''"
    >
        <slot/>
            <THeadColumn 
                v-for="(colData, ck) of store.columns"
                :key="ck"
                :class="tdClassHelper(ck)"
                :title="colData.title"
                :component="colData.headComponent || undefined"
                @click="( is=> is ? (()=>orderHandler(ck)) : undefined )(colData.sortable)"
            />
    </component>

    <thead
        v-else
        :class="store?.head?.class || ''"
    >
        <slot/>
        <Tr>
            <THeadColumn 
                v-for="(colData, ck) of store.columns"
                :key="ck"
                :class="tdClassHelper(ck)"
                :title="colData.title"
                :component="colData.headComponent || undefined"
                @click="( is=> is ? (()=>orderHandler(ck)) : undefined )(colData.sortable)"
            />
        </Tr>
    </thead>
</template>