<script setup lang="ts">
import { ref, defineProps, watch } from 'vue';
import { getUniqId, converWorkerType } from '@/utils';
import { EJobType } from '../types'
// components
import { WorkerIcon } from '@/modules/NewPage/components';
// store
import { usePipelineStore, TJob } from '../store';


interface ICardJob {
    isFirstJob?: boolean;
    disabled?: boolean;
    parentJobOptions?: TJob;
}

const store = usePipelineStore();
// props
const props = defineProps<ICardJob>();


const addJob = (jobOptions: TJob) => {
    
    // isOpen.value = false;
    closeAddBox().then(()=>{
        store.createJob(
            jobOptions,
            props.parentJobOptions
        );
    })
}

const addJsSimpleJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsSimple,
        name: 'js-simple worker',
        id: getUniqId(),
        code: `return data;`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addJsFetchJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsFetch,
        name: 'js-fetch worker',
        id: getUniqId(),
        code: `//example\n//const response = await fetch(env.HOST);\n//const { text = '', headers = {}, message = '' } = response;\n//return { text, headers, message };\nreturn data;`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
};

const addJsAsyncLocalStorageJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsLocalStorage,
        name: 'js-local-storage worker',
        id: getUniqId(),
        code: `//example\n//var fromLocalStore = await asyncLocalStorage.get(); // get\n//await asyncLocalStorage.set({a: 2, b: '1', c: true});`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
};


const addJsTelegramJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsTelegram,
        name: 'js-telegram worker',
        id: getUniqId(),
        code: `// js-telegram job;\n// const bot = new Telegraf(env.BOT_TOKEN); \nreturn data;`,
        data: '',
        enabled: false,
        env: {
            BOT_TOKEN: "12345:ABCDEF",
            CHAT_ID: "1234567890"
        },
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addJsDnsJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsDns,
        name: 'js-dns worker',
        id: getUniqId(),
        code: `// dns job\nreturn data;`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}
const addJsNetJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsNet,
        name: 'js-net worker',
        id: getUniqId(),
        code: `// js-net job\nreturn data;`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addPostgresJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsPostgres,
        name: 'js-postgres worker',
        id: getUniqId(),
        code: `// example \n//var a = await pg.query('select 1');\nreturn data;`,
        data: '',
        enabled: false,
        env: {
            host: 'postgres.host',
            port: '5432',
            username: 'postgres',
            password: 'password',
            db_name: 'postgres'
        },
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}
const addMysqlJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsMysql,
        name: 'js-mysql worker',
        id: getUniqId(),
        code: `// example \n//var a = await mysql.query('select 1');\nreturn data;`,
        data: '',
        enabled: false,
        env: {
            host: 'mysql.host',
            port: '3306',
            username: 'mysql',
            password: 'password',
            db_name: 'mysql'
        },
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addClickhouseJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsClickhouse,
        name: 'js-clickhouse worker',
        id: getUniqId(),
        code: `// js clickhouse worker;`,
        data: '',
        enabled: false,
        env: {
            host: 'clickhouse.host',
            port: '8123',
            username: 'clickhouse_user',
            password: 'password',
            db_name: 'database'
        },
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addJsChatgpt = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsChatgpt,
        name: 'js-chatgpt',
        id: getUniqId(),
        code: `// js-chatgpt worker`,
        data: '',
        enabled: false,
        env: {
            api_key: 'api_key'
        },
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}
const addJsNodeHtmlParser = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsNodeHtmlParser,
        name: 'js-node-html-parser',
        id: getUniqId(),
        code: `// js-node-html-parser worker`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addJsHtmlParser2 = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsHtmlParser2,
        name: 'js-htmlparser2',
        id: getUniqId(),
        code: `// js-htmlparser2 worker`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addJsYup = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.jsYup,
        name: 'js-yup',
        id: getUniqId(),
        code: `// js-yup worker`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const addTextJob = () => {
    addJob({
        $loading: true,
        $saved: true,

        type: EJobType.text,
        name: 'text worker',
        id: getUniqId(),
        code: `this text will be returned by the worker as a result of the work`,
        data: '',
        enabled: false,
        env: {},
        pipelineId: '',
        returnedData: '',
        tested: false,
    });
}

const isOpen = ref<boolean>(false);
const openCloseBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (isOpen.value) {
        closeAddBox();
    }
    else {
        isOpen.value = true;
        if (!jobBox.value) { return }
        jobBox.value.classList.add('--add-widget-open');
    }
}

const closeAddBox = () => {
    // if (isOpen.value) { return }
    
    return new Promise(resolve=>{
        setTimeout(()=>{
            isOpen.value = false;
            resolve(true);
        }, 600);
    
        setTimeout(()=>{
            if (!jobBox.value) { return }
            jobBox.value.classList.remove('--add-widget-open');
        }, 300);
    
        if (!jobBox.value) { return }
        Array.from(jobBox.value.querySelectorAll('.add-worker-btn-box'))
            .reverse()
            .forEach((el, k)=>{ el.style.transitionDelay =  (k * 0.02) + 's' })
        jobBox.value.classList.remove('--add-widget-open-beat');
    })

}

const jobBox = ref<HTMLElement>();

// watch;
watch(isOpen, (opened)=>setTimeout(()=>{
    if (!jobBox.value) { return }
    if (opened) {
        jobBox.value.classList.add('--add-widget-open-beat');
        jobBox.value.querySelectorAll('.add-worker-btn-box')
            .forEach((el, k)=>{ el.style.transitionDelay =  (k * 0.05 + .1) + 's' })
    }
}));
</script>

<template>

    <div class="add-job-box" ref="jobBox">
        <i v-if="!props.isFirstJob" class="icon-down"></i>

        <div class="add-job-box-title">
            <button class="ui-btn" @click="openCloseBtnClickHandler" :disabled="isOpen || props.disabled">
                <i class="icon-plus-squared-alt add-btn-icon"></i>
                <span>Add worker</span>
            </button>
            <button class="ui-btn add-btn-close" @click="openCloseBtnClickHandler">
                <i class="icon-cancel"></i>
            </button>
        </div>

        <div class="add-job-box-editor" v-if="isOpen">

            <div class="add-job-box-editor-ctrl">

                <div class="add-worker-btn-box">
                    <button @click="addJsSimpleJob" class="add-worker-btn" title="add js simple worker">
                        <WorkerIcon :text="converWorkerType('js-simple')" class="icon" />
                        <span><strong>js simple</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsAsyncLocalStorageJob" class="add-worker-btn" title="add js local storage worker">
                        <WorkerIcon :text="converWorkerType('js-local-storage')" class="icon" />
                        <span><strong>js local storage</strong></span>
                    </button>
                </div>

            </div>

            <div class="add-job-box-editor-ctrl">
                <div class="add-worker-btn-box">
                    <button @click="addJsFetchJob" class="add-worker-btn" title="add js fetch worker">
                        <WorkerIcon :text="converWorkerType('js-fetch')" class="icon" />
                        <span><strong>js fetch</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsDnsJob" class="add-worker-btn" title="add js dns worker">
                        <WorkerIcon :text="converWorkerType('js-dns')" class="icon" />
                        <span><strong>js dns</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsNetJob" class="add-worker-btn" title="add js net worker">
                        <WorkerIcon :text="converWorkerType('js-net')" class="icon" />
                        <span><strong>js net</strong></span>
                    </button>
                </div>
            </div>

            <div class="add-job-box-editor-ctrl">
                <div class="add-worker-btn-box">
                    <button @click="addPostgresJob" class="add-worker-btn" title="add js postgres worker">
                        <WorkerIcon :text="converWorkerType('js-postgres')" class="icon" />
                        <span><strong>js postgres</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addMysqlJob" class="add-worker-btn" title="add js mysql worker">
                        <WorkerIcon :text="converWorkerType('js-mysql')" class="icon" />
                        <span><strong>js mysql</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addClickhouseJob" class="add-worker-btn" title="add js clickhouse worker">
                        <WorkerIcon :text="converWorkerType('js-clickhouse')" class="icon" />
                        <span><strong>js clickhouse</strong></span>
                    </button>
                </div>
            </div>

            <div class="add-job-box-editor-ctrl">

                <div class="add-worker-btn-box">
                    <button @click="addJsYup" class="add-worker-btn" title="add js yup worker">
                        <WorkerIcon :text="converWorkerType('js-yup')" class="icon" />
                        <span><strong>js yup</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsNodeHtmlParser" class="add-worker-btn" title="add js node html parser worker">
                        <WorkerIcon :text="converWorkerType('js-node-html-parser')" class="icon" />
                        <span><strong>js node html parser</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsHtmlParser2" class="add-worker-btn" title="add js htmlparser2 worker">
                        <WorkerIcon :text="converWorkerType('js-html-parser')" class="icon" />
                        <span><strong>js htmlparser2</strong></span>
                    </button>
                </div>

            </div>

            <div class="add-job-box-editor-ctrl">
                <div class="add-worker-btn-box">
                    <button @click="addJsTelegramJob" class="add-worker-btn" title="add js telegram worker">
                        <WorkerIcon :text="converWorkerType('js-telegram')" class="icon" />
                        <span><strong>js telegram</strong></span>
                    </button>
                </div>

                <div class="add-worker-btn-box">
                    <button @click="addJsChatgpt" class="add-worker-btn" title="add js chatgpt worker">
                        <WorkerIcon :text="converWorkerType('js-chatgpt')" class="icon" />
                        <span><strong>js chatgpt</strong></span>
                    </button>
                </div>
            </div>

            <div class="add-job-box-editor-ctrl">
                <div class="add-worker-btn-box">
                    <button @click="addTextJob" class="add-worker-btn" title="add text worker">
                        <WorkerIcon :text="converWorkerType('text')" class="icon" />
                        <span><strong>text</strong></span>
                    </button>
                </div>
            </div>

        </div>

        <i class="icon-down"></i>
    </div>
</template>

<style lang="scss">
.add-job-box {
    padding: 20px 0;

    &:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    &:last-child .icon-down:last-child {
        display: none;
    }

    .add-job-box-editor {
        overflow: hidden;
        height: 0;
        transition: opacity .25s, padding .2s;
    }

    &.--add-widget-open {

        .add-job-box-editor {
            height: auto;
            padding-top: 200px;
            opacity: 1;
            
            transition: opacity .25s, padding .5s;
        }

        .add-job-box-title {
            .add-btn-icon {
                opacity: 0;
            }

            .add-btn-close {
                display: flex;
            }
        }
        
    }
    
    &.--add-widget-open-beat {
        .add-worker-btn-box {
            opacity: 1;
        }
    }


    .add-job-box-editor-ctrl {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        &:first-child {
            margin-top: -200px;
        }

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            .add-worker-btn-box {
                max-width: 250px;
                width: 100%;
            }
            .add-worker-btn {
                width: 100%;
            }
        }
    }

    & .add-job-box-editor-ctrl:last-child {
        margin-bottom: 15px
    }

    .add-job-box-btn {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }

    // .add-btn {
    //     margin: 5px auto;
    // }


    .icon-down {
        opacity: 0.5;
        margin: 0 auto;
        display: block;
        text-align: center;
    }

    .add-job-box-title {
        // display: flex;
        // width: 140px;
        margin: 5px auto;
        position: relative;
        display: table;

        .add-btn-close {
            position: absolute;
            top: 0;
            right: -30px;
            display: none;
            opacity: .5;
        }
    }

    // counter-reset: add-worker-btn-box;

    .add-worker-btn-box {
        // counter-increment: add-worker-btn-box;
        margin: 10px 10px 0 0;
        opacity: 0;
        transition: opacity .35s;
        // // width: calc( (counter(add-worker-btn-box) * 1)px + 100px);
        // // transition-delay: calc( counter(add-worker-btn-box) * 1 )s;
        
        // &::before {
        //     content: counter(add-worker-btn-box);
        //     color: #fff;
        // }
    }

    .add-worker-btn {
        display: flex;
        background: #00000060;
        cursor: pointer;
        color: #aeb3b4;
        align-items: end;
        border: none;
        padding: 0 10px 10px 5px;
        // margin: 10px 10px 0 0;
        outline: none;
        user-select: none;
        transition: background .25s, color .25s;
        // opacity: 0;

        .icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        span {
            display: block;
            min-width: max-content;
        }

        &:hover {
            background: #00000040;
            color: #fff;
        }

        &:focus {
            background: #00000040;
            color: #fff;
        }

        &:disabled {
            opacity: .5;
            color: gray;
            cursor: not-allowed;
        }
    }
}

@media print {
  .workers-list {
    .add-job-box {
        i:first-child {
            display: block;
        }
        i {
            display: none;
        }
        .add-job-box-title {
            display: none;
        }
    }

    & .add-job-box:last-child {
        display: none;
    }
  }
}
</style>