import {
    generateAsyncDataTableStore,
    definePromiseQueue,
    TAsyncDataTableStore,
} from '@/components/Table/tableStore';
import LogsService from '../service';


export type TTableLogItem = {
    pipelineName: string;
    pipelineId: string;
    logId: string;
    updatedAt: string;
    isSuccess: boolean;
}

type TTableGetLogListRes = {
    list: Array<TTableLogItem>,
    pagination: {
        page: number;
        pageSize: number;
        pageCount: number;
        total: number;
    }
}


const asyncPromiseQueue = definePromiseQueue<TTableGetLogListRes>(300);


export const useTableLogsStore = generateAsyncDataTableStore<TTableLogItem>({
    initialState: {
        paginateInfoTpl: '_DATA_ of _TOTAL_',
        loading: true
    },

    onDataStateChange(useStore) {
        setTimeout(() => {
            
            const store = useStore();
            if (store.viewData.length === 0) { return }
            if (store.viewData.length < store.paginatePerPage) {
                const tbody = document.body.querySelector('.logs-page-table tbody');
                if (tbody) {
                    const colspan = store.columns.length + '';
                    const trNumber = store.paginatePerPage - store.viewData.length;

                    new Array(trNumber)
                        .fill(1)
                        .forEach(() => {
                            const td = document.createElement('td');
                            td.setAttribute('colspan', colspan);
                            td.innerHTML = '&nbsp;'
                            const tr = document.createElement('tr');
                            tr.className = 'border-color-unset';
                            tr.appendChild(td);
                            tbody!.appendChild(tr);
                        });
                }
            }
        })
    },

    dataGetter: async (useStore) => {
        const store = useStore();

        await asyncPromiseQueue.wait(getData, useStore)
            .then((r) => {
                // устанавливаем данные стора
                store.data = r.list;
                // store.paginateFiltered = r.pagination.total;
                store.paginateTotal = r.pagination.total;
                store.paginateFiltered = r.pagination.total; // no pagination.filtered from backend. Temp fix
            })
            .catch((e: Error) => {
                // если ошибка не по таймауту, то чистим стор
                if (e) {
                    store.data = [];
                    store.paginateTotal = 0;
                    store.paginateFiltered = 0;

                    alert(`Error loading the log list`);
                    console.log('logs list error');
                    console.log(e);
                }

            })
    }
});


const getData = async (useStore: TAsyncDataTableStore<any>): Promise<TTableGetLogListRes> => {
    // store
    const store = useStore();

    const filters = [...store.filters].filter(i => i).reduce((acc, v) => {
        v?.column && (acc[v.column] = v.value)
        return acc
    }, {} as any);

    const {
        pipelineId = void 0,
        isSuccess = void 0,
    } = filters;

    // get data
    const query = {
        pipeline_id: pipelineId,
        status: isSuccess,
        page: store.paginatePage,
        page_size: store.paginatePerPage,
    }
    const data = await LogsService.getLogList(query);
    // mapping
    return {
        list: data.list.map(item => {
            return {
                pipelineName: item.pipeline_name,
                pipelineId: item.pipeline_id,
                logId: item.request_id,
                updatedAt: item.updated_at,
                isSuccess: item.is_success,
            }
        }),
        pagination: {
            page: data.pagination.page,
            pageCount: data.pagination.page_count,
            pageSize: data.pagination.page_size,
            total: data.pagination.total
        }
    }
}