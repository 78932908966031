<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITableTr } from './types';


const props = defineProps<ITableTr>(); 
defineComponent({ name: 'table-tr'});
</script>

<template>
    <tr :class="props.class || ''"> <slot /> </tr>
</template>