<script setup lang="ts">
import { ref } from 'vue';
import { ENavy, router } from '@/router';
// eslint-disable-next-line
import defaultUserImage from '@/assets/img/user.svg';
// components
import { Input } from '@/components/kit'
// store
import { useUserStore, loginUserByLoginPassword } from '@/store';


const userStore = useUserStore();

const login = ref<string>('');
const password = ref<string>('');

const isEmailValidator = (email?: string): boolean => {
  if (!email) { return false }
  const regex = /^[a-zA-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/ig;

  return regex.test(email);
}

const loginHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation && e.stopPropagation();

  if (userStore.loading) { return }

  // проверяем на почту или телефон
  let identifier = login.value.trim();
  if (!isEmailValidator(login.value.trim())) {
    identifier = (identifier.match(/\d+/g) || []).join('');
    if (identifier[0] === '8') {
      identifier = '7' + identifier.slice(1);
    }
  }

  loginUserByLoginPassword(identifier, password.value)
    .then(() => router.push(ENavy.home))
}
</script>

<template>
  <div class="login-page">
    <div class="login-page-box">
      <img :src="defaultUserImage" class="login-user-ava" alt="login user ava">
      <form @submit="loginHandler">
        <div class="form-group">
          <label for="login">Sign in</label>
          <Input id="login" v-model="login" type="text" placeholder="Login" required :disabled="userStore.loading" 
          autocomplete="off" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Input id="password" v-model="password" type="password" class="form-control text-center" placeholder="Password"
            required :disabled="userStore.loading" autocomplete="off"/>
        </div>
        <div id="login-btn">
          <button type="submit" :disabled="userStore.loading" class="ui-btn">
            <i class="icon-user"></i>
            <span>
              Sign in
            </span>
          </button>
        </div>

        <div v-if="userStore.errorMessage" id="login-error">
          <p>
            Login error!
          </p>
          <p>
            {{ userStore.errorMessage.toLocaleUpperCase() }}
          </p>
          <p v-if="userStore.errorRequestId">
            request id: {{ userStore.errorRequestId }}
          </p>
          <p v-if="userStore.errorCode">
            code: {{ userStore.errorCode }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.login-page {
  background: url("../../assets/img/auth/lockscreen-bg.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  height: 100lvh;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .login-page-box {

    min-width: 280px;
    max-width: 290px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      width: 100%;
    }

    .login-user-ava {
      width: 45px;
      height: 45px;
    }
  }

  #login, #password {
    background: #ffffff30;
    color: #3b464b;
  }

  #login-btn {
    width: 150px;
    margin: 30px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui-btn {
      background: #00000080;
    }
  }

  #login-error {
      margin: 20px 0 0;
      padding: 10px;
      box-sizing: border-box;
      background: #ffffff80;
      color: #000;

      p {
          font-weight: 600;
          line-height: 1.5em;
      }
  }
}
</style>
