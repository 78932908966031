<script setup lang="ts">
import { WorkerIcon } from '@/modules/NewPage/components';
import { routeNames } from '@/router';
import { converWorkerType } from '@/utils';
import { useNavyStore } from '@/store';


const navyStore = useNavyStore()
</script>

<template>
    <ul class="chain-navy" v-if="navyStore.chainPage && navyStore.chainPage.chainId">
        <li id="chain-navy-anchor" :class="{
            '--chain-navy-enabled': navyStore.chainPage.enabled,
            '--chain-navy-unsaved': navyStore.chainPage.unsaved,
        }">
            <router-link :to="{
                name: routeNames.chainEditor,
                params: { chainID: navyStore.chainPage.chainId },
                query: { ot: navyStore.openedWorkers }
            }" :title="navyStore.chainPage.name"
                :class="{ '--nav-anchor-active': navyStore.chainPage.active }">
                <i class="ico icon-off-1"></i>
                <span>{{ navyStore.chainPage.name }}</span>
            </router-link>
        </li>

        <template v-for="(tabData) of navyStore.chainPage.workersList" :key="tabData.id">
            <li class="chain-navy-worker" :class="{
                '--chain-navy-unsaved': tabData.unsaved,
                [`chain-navy-type-${tabData.type}`]: true,
                '--chain-navy-enabled': tabData.enabled
            }">
                <router-link :to="{
                    name: routeNames.chainEditor,
                    params: { chainID: navyStore.chainPage.chainId },
                    query: {
                        worker_id: tabData.id,
                        ot: navyStore.openedWorkers,
                    }
                }" :class="{ '--nav-anchor-active': tabData.active }" :title="tabData.name">
                    <i class="chain-navy-worker-ico">
                        <WorkerIcon :text="converWorkerType(tabData.type)" :is-background="true" />
                    </i>
                    <span>{{ tabData.name }}</span>
                </router-link>
            </li>
        </template>

    </ul>
</template>