<script setup lang="ts">
import  { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { router, routeNames } from '@/router';
import { appBus } from '@/utils';
// components
import DeskTop from './DeskTop.vue';
import DeskTopHeader from './DeskTopHeader.vue';
import PipelinePage from '@/modules/pipeline/PipelinePage.vue';
import Loader from '@/components/Loader.vue';
// store
import { useNavyStore } from '@/store';
import { usePipelineStore } from "@/modules/pipeline/store";
const store = usePipelineStore();
const navyStore = useNavyStore();


const route = useRoute();
const chainID = ref<string>('');

const loadChainData = () => {
    const chainData = route.params.chainID;
    if (! (chainData && typeof chainData === 'string') ) { return }

    const opened = route.query.worker_id;
    const openedList = route.query.ot;

    const openedWorker = opened && typeof opened === 'string' ? opened : '';
    const openedWorkerList = (openedList && Array.isArray(openedList)) ? openedList.filter(i=>{ return typeof i === 'string' }) : [];

    if (store.pipelineId === chainData) {
        // цепочка уже загружена

        if (openedWorker) {
            navyStore.openTab(openedWorker, true);
        }
        else {
            navyStore.setChainTabActive();
        }
        
        return;
    }
    
    // цепочку нужно загрузить
    navyStore.chainPage = void 0;
    navyStore.openedTabs.clear();
    
    // дождаться лоадинга
    store.$loading = true;
    store.getPipeline(chainData);

    const removeFirstWatcher = watch(()=>store.$loading, (isLoading) => {
        if (!isLoading) {
            if (!store.pipelineId) { return }
            chainID.value = store.pipelineId;

            // setopened tabs
            // open all tabs
            openedWorkerList.forEach(workerID=>navyStore.openTab(workerID as string, false));

            if (openedWorker) {
                // open active tab
                navyStore.openTab(openedWorker, true)
            }
            else {
                navyStore.setChainTabActive()
            }
        }

        removeFirstWatcher();
    });

};



onMounted(()=>{
    // router on change
    const routerAfterEachHandler = ()=>loadChainData();

    appBus.subscribe( appBus.eventList.routerAfterEach, routerAfterEachHandler);
    onUnmounted(()=>appBus.unsubscribe(appBus.eventList.routerAfterEach, routerAfterEachHandler));

    // ctrl left + right
    const leftHandler = ()=>{
        if ( !navyStore.chainPage ) { return }
        if ( navyStore.chainPage.active ) { return }
        
        const chainNavyOpenedList = navyStore.chainPage.workersList;
        var newActive;

        var index = Object.values(chainNavyOpenedList).findIndex(i=>i.active);

        if (index === 0) {
            // set chain tab active
            router.push({
                name: routeNames.chainEditor,
                query: { ot: navyStore.openedWorkers }
            });
            return
        }
        else if (~index) {
            newActive = (index - 1 > -1) && chainNavyOpenedList?.[index - 1];
        }

        if (newActive) {
            router.push({
                name: routeNames.chainEditor,
                query: {
                    worker_id: newActive.id,
                    ot: navyStore.openedWorkers
                }
            });
        }

    };

    const rightHandler = ()=>{
        if ( !navyStore.chainPage ) { return }

        const chainNavyOpenedList = navyStore.chainPage.workersList;
        var newActive;

        if (navyStore.chainPage.active) {
            newActive = Object.values(chainNavyOpenedList)?.[0] 
        }
        else {
            var index = Object.values(chainNavyOpenedList).findIndex(i=>i.active);
            if (~index) {
                newActive = Object.values(chainNavyOpenedList)?.[index + 1]
            }
        }

        if (!newActive) { return }

        router.push({
            name: routeNames.chainEditor,
            query: {
                worker_id: newActive.id,
                ot: navyStore.openedWorkers
            }
        });

    };

    appBus.subscribe(appBus.eventList.ctrlLeft, leftHandler);
    appBus.subscribe(appBus.eventList.ctrlRight, rightHandler);

    onUnmounted(()=>{
        appBus.unsubscribe(appBus.eventList.ctrlLeft, leftHandler);
        appBus.unsubscribe(appBus.eventList.ctrlRight, rightHandler);
    });


    // get data
    loadChainData();


});

onUnmounted(()=>{
    chainID.value = '';

    store.$reset();
    navyStore.chainPage = void 0;
    navyStore.openedTabs.clear();
});


const desktopClassHelper = (): string => {
    let result = '';
    if (store.$loadingError) { return result }
    if (!chainID.value) { return result }
    if (!navyStore.chainPage) { return result }


    if (navyStore.chainPage.active) {
        if (navyStore.chainPage.consoleOpened) {
            result+=' --console-opened';
        }
        if (navyStore.chainPage.consoleMaximized) {
            result+=' --console-maximized';
        }
    }
    else {
        const activeWorker = navyStore.chainPage?.workersList.find(i => i.active);
        if (!activeWorker) { return '' }
        if (activeWorker.consoleOpened) {
            result+=' --console-opened';
        }
        if (activeWorker.consoleMaximized) {
            result+=' --console-maximized';
        }
    }

    return result;

}
</script>


<template>
    <DeskTop v-if="store.$loading">
        <Loader/>
    </DeskTop>

    <!-- editor -->
    <DeskTop v-if="chainID && !store.$loadingError" v-show="!store.$loading"
        :class="desktopClassHelper()"
    >

        <PipelinePage :id="chainID"/>
                
    </DeskTop>

    <!-- error -->
    <DeskTop v-if="!store.$loading && store.$loadingError">
        <DeskTopHeader>
            <h1>Error</h1>
        </DeskTopHeader>
        <div class="options-bar">
            <div class="options-bar-box">
                <div class="box">
                    <div class="box-body">
                        <p>{{store.$loadingError}}</p>
                    </div>
                </div>
            </div>
        </div>
    </DeskTop>
</template>


<style lang="scss">

#save-btn {
    position: absolute;
    border-radius: 2px;
    bottom: 10px;
    right: 10px;
    z-index: 1;

    .ui-btn {
        background: #08bb08;
        color: #e7e7e7;

        &:disabled {
            background-color: inherit;
            cursor: not-allowed;
            color: gray;
        }
    }
}


.pipeline-work-space {
    display: flex;
    height: 100%;
    margin: 10px -5px 0 -5px;

    // flex-direction: column;

    &.--split {
        .options {
            display: initial;
        }
        .code {
            display: initial;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            margin: 10px 0;
            overflow-y: auto;

            .options {
                margin: 0 0 10px;
                min-height: 50vh;
            }
            .code {
                margin: 0 0 10px;
                min-height: 50vh;
            }
        }
    }

    &.--code {
        .code {
            display: initial;
        }
    }

    &.--options {
        .options {
            display: initial;
        }
    }

    .options {
        // background: #494949;
        display: none;
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
        margin: 0 5px;
        overflow-y: auto;
    }

    .code {
        // background: #191a1a;
        display: none;
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
        margin: 0 5px;
        overflow-y: auto;
    }
}
</style>