<script lang="ts" setup>
import { defineComponent } from 'vue';
// import { NewPage } from '@/modules/NewPage';
import NewWrap from '@/modules/NewPage/NewWrap.vue';
import NewLoginPage from '@/modules/login/NewLoginPage.vue';
// import Wrap from '@/components/Wrap.vue';

// window global utils
import { appBus } from '@/utils';
import { version } from '../package.json';
// store
import { useUserStore } from '@/store';
const userStore = useUserStore();

// window
(window as any)._app = { version, appBus };

defineComponent({ name: 'App'});

</script>

<template>
  <NewLoginPage v-if="!userStore.status && !userStore.loading" />
  <NewWrap v-if="userStore.status" />
</template>

<style lang="scss">
  // @import "./assets/css/master.css";
  // @import "./assets/css/loader.scss";
</style>
