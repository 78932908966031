import { getUniqId } from "@/utils";
import {
    TGetPipelineResDto,

    TGetJobListResDto,
    TCreateJobReqDto,
    TCreateJobResDto,
    TUpdateJobReqDto,
    TUpdateJobResDto,
    TGetPipelineListResDto,
    TGetPipelineListReqDto,
    TUpdatePipelineItemResDto,
    IUpdatePipelineItemReqDto,
    TJobTestingReqDto,
    TJobTestingResDto,
    TPipelineTestingResDto,
    TPipelineTestingReqDto,
} from "./types";
import { get, post, put } from "@/service/bffService";



export default class Service {
    // get one pipeline
    static async getPipeline(pipelineUid: string): Promise<TGetPipelineResDto> {
        return await get<TGetPipelineResDto>(`/api/chain/${pipelineUid}`)
    }
    // get pipeline list
    static async getPipelineList(query: TGetPipelineListReqDto): Promise<TGetPipelineListResDto> {
        const paginationQuery = `${query.page ? 'page='+query.page : ''}${query.page_size ? '&pageSize='+query.page_size : ''}`
        return await get<TGetPipelineListResDto>(`/api/chain?${paginationQuery}`)
    }
    
    // create one pipeline
    static async createPipeline(): Promise<TGetPipelineResDto> {
        return await post<TGetPipelineResDto, {}>(`/api/chain`, {})
    }
    // update one pipeline
    static async updatePipeline(pipelineUid: string, body: IUpdatePipelineItemReqDto): Promise<TUpdatePipelineItemResDto> {
        return await put<TUpdatePipelineItemResDto, IUpdatePipelineItemReqDto>(`/api/chain/${pipelineUid}`, body)
    }

    // create job
    static async createJob(data: TCreateJobReqDto): Promise<TCreateJobResDto> {
        return await post<TCreateJobResDto, TCreateJobReqDto>(`/api/job`, data)        
    }
    
    // update job
    static async updateJob(pipeId: string, jobId: string, { name, env, code, enabled }: TUpdateJobReqDto): Promise<TUpdateJobResDto> {
        return await put<TUpdateJobResDto, TUpdateJobReqDto>(`/api/job/${pipeId}/${jobId}`, { 
            name: name,
            env: env,
            code: code,
            enabled: enabled
        })
    }

    // run job test
    static async runJobTest(body: TJobTestingReqDto): Promise<TJobTestingResDto> {
        return await post<TJobTestingResDto, TJobTestingReqDto>(`/api/test/job`, body)
    }
    // run pipeline test
    static async runPipelineTest(pipelineId: string, data: string): Promise<TPipelineTestingResDto> {
        const body: TPipelineTestingReqDto = {
            uid: pipelineId,
            data: data
        }
        return await post<TPipelineTestingResDto, TPipelineTestingReqDto>(`/api/test/pipeline`, body)
    }

}