import { createRouter, createWebHistory } from 'vue-router';
import { isUserAuth, useNavyStore, useUserStore } from '@/store';
import { appBus } from '@/utils';
// import HomePageVue from '@/modules/home/HomePage.vue';
// import PipelinePage from '@/modules/pipeline/PipelinePage.vue';
// import PipelineTablePageVue from '@/modules/pipeline/PipelineTablePage.vue';
// // import LogsTablePage from '@/modules/logs/LogsTablePage.vue';
// import { LoginPage } from '@/modules/login';
// import LogsPageVue from '@/modules/logs/LogsPage.vue';
// new pages
import DashboardPage from '@/modules/NewPage/DashboardPage.vue';
import AccountPage from '@/modules/NewPage/AccountPage.vue';
import LogsPage from '@/modules/NewPage/LogsPage.vue';
import LogViewerPage from '@/modules/NewPage/LogViewerPage.vue';
import ChainsPage from '@/modules/NewPage/ChainsPage.vue';
import ChainEditorPage from '@/modules/NewPage/ChainEditorPage.vue';


// dev / prod mode
const devMode = process?.env?.NODE_ENV === "development";

const defaultPageTitle = 'Wor4';

// роуты
export const routeNames = {
	login: 'login',
	home: 'home',
	account: 'account',
	logs: 'logs',
	logViewer: 'logViewer',
	chains: 'chains',
	chainEditor: 'chainEditor',


	// pipeline: 'pipeline',
	// pipelineTable: 'pipelineTable',
	// logsList: 'logsList',
	// logsItem: 'logsItem',
} as const;

export const EPermissions = {
	// admin
	viewPermission: 10,
	editPermission: 11,
	viewUsers: 20,
	editUsers: 21,

	// other
	viewCmi: 2,
	editCmi: 3,
} as const;

export const routesPermissions: Record<TRoutes, number[]> = {
	login: [],
	home: [],
	account: [],
	logs: [],
	logViewer: [],
	chains: [],
	chainEditor: [],

	// pipeline: [],
	// pipelineTable: [],
	// logsList: [],
	// logsItem: [],
};



// все ключи роутов
export type TRoutes = keyof typeof routeNames;


// пути для роутов
// не юзаем enum, чтобы было можно юзать в шаблонах
export const ENavy: Record<TRoutes, string> = {
	login: '/sign-in',
	home: '/',
	account: '/account',
	logs: '/logs',
	logViewer: '/log/:chainID/:logID',
	chains: '/chains',
	chainEditor: '/chain/:chainID',


	// pipeline: '/pipeline/:id',
	// pipelineTable: '/pipeline-table',
	// logsList: '/logs2',
	// logsItem: '/logs2/:pipelineId/:logId',
};

export type TNavyRoute = typeof ENavy[keyof typeof ENavy];

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: routeNames.home,
			path: ENavy.home,
			component: DashboardPage,
		},
		// account
		{
			name: routeNames.account,
			path: ENavy.account,
			component: AccountPage,
			meta: {
				pageTitle: 'Account. ' + defaultPageTitle
			}
		},
		// logs
		{
			name: routeNames.logs,
			path: ENavy.logs,
			component: LogsPage,
			meta: {
				pageTitle: 'Logs. ' + defaultPageTitle
			}
		},

		{
			name: routeNames.logViewer,
			path: ENavy.logViewer,
			component: LogViewerPage,
			meta: {
				pageTitle: 'Log Viewer. ' + defaultPageTitle
			}
		},
		// chains
		{
			name: routeNames.chains,
			path: ENavy.chains,
			component: ChainsPage,
			meta: {
				pageTitle: 'Chains. ' + defaultPageTitle
			}
		},
		{
			name: routeNames.chainEditor,
			path: ENavy.chainEditor,
			component: ChainEditorPage,
			meta: {
				pageTitle: 'Chain Editor. ' + defaultPageTitle
			}
		},

		// // pipeline
		// {
		// 	name: routeNames.pipeline,
		// 	path: ENavy.pipeline,
		// 	props: true,
		// 	component: PipelinePage,
		// },
		// // pipeline table
		// {
		// 	name: routeNames.pipelineTable,
		// 	path: ENavy.pipelineTable,
		// 	component: PipelineTablePageVue
		// },
		// // login
		// {
		// 	name: routeNames.login,
		// 	path: ENavy.login,
		// 	component: LoginPage,
		// },
		// // logs item
		// {
		// 	name: routeNames.logsItem,
		// 	path: ENavy.logsItem,
		// 	props: true,
		// 	component: LogsPageVue,
		// },
		// logs list
		// {
		// 	name: routeNames.logsList,
		// 	path: ENavy.logsList,
		// 	props: route => ({pipelineId: route.query.pipelineId}),
		// 	component: LogsTablePage
		// },
		// not found
		{
			path: '/:catchAll(.*)',
			component: DashboardPage
		}
	]
});

// закрываем мобильную навигацию
router.beforeResolve(() => {
	const navyStore = useNavyStore();
	navyStore.closeMobileNavy();
});

// guard для проверки прав юзера на страницу
router.beforeResolve(async (to, from, next) => {

	// получаем права страницы
	const pathPermissions = to?.meta?.permissions || [];

	const userStore = useUserStore();
	// проверяем статус логина
	const isAuth = await isUserAuth();
	if (!isAuth) {
		if (to.path !== ENavy.login) {
			next(ENavy.login);
		}
		else {
			next();
		}
		return;
	}

	// если залогинен и страница логина - идём на dashboard
	if (isAuth && to.path === ENavy.login) {
		next('/');
		return;
	}

	// получаем права юзера
	userStore.matchAuth();

	if (Array.isArray(pathPermissions) && pathPermissions.length) {
		// если данные о юзере ещё грузятся, то ожидаем их
		const awaitUserPermissions = async (cb: (list: number[]) => void) => {
			if (!userStore.loading) {
				return cb(userStore.permissions);
			}
			setTimeout(() => awaitUserPermissions(cb), 100);
		};

		const userPermissions: number[] = await new Promise(res => {
			awaitUserPermissions(res);
		});

		// проверяем, есть ли хотя бы одно право на страницу
		const isSuccess = pathPermissions.length === 0 || [...userPermissions].filter(perm => {
			return ~pathPermissions.indexOf(perm)
		}).length;

		if (!isSuccess) {
			next(ENavy.login);
			return;
		}
	}

	next();
});

// route before resolve hook
router.beforeResolve(async (to, from, next)=>{
	appBus.dispatch(appBus.eventList.routerAfterEach, {to, from});
	next();
})

// pagetitle
router.afterEach((to) => {
	// триггерим событие изменения страницы
	appBus.dispatch(appBus.eventList.routerAfterEach, {});

	// устанавливаем title для страницы
	const pageTitle = to?.meta?.pageTitle || defaultPageTitle;
	(window as any).document.title = pageTitle;
});