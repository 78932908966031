<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
// components
import Loader from '@/components/Loader.vue';
// ace editor
import { getTextEditor } from "../ace";
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


// get active tab worker
const getActiveWorker = () => navyStore.chainPage?.workersList.find(i => i.active);
const getStoreActiveWorker = () => {
    if (!worker.value) { return void 0 }
    const id = worker.value.id;
    return store.jobs?.find(i => i.id === id);
}
const worker = ref(getActiveWorker());
const storeWorker = ref(getStoreActiveWorker());
watch(getActiveWorker, (aw) => {
    worker.value = aw;
    storeWorker.value = getStoreActiveWorker();

    if (mountedBox.value) {
        setupEditor();
    }
});

// editor
const mountedBox = ref<HTMLDivElement>();

// editor
const editor = getTextEditor(() => {
    if (worker.value) {
        const code = editor.getValue();
        if (worker.value.options.code !== code) {
            removeUpNavyStoreCodeWatcher();
            worker.value.options.code = code;
            worker.value.options.codeInvalid = false;
            removeUpNavyStoreCodeWatcher = setUpNavyStoreCodeWatcher();
        }
    }
});
// editor;

const codeEditorLoading = ref<boolean>(true);

type TAceTextMode = 'html' | 'css' | 'text' | 'javascript' | 'json' | 'xml';
const setEditorMode = (mode: TAceTextMode) => editor.session.setMode('ace/mode/' + mode);

const setupEditor = async () => {
    if (!worker.value) { return }
    if (mountedBox.value) {

        codeEditorLoading.value = false;

        editor.setValue(worker.value.options.code);
        mountedBox.value.appendChild(editor.box);

        if (worker.value?.editor?.cursor) {
            editor.moveCursorToPosition(worker.value.editor.cursor);
        }

        if (worker.value?.editor?.textMode) {
            setEditorMode(worker.value.editor.textMode as TAceTextMode);
        }

        editor.clearSelection();
        editor.focus();
    }
}

onMounted(() => {
    if (!worker.value || !storeWorker.value) { return }
    setupEditor();
});

onUnmounted(() => {
    if (!worker.value) { return }

    if (worker.value?.editor?.cursor) {
        worker.value.editor.cursor = editor.getCursorPosition();
    }
    else {
        worker.value.editor = worker.value.editor ?? {};
        worker.value.editor.cursor = editor.getCursorPosition();
    }
});

const setUpNavyStoreCodeWatcher = ()=>watch(()=>[worker.value?.options.code], ([code])=>{
    if (typeof code !== 'string') { return }

    if (code !== editor.getValue()) {
        editor.setValue(code, 0);
        editor.clearSelection();
        editor.focus();
    }
});

let removeUpNavyStoreCodeWatcher = setUpNavyStoreCodeWatcher();

const switchEditorModeBtnClickHandler = (e: Event, type: TAceTextMode) => {
    e.preventDefault();
    e.stopPropagation();

    if (worker.value) {
        if (!worker.value.editor) {
            worker.value.editor = worker.value.editor ?? {};
        }
        worker.value.editor.textMode = type;
    }

    // editor.session.setMode('ace/mode/' + type);
    setEditorMode(type)
}
</script>


<template>
    <div class="worker-text-editor" v-if="worker && storeWorker">
        <div class="worker-text-editor-mode">
            <button 
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'text')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'text'}"
            >text</button>
            <button
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'html')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'html'}"
            >html</button>
            <button 
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'css')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'css'}"
            >css</button>
            <button 
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'javascript')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'javascript'}"
            >js</button>
            <button 
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'json')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'json'}"
            >json</button>
            <button 
                @click="(e)=>switchEditorModeBtnClickHandler(e, 'xml')"
                :class="{ '--worker-text-editor-mode': worker.editor?.textMode === 'xml'}"
            >xml</button>
        </div>
        <div class="worker-text-editor-box" ref="mountedBox">
            <Loader v-show="codeEditorLoading" />
        </div>
    </div>
</template>

<style lang="scss">
.worker-text-editor {

    position: relative;
    height: calc(100vh - 150px);
    padding-top: 20px;
    box-sizing: border-box;

    .loader .loader-box {
        z-index: 999;
    }

    .worker-text-editor-mode {
        top: 0;
        right: 0;
        position: absolute;
        display: flex;


        & > button {
            border: 0px;
            height: 20px;
            padding: 0 7px;
            color: #aeb3b4;
            font-family: "ubuntu-light";
            background-color: rgba(0, 0, 0, 0.1);
            user-select: none;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                background-color: rgba(0, 0, 0, 0.25);
            }

            &.--worker-text-editor-mode {
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
            }
        }
    }
}

.--console-opened {
    .worker-text-editor {
        height: calc(100vh - 380px);
    }

    .--split {
        .worker-text-editor {
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            .worker-text-editor {
                height: 50vh;
            }
        }
    }
}

.worker-text-editor-box {
    position: absolute;
    left: 0;
    top: 20;
    height: calc(100% - 20px);
    width: 100%;

    .ace_editor {
        width: 100%;
        height: 100%;
        font: 12px / normal 'Ubuntu Mono', monospace;
    }

    .ace-monokai {
        color: #aeb3b4;
        background: #1e2324;

        .ace_print-margin {
            display: none;
        }

        .ace_entity.ace_name.ace_tag,
        .ace_keyword,
        .ace_meta.ace_tag,
        .ace_storage {
            color: #d10707;
        }

        .ace_gutter {
            background: #2c3233;
            color: #aeb3b4;
            font-family: 'ubuntu-light';
        }

        .ace_storage.ace_type,
        .ace_support.ace_class,
        .ace_support.ace_type {
            color: #07658e;
        }

        .ace_entity.ace_name.ace_function,
        .ace_entity.ace_other,
        .ace_entity.ace_other.ace_attribute-name,
        .ace_variable {
            color: #08bb08;
        }

        .ace_identifier {
            color: #7edff2;
        }

        .ace_punctuation,
        .ace_punctuation.ace_tag {
            color: #aeb3b4;

            // & + .ace_identifier {
            //     // color: red
            // }
        }

        .ace_string {
            color: #aeb3b4;
        }

        .ace_comment {
            color: #7b7b7b;
        }
    }
}
</style>