<script setup lang="ts">
import { routeNames } from '@/router';
import { converWorkerType } from '@/utils';
// components
// import JobCard from './JobCard.vue';
import { WorkerIcon } from '@/modules/NewPage/components';
import AddJob from './AddJob.vue';
import { Label, Checkbox } from '@/components/kit';
// types
import { EJobType } from '../types';
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


const enabledWorkersTypes: string[] = [
  // js
	EJobType.jsSimple, EJobType.jsLocalStorage,

  // network
	EJobType.jsFetch, EJobType.jsDns, EJobType.jsNet,

  // db
	EJobType.jsPostgres, EJobType.jsMysql, EJobType.jsClickhouse, 

  // utils
  EJobType.jsNodeHtmlParser, EJobType.jsHtmlParser2, EJobType.jsYup,
  
  // apps
  EJobType.jsTelegram, EJobType.jsChatgpt,

  // text
  EJobType.text,
];

</script>

<template>
  <div class="workers-list-filter" v-if="navyStore.chainPage">
    <div class="form-group">
      <Label for="workers-list-page">View only the enabled workers</Label>
      <Checkbox v-model="navyStore.chainPage.viewActiveWorkers" id="workers-list-page" />
    </div>
  </div>
  <div v-if="navyStore?.chainPage?.viewActiveWorkers" class="workers-list --enabled-workers">
    <template v-if="store.jobs.length">
			<template v-for="jobItem of store.jobs" :key="jobItem.id">
        <template
          v-if="jobItem.enabled && ~enabledWorkersTypes.indexOf(jobItem.type)" 
            >
              <router-link
                :to="{
                  name: routeNames.chainEditor,
                  params: { chainID: store.pipelineId },
                  query: { worker_id: jobItem.id, ot: navyStore.openedWorkers }
                }"

                class="worker-link --worker-link-enabled"
                :title="jobItem.name"
              >
                <WorkerIcon :text="converWorkerType(jobItem.type)" class="worker-link-icon" :isBackground="true" />
                <span>{{ jobItem.name }}</span>
              </router-link>
              <i class="icon-down"></i>
            </template>
        </template>
      </template>
  </div>
	<div v-else class="workers-list">
		<AddJob :isFirstJob="true" />
		<template v-if="store.jobs.length">
			<template v-for="jobItem of store.jobs" :key="jobItem.id">

				<template
					v-if="~enabledWorkersTypes.indexOf(jobItem.type)" 
				>
					<router-link
            :to="{
              name: routeNames.chainEditor,
              params: { chainID: store.pipelineId },
              query: { worker_id: jobItem.id, ot: navyStore.openedWorkers }
            }"

            class="worker-link"
            :class="{'--worker-link-enabled': jobItem.enabled }"
            :title="jobItem.name"
					>
            <WorkerIcon :text="converWorkerType(jobItem.type)" class="worker-link-icon" :isBackground="true" />
            <span>{{ jobItem.name }}</span>
          </router-link>
				</template>

				<AddJob :parentJobOptions="jobItem" :disabled="jobItem.$loading" />
			</template>
		</template>
	</div>
</template>

<style lang="scss">
.workers-list {

  max-width: 480px;
  margin: 0 auto;

	.worker-link {
    display: flex;
    align-items: end;
    text-decoration: none;
    margin: 0 auto;
    max-width: max-content;

    &.--worker-link-enabled {
      .worker-link-icon {
        opacity: 1;
      }

      span {
        opacity: 1;
      }
    }

    .worker-link-icon {
      min-width: 35px;
      width: 35px;
      margin-right: 10px;
      opacity: .5;
    }

    span {
      font-size: 15px;
      padding-bottom: 3px;
      opacity: .5;
    }
  }
}

.--enabled-workers {
  .worker-link {
    margin: 15px auto;
  }

  .icon-down {
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  & .icon-down:last-child {
    display: none;
  }
}
</style>