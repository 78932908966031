<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { routeNames } from '@/router';
import { converWorkerType, appBus } from '@/utils';
// components
import { WorkerIcon } from '@/modules/NewPage/components';
// store
import { useNavyStore } from '@/store';
import { usePipelineStore } from '@/modules/pipeline/store';
const navyStore = useNavyStore();


const chainNavyClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();
    navyStore.toggleLeftNavy();
}

const closeWorkerEditor = (e: Event, workerId: string) => {
    e.preventDefault();
    e.stopPropagation();

    const navyWorker = navyStore.chainPage?.workersList.find(i => i.id === workerId);

    
    if (navyWorker?.unsaved) {
        //
        const isClose = window.confirm('close the unsaved worker`s tab?');
        
        if (!navyWorker) { return }

        if (isClose) {
            navyStore.openedTabs.delete(workerId);
            navyStore.closeTab(workerId);
            const store = usePipelineStore();

            const originWorker = store.jobs.find(i=>i.id === workerId);
            if (originWorker) {
                navyWorker.name = originWorker.name;
                navyWorker.enabled = originWorker.enabled;
                navyWorker.options.env = JSON.parse(JSON.stringify(originWorker.env));
                navyWorker.options.code = originWorker.code;
                navyWorker.unsaved = false;
            }
        }
    }
    else {
        navyStore.openedTabs.delete(workerId);
        navyStore.closeTab(workerId)
    }
    // const storeWorker = 
    // e; workerId;
}

// scroll tab into view
onMounted(()=>{
    const scrollHandler = ()=>{
        setTimeout(()=>{
            if (!navyStore.chainPage) { return }
            // activeID
            let activeID = 'active-chain-tab';
    
            if (!navyStore.chainPage.active) {
                const worker = navyStore.chainPage?.workersList.find(i => i.active);
                if (worker) {
                    activeID += '-' + worker.id;
                }
            }
    
            const tab = window.document.querySelector('#' + activeID);
            if (!tab) { return }
            tab.scrollIntoView({ behavior: 'smooth', block: 'center'});
        })
    };

    appBus.subscribe(appBus.eventList.routerAfterEach, scrollHandler);
    onUnmounted(()=>{
        appBus.unsubscribe(appBus.eventList.routerAfterEach, scrollHandler);
    });
});
</script>

<template>
    <!-- tabs navy -->
    <div class="options-tab" v-if="navyStore.chainPage">

        <div @click="chainNavyClickHandler" class="mobile-navy-btn">
            <button>
                <div></div>
                <div></div>
                <div></div>
            </button>
        </div>

        <div class="options-tab-list horizontal-scroll" v-if="navyStore.chainPage">
            <ul>
                <li 
                    :class="{
                        '--options-tab-list-unsaved': navyStore.chainPage.unsaved,
                        '--options-tab-list-enabled': navyStore.chainPage.enabled,
                    }" 
                    :title="navyStore.chainPage.name" id="active-chain-tab"
                >
                    <router-link 
                        :to="{
                            name: routeNames.chainEditor,
                            params: { chainID: navyStore.chainPage.chainId },
                            query: { ot: navyStore.openedWorkers }
                        }" 
                        :class="{
                            '--options-tab-list-active': navyStore.chainPage.active,
                        }"
                    >
                        <i class="icon-off-1"></i>
                        <span>{{ navyStore.chainPage.name }}</span>
                    </router-link>
                </li>

                <template v-for="tabData of navyStore.chainPage.workersList" :key="tabData.id">
                    <li 
                        v-if="tabData.opened"
                        class="worker-tab-title" 
                        :class="{
                            '--options-tab-list-unsaved': tabData.unsaved,
                            '--options-tab-list-enabled': tabData.enabled,
                        }" 
                        :title="tabData.name" 
                        :id="'active-chain-tab-' + tabData.id"
                    >
                        <router-link 
                            :to="{
                                name: routeNames.chainEditor,
                                params: { chainID: navyStore.chainPage.chainId },
                                query: {
                                    worker_id: tabData.id,
                                    ot: navyStore.openedWorkers,
                                }
                            }"

                            :class="{
                                '--options-tab-list-active': tabData.active,
                            }"
                        >
                            <i class="worker-tab-title-type">
                                <WorkerIcon :text="converWorkerType(tabData.type)" :is-background="true" />
                            </i>
                            <span>{{ tabData.name }}</span>
                        </router-link>

                        <button class="worker-tab-title-close-btn" @click="(e) => closeWorkerEditor(e, tabData.id + '')">
                            <i class="icon-cancel"></i>
                        </button>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>