<script setup lang="ts">
// components
import DeskTop from './DeskTop.vue';
import DeskTopHeader from './DeskTopHeader.vue';
import LogsPage from '@/modules/logs/LogsPage.vue';
// route
import { useRoute } from 'vue-router';


const route = useRoute();
</script>


<template>
    <DeskTop>
        <DeskTopHeader>
            <h1>Log viewer</h1>
        </DeskTopHeader>

        <div class="options-bar">
            <div class="options-bar-box">
                <div class="box">
                    <div class="box-title">
                        <h2>Log ID: {{ route.params?.logID ?? 'unknown'}}</h2>
                    </div>

                    <div class="box-body">
                        <LogsPage 
                            v-if="route.params?.logID && route.params?.chainID" 
                            :pipelineId="route.params!.chainID + ''"
                            :logId="route.params!.logID + ''"
                        />
                        <p v-else>route error</p>
                    </div>
                </div>
            </div>
        </div>
    </DeskTop>
</template>