<script setup lang="ts">
import { defineProps, reactive, watch, defineEmits } from 'vue';
import { QuestionViewerEditor } from "@/components/kit/QuestionViewer";
import { getUniqId } from '@/utils';


type TEnvArray = {
    id: string;
    key: string;
    value: string;
}[];

interface IJobEnvEditor {
    modelValue?: Record<string, string>;
}

const props = defineProps<IJobEnvEditor>();

const preparePropsToArray = ():TEnvArray => {
    return Object.entries(props.modelValue || {})
    .map(i=>{
        return {
            id: getUniqId(),
            key: (i[0] ?? '') + '',
            value: (i[1] ?? '') + ''
        }
    })
}

const envList = reactive<TEnvArray>(preparePropsToArray());

// update value on change
const emits = defineEmits(['update:modelValue']);
const addEnvListWatcher = () => watch(envList, ()=>emits('update:modelValue', prepareValueToModelValue()));
let removeEnvListWatcher = addEnvListWatcher();

watch(()=>props.modelValue, ()=>{
    removeEnvListWatcher();
    
    envList.splice(0, envList.length);
    envList.push(...preparePropsToArray());

    removeEnvListWatcher = addEnvListWatcher();
})

const addBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    envList.push({
        id: getUniqId(),
        key: 'KEY_' + Math.random().toString(36).replace('0.','') + '_' + envList.length,
        value: 'VALUE'
    })
}

const deleteBtnClickHandler = (e: Event, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    const list = envList.filter(i=>i.id!==id).map(i=>{return {...i}});
    while (envList.length) { envList.pop() }
    envList.push(...list);
}

// helpers
const keyValidator = (text: string): boolean => {
    if (text === '') { return true }
    return /^[a-z0-9-_]{1,}$/ig.test(text)
}

const prepareValueToModelValue = (): Record<string, string> => {
    return Object.fromEntries(
        envList
            .filter(i=>i.key)
            .map(i=>[i.key, i.value])
    );
}
</script>

<template>
    <div class="env-editor-ctrl">

        <div v-for="item of envList" :key="item.id" class="env-editor">
            <QuestionViewerEditor 
                v-model="item.key"
                :isEditable="true"
                :validator="keyValidator"
            />
            <QuestionViewerEditor
                v-model="item.value"
                :isEditable="true"
                :secure="true"
                :type="item.value.length < 30 ? void 0 : 'textarea'"
            />

            <button @click="(e: Event)=>deleteBtnClickHandler(e, item.id)" class="ui-btn">
                <i class="icon-trash-empty"></i>
            </button>
        </div>

        <button 
            @click="addBtnClickHandler" 
            class="ui-btn add-btn" title="add env"
        >
            <i class="icon-plus-squared-alt"></i>
            <span>add env</span>
        </button>
                
    </div>
</template>

<style lang="scss">
.env-editor-ctrl {
    padding: 0 0 20px;

    .env-editor {
        
        display: flex;
        margin: 7px 0;

        .value-editor {
            width: 100%;
        }

        .ui-btn {
            height: 43px;
            background: #3b464b;
            border-radius: 0;

            i {
                line-height: 42px;
            }

            &:hover {
                color: #fff;
                background-color: #3b464b99;
                opacity: 1;
            }

            &:focus {
                color: #fff;
                background-color: #3b464b99;
            }
        }
    }

    @media screen and (max-width: 540px) {
            .env-editor { 
                flex-direction: column;
            }

            .ui-btn i {
                margin: 0 auto;
            }

            .add-btn {
                width: 100%;
                margin-top: 10px;
                background: #3b464b;
                padding: 10px;
                height: 50px;

                i {
                    margin: 1px 0;
                }
            }
    }
}
</style>