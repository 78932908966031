import { createApp } from 'vue'
import App from '@/App.vue'
// stores
import { createPinia } from 'pinia';
// routes
import { router } from '@/router';
// modules
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

// components

// app 
export const app = createApp(App);

// store
app.use(createPinia())

// add routes
app.use(router);
app.use(VueMonacoEditorPlugin, {
    paths: {
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    },
})
// mount app
app.mount('#app');

export type TApp = typeof app;
