<script setup lang="ts">
import { defineProps, ref, watch, onUnmounted } from 'vue';
// store
import { useLogsStore } from './store';
// components
import Loader from '@/components/Loader.vue';
import { Input, Label } from '@/components/kit'
// utils
import { dateIsoToHumanDateTime } from '@/utils';
import { routeNames } from '@/router';


export interface ILogsPageProps {
    pipelineId: string;
    logId: string;
}

// props
const props = defineProps<ILogsPageProps>()


// store
const store = useLogsStore();
store.getData(props.pipelineId, props.logId);

const logs = () => {
    const logs = store.logs + '';
    try {
        return logs ? JSON.parse(logs) : null
    } catch (e: any) {
        return null
    }
}


const logText = ref<string>('');
const duration = ref<number>(0);
const errorText = ref<string>('');
const logsObject = ref<any>(logs());

watch(()=>store.logs, ()=>{
    logsObject.value = logs();
    const json = logsObject.value;
    if (json) {
        logText.value = JSON.stringify(json, null, "\t");

        if (Array.isArray(json?.logs)) {
            json.logs.forEach((i: any)=>{
                if (typeof i?.duration === 'number') {
                    duration.value += i.duration;
                }
                if (i?.error_message) {
                    errorText.value = i.error_message
                }
            });
        }
    }
    else {
        logText.value = store.logs;
    }
});

onUnmounted(()=>{
    store.$reset();
    logText.value = '';
    duration.value = 0;
    errorText.value = '';
    logsObject.value = null;
});
</script>
<template>
    <div class="log-viewer-page">

        <Loader v-if="store.$loading"></Loader>
    
        <template v-if="!store.$loading && store.pipelineName">

            <p v-if="store.isSuccess" class="--log-viewer-success">
                <i class="icon-ok-circled"></i>
                <span>success</span>
            </p>
            <p v-else class="--log-viewer-error">
                <i class="icon-cancel-circled"></i>
                <span>error</span>
            </p>


            <p>Execution date: <b>{{ dateIsoToHumanDateTime(store.updatedAt) }}</b></p>
            <p>Execution ID: <b>{{ store.logId }}</b></p>

            <p>
                <router-link 
                    :to="{ name: routeNames.chainEditor, params: { chainID: store.pipelineId } }"
                    :title="store.pipelineName"
                >
                    Chain name: <b>{{ store.pipelineName }}</b>
                </router-link>
            </p>

            <p>
                <router-link 
                    :to="{ name: routeNames.chainEditor, params: { chainID: store.pipelineId } }"
                    :title="store.pipelineName"
                >
                    Chain ID: <b>{{ store.pipelineId }}</b>
                </router-link>
            </p>

            <router-link 
                :to="{ name: routeNames.logs, query: { chain_id: store.pipelineId } }"
                :title="store.pipelineName"
                class="ui-btn"
            >
                <i class="icon-list-bullet"></i>
                <span>The list of logs of this chain</span>
            </router-link>

            <p>
                Duration (ms): <b>{{ duration }}</b> 
            </p>

            <p v-if="errorText" class="--log-viewer-error">
                Error message: <b>{{ errorText }}</b> 
            </p>

            <div class="form-group workers-result-list" v-if="logsObject?.logs">
                <Label>Worker`s result list</Label>

                <router-link v-for="(log, index) of logsObject.logs" :key="index"
                    :to="{
                        name: routeNames.chainEditor, 
                        params: { chainID: store.pipelineId },
                        query: { worker_id: log.job_id },
                    }" 
                    class="qa-worker-result"
                >
                    <i v-if="log.status" class="icon-ok-circled --success"></i>
                    <i v-else class="icon-cancel-circled --error"></i>
                    <span class="name">{{ log.job_name }}.</span>
                    <span class="duration">{{ log.duration }}ms.</span>
                    <span class="error" v-if="log.error_message">{{ log.error_message }}</span>
                </router-link>
            </div>

    
            <div class="form-group all-logs">
                <Label>Detailed log:</Label>
                <Input
                    v-model="logText"
                    type="textarea"
                    disabled
                />
            </div>
            <pre id="log-viewer-printer">{{ logText }}</pre>
    
        </template>
    </div>

</template>

<style lang="scss">
.log-viewer-page {
    position: relative;
    min-height: 500px;

    p {
        margin: 25px 0;
        line-height: 1.35em;

        & > a {
            text-decoration: none;
        }

        b {
            font-weight: 600;
            color: #fff;
        }
    }

    p.--log-viewer-success {
        color: #0bbb08;
        span {
            letter-spacing: .5px;
            padding-left: 7px;
        }
    }

    p.--log-viewer-error {
        color: #d10607;
        span {
            letter-spacing: .5px;
            padding-left: 7px;
        }
    }

    .ui-btn {
        margin: 20px 0;
    }

    .all-logs {
        textarea {
            height: 300px;
            resize: vertical;
        } 
    }

    .workers-result-list {
        .qa-worker-result {
            margin: 10px 0;
            padding-left: 10px;
            text-decoration: none;
            display: block;

            .--success {
                color: #0bbb08;
            }

            .--error {
                color: #d10607;
            }

            .name {
                margin: 0 8px;
            }

            .duration {
                opacity: .6;
            }

            .error {
                padding-left: 20px;
            }

            .form-group {
                margin: 30px 0 0;
            }

        }
    }

    // printer
    #log-viewer-printer {
        display: none;
    }

    @media print {
        #log-viewer-printer {
            display: initial;
            // border-top: 3px solid #000;
            width: 100%;
            display: block;
        }

        .all-logs {
            display: none;
        }
    }

}


</style>