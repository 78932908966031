<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref, watch } from 'vue';
import vSelect from "vue-select";
// types
import { TSelectOption, ISelect } from './types';
// utils
import { getUniqId } from '@/utils';


// props
const props = defineProps<ISelect>();

const idi = props.id || getUniqId();
const data = ref<string | string[]>(props.modelValue || []);
const noSearch = props.noSearch || false;
const multiple = Boolean(props.multiple || false);

defineComponent({ name: "custom-select" });

// emits
const emits = defineEmits(['update:modelValue']);

const addDataWatcher = ()=>watch(data, () => {
    if (multiple) {
        emits('update:modelValue', [...data.value]);
    }
    else {
        emits('update:modelValue', data.value);
    }
});

let removeDataWatcher = addDataWatcher();

watch(()=>props.modelValue, (val, b)=>{
    removeDataWatcher();

    if (multiple) {
        data.value = new Array(...(val || []));
    }
    else {
        data.value = val + '';
    }

    removeDataWatcher = addDataWatcher();

    b;
})

// reduce
const reduce = (option: TSelectOption | string): string => {
    if (typeof option === 'string') {
        return option
    }
    return option.value;
}

// methods
const filter = (options: TSelectOption[], search: string) => {
    const regexp = new RegExp(search, 'i');

    return options.filter((item) => {
        return regexp.test(item.label) || regexp.test(item.value)
    })
}
</script>

<template>
    <div :class="[
        'form-select',
        noSearch ? '-no-search' : '',
        multiple ? '-multiple' : '',
        disabled ? '--form-select-disabled' : '',
    ].join(' ')">
        <vSelect 
            :inputId="idi" 
            inputClass="" 
            :options="options" 
            :multiple="multiple" 
            :filter="filter"
            :searchable="!noSearch" 
            :disabled="Boolean(disabled)" 
            :reduce="reduce" 

            v-model="data"
        >
            <template #no-options="{}">
                Ничего не найдено
            </template>
        </vSelect>
    </div>
</template>

<style lang="css">
@import 'vue-select/dist/vue-select.css';
</style>

<style lang="scss">

    $selectBg: #3b464b;
    $selectColor: #76838f;
    $disabledCl: #1e2324;

    :root {
        --vs-controls-color: #76838f;

        --vs-border-width: 2px;
        --vs-border-style: solid;
        --vs-border-color: #3b464b;

        --vs-border-radius: 0px;
        --vs-search-input-color: #76838f;
        --vs-line-height: 27px;
        --vs-font-size: 15px;
    }

    

    .form-select {
        border: none;
        font-family: "ubuntu-light", sans-serif;

        input[type="search"].vs__search {
            cursor: text;
            min-height: 35px;
            font-weight: 300;
            box-sizing: border-box;
            background: $selectBg;
            border-radius: 0;
            font-family: "ubuntu-light", sans-serif;
            font-size: 15px;
            border: none;
            margin: 0;
        }

        .vs--searchable {
            background: $selectBg;
        }

        .vs--single .vs__selected {
            font-size: 14px;
            font-weight: 300;
        }

        .vs__selected {
            font-size: 14px;
            font-weight: 300;
            background-color: $selectBg;
            color: $selectColor;

            @media screen and (max-width: 540px) {
                max-width: 180px;
            }
        }

        .-multiple .vs__selected {
            border-color: $selectColor;
        }

        .vs__dropdown-toggle {
            cursor: text;
            min-height: 43px;
            font-weight: 300;
            box-sizing: border-box;
            border-color: $selectBg;
            background: $selectBg;
            border-radius: 0px;
            font-size: 15px;
            box-sizing: border-box;
        }

        .vs__dropdown-option {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.55em;
            margin: 0;
        }

        .vs__dropdown-option--highlight {
            background: $selectBg;
            color: $selectColor;
            font-weight: 400;
        }

        .vs--open .vs__dropdown-toggle{
            border-color: $selectColor;
            padding: 0;
        }

        .vs__dropdown-menu {
            border-color: $selectColor;
            background-color: $selectBg;
        }

        .vs__no-options {
            font-size: 14px;
            font-weight: 300;
            color: $selectColor;
            background-color: $selectBg;
        }

        &.-no-search {
            .vs__dropdown-toggle {
                cursor: pointer;
            }

            input[type="search"].vs__search {
                display: none;
            }
        }

        & .vs__actions {
            .vs__clear {
                margin-top: 2px;
                transition: opacity .25s;

                &:hover {
                    opacity: .7;
                }

                &:focus {
                    outline: none;
                    opacity: .85;
                    fill: #fff;
                }
            }
        }

        .vs__deselect {
            transition: opacity .25s;

            &:hover {
                opacity: .7;
            }

            &:focus {
                outline: none;
                opacity: .85;
                fill: #fff;
            }
        }

        &.--form-select-disabled {
            .vs__dropdown-toggle {
                background-color: $disabledCl;
                border-color: $disabledCl;
            }

            .vs__selected {
                background-color: $disabledCl;
                border-color: $disabledCl;
            }

            input[type=search].vs__search {
                background-color: $disabledCl;
            }

            .vs__actions {
                display: none;
            }

            .vs__deselect {
                opacity: .3;
                cursor: not-allowed;
            }
        }
    }


</style>