<script setup lang="ts">
import { ref, watch } from 'vue';
import { routeNames, router } from '@/router';
// components
import TopTabsNavy from './components/TopTabsNavy.vue';
import ChainEditor from './components/ChainEditor.vue';
import JobEditor from './components/JobEditor.vue';
// store
import { useNavyStore } from '@/store';


const navyStore = useNavyStore();

const getActiveWorker = () => navyStore.chainPage?.workersList.find(i=>i.active);
const activeWorker = ref(getActiveWorker());

watch( getActiveWorker, (aw)=>{
  activeWorker.value = aw;
  if (!aw && navyStore.chainPage) {
    
    setTimeout(()=>{
      if (!navyStore.chainPage) { return }
      router.push({
        name: routeNames.chainEditor,
        params: { chainID: navyStore.chainPage.chainId },
        query: { ot: navyStore.openedWorkers },
      })
    }, 200)
  }
})
</script>

<template>

  <TopTabsNavy />

  <template v-if="navyStore.chainPage?.chainId">
    <ChainEditor v-if="navyStore.chainPage.active" />
    <JobEditor v-else-if="activeWorker" />
  </template>
  
</template>
