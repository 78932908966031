<script setup lang="ts">
import { defineProps, watch, ref } from 'vue';
// components
// import Row from '@/components/kit/Row.vue';
import { TablePaginationAppBus } from '@/components/Table';
import PaginationBtn from './PaginationBtn.vue';
import PaginationPrevBtn from './PaginationPrevBtn.vue';
import PaginationNextBtn from './PaginationNextBtn.vue';
import PaginationDelimeter from './PaginationDelimeter.vue';
import { Input } from '@/components/kit';
// types
import { TTableStore } from '@/components/Table/tableStore';
// app bus
import { appBus } from '@/utils';


const props = defineProps<{ useStore: TTableStore<any>, paginationEventName?: string }>();
const store = props.useStore();

watch(() => [store.paginatePage, store.paginateFiltered, store.paginatePerPage, store.paginateViewed], () => {
    pageIndex.value = store.paginatePage + '';

    // set focus to active btn
    setTimeout(() => {
        var nextA = document.querySelector('.pagination .active a');
        if (nextA) {
            nextA?.focus({ preventScroll: true })
        }
    });
});

const pageIndex = ref<string>(store.paginatePage + '');
const pageFormSubmitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    var pageInt = Number.parseInt(pageIndex.value);
    if (Number.isInteger(pageInt)) {
        // store.setPage(pageInt)
        appBus.dispatch(props.paginationEventName || '', { page: pageInt });
    }
}

const inputPageIndexValidator = (val: string) => {
    if (!val) { return true }

    if (/^\d+$/.test(val)) {
        var int = Number.parseInt(val);
        if (int < 1) { return false }
        if (int > 9999) { return false }
        return true
    }
    return false
}
</script>

<template>
    <div class="table-pagination">
        <div class="table-pagination-meta">
            <p>{{ store.paginateInfo }}</p>
        </div>

        <div class="table-pagination-ctrl">
            <div class="table-pagination-btns">
                <form class="table-pagination-form" @submit="pageFormSubmitHandler">
                    <Input v-model="pageIndex" :validator="inputPageIndexValidator" />
                </form>
                <ul class="pagination">
                    <TablePaginationAppBus 
                        :store="props.useStore"
                        :btn="PaginationBtn"
                        :prevBtn="PaginationPrevBtn"
                        :nextBtn="PaginationNextBtn"
                        :delimeter="PaginationDelimeter"
                        :eventName="paginationEventName"
                    />
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.table-pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: normal;
    align-content: normal;
    background: #181c1d;
    padding: 9.5px 12px;

    .table-pagination-meta {
        display: flex;
        width: 100%;
        color: #aeb3b4;

        p {
            line-height: 36px;
        }
    }

    .table-pagination-ctrl {
        display: flex;
        width: 100%;

        .table-pagination-btns {


            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: normal;
            width: 100%;
            align-content: normal;
            justify-content: flex-end;

            .table-pagination-form {
                .form-control {
                    background-color: #1e2324;
                    font-size: 14px;
                    line-height: 22px;
                    width: 55px;
                    padding: 5px 5px;
                    text-align: center;
                }
            }

            .pagination {
                color: #aeb3b4;
                display: flex;
            }
        }
    }

    .page-link {
        line-height: 36px;
        min-width: 36px;
        box-sizing: border-box;
        display: block;
        text-align: center;
        padding: 0 3px;
        background: #181c1d;
        transition-duration: .25s;

        &:focus {
            background: #00000040;
            color: #fff;
        }

    }

    .active {
        .page-link {
            background: #00000080;
            color: #fff;
        }
    }

    @media screen and (max-width: 680px) {
        & {
            flex-direction: column-reverse;
        }

        .table-pagination-meta {
            display: block;
            text-align: center;
            margin-top: 25px;
        }

        .table-pagination-ctrl {
            .table-pagination-btns {
                justify-content: space-between;
                width: 100%;
            }

            .table-pagination-form .form-control {
                width: 42px;
                padding: 5px 5px;
                text-align: center;
            }

            .delimeter {
                width: 10px;
                overflow: hidden;
                min-width: initial;
            }
        }

        .pagination {

            li a {
                display: none;
            }

            li.previous a {
                display: block;
            }

            li.next a {
                display: block;
            }

            li.active a {
                display: block;
            }

            li:nth-child(2)>a {
                display: block;
            }

            li:nth-last-child(2)>a {
                display: block;
            }
        }
    }
}
</style>