<script setup lang="ts">
import { defineComponent } from 'vue';


defineComponent({ name: 'loader-view' });
</script>

<template>
    <div class="loader">
        <div class="loader-box">
            <div class="circle-loader">
                <i class="icon-spin1 animate-spin"></i>
                <span>loading...</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.loader {
    .loader-box {

    
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .circle-loader {
            position: relative;
            color: #fff;
            opacity: 0.8;
            display: flex;
            padding: 10px;

            span {
                margin-left: 7px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            animation: loader-animated-bg 2s linear infinite;
        }

    }
}

@keyframes loader-animated-bg {
    0% {
        background-color: #1e2324;
    }


    50% {
        background-color: #22282a;
    }

    100% {
        background-color: #1e2324;
    }
}
</style>