<script setup lang="ts">
import { ITableCellProps } from '@/components/Table';
import { routeNames } from '@/router';
import { defineProps } from 'vue';

const props = defineProps<ITableCellProps>();

</script>

<template>
    <td :class="props.class || ''">
        <div class="chainslist-page-ctrl">
            <!-- <router-link 
                class="ui-btn"
                :to="{ name: routeNames.pipeline, params: { id: props.value } }"
                title="edit chain"
            >
                <i class="icon-pencil"></i>
                <span>edit</span>
            </router-link> -->
            <router-link 
                class="ui-btn" 
                :to="{ name: routeNames.logs, query: { chain_id: props.value } }"
                title="view logs"
            >
                <i class="icon-list-bullet"></i>
                <span>logs</span>
            </router-link>
        </div>
    </td>
</template>