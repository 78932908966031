<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();

</script>

<template>
    <td class="logs-status-badge" :class="props.class || ''">
        <div v-if="props.value" class="success">
            <i class="icon-ok-circled"></i>
            ok
        </div>
        <div v-else class="error">
            <i class="icon-cancel-circled"></i>
            error
        </div>
    </td>
</template>
<style>
.logs-status-badge {
    width: 90px;
    box-sizing: border-box;

    & i {
        padding-right: 5px;
    }

    .success {
        display: flex;
        color: #08bb08;
        width: 58px;
    }

    .error {
        display: flex;
        color: #d10707;
        width: 58px;
    }
}
</style>