<script setup lang="ts">
import { QuestionViewerEditor } from '@/components/kit/QuestionViewer';
import {
    defineProps,
    defineEmits,
    reactive,
    watch,
} from 'vue';
// components
import EnvEditor from './EnvEditor.vue';
import QuestionViewerEditorSelect from '@/components/kit/QuestionViewer/QuestionViewerEditorSelect.vue';
import { Label, LabelDescription } from '@/components/kit';
// store
import { ETriggerTypes, TDataState } from '../store';


// types
type TTrigger = TDataState['trigger'];
interface ITriggerEditorProps {
    modelValue: TTrigger;
}


// props
const props = defineProps<ITriggerEditorProps>();
// const value = reactive<TTrigger>(props.modelValue);

// emits
const emits = defineEmits(['update:modelValue']);

const webhookMethods = [
    {
        value: 'get',
        label: 'GET'
    },
    {
        value: 'post',
        label: 'POST'
    },
    {
        value: 'put',
        label: 'PUT'
    },
    {
        value: 'patch',
        label: 'PATCH'
    },
    {
        value: 'delete',
        label: 'DELETE'
    },
];


const cronErrorCaption = 'only * or number or */number ex.: */5';

// helpers
const isWebhookTypeHelper = (value: string) => {
    return ~[ETriggerTypes.webhook, ETriggerTypes.syncWebhook].indexOf(value as ETriggerTypes);
}

// validators
const cronValidatorM = (str: string) => {
    return (str === '' || /^(\*|(\*\/)?([1-5]?[0-9]|0)|\*\/)$/.test(str)) // && str !== '*/'
}
const cronValidatorH = (str: string) => {
    return (str === '' || /^(?:[0-9]|1[0-9]|2[0-3]|\*\/|\*|\*\/(?:[1-9]|1[0-9]|2[0-3]|0))$/.test(str)) // && str !== '*/'
}
const cronValidatorD = (str: string) => {
    return (str === '' || /^(?:[1-9]|[12][0-9]|3[01]|\*\/|\*|\*\/(?:[1-9]|[12][0-9]|3[01]))$/.test(str)) // && str !== '*/'
}
const cronValidatorMN = (str: string) => {
    return (str === '' || /^(?:[1-9]|1[0-2]|\*\/|\*|\*\/(?:[1-9]|1[0-2]))$/.test(str)) // && str !== '*/'
}

const cronMinutesValidator = (minutes: string): boolean => {
    if (minutes === '*') { return true }
    if (minutes.startsWith('*/')) {
        const number = parseInt(minutes.replace('*/', ''));
        return Number.isInteger(number) && number >= 0 && number <= 59;
    }
    const number = parseInt(minutes);
    return (number.toString() === minutes && Number.isInteger(number) && number >= 0 && number <= 59)
}
const cronHoursValidator = (minutes: string): boolean => {
    if (minutes === '*') { return true }
    if (minutes.startsWith('*/')) {
        const number = parseInt(minutes.replace('*/', ''));
        return Number.isInteger(number) && number >= 0 && number <= 23;
    }
    const number = parseInt(minutes);
    return (number.toString() === minutes && Number.isInteger(number) && number >= 0 && number <= 23)
}
const cronDaysValidator = (minutes: string): boolean => {
    if (minutes === '*') { return true }
    if (minutes.startsWith('*/')) {
        const number = parseInt(minutes.replace('*/', ''));
        return Number.isInteger(number) && number >= 0 && number <= 31;
    }
    const number = parseInt(minutes);
    return (number.toString() === minutes && Number.isInteger(number) && number >= 0 && number <= 31)
}
const cronMonthsValidator = (minutes: string): boolean => {
    if (minutes === '*') { return true }
    if (minutes.startsWith('*/')) {
        const number = parseInt(minutes.replace('*/', ''));
        return Number.isInteger(number) && number >= 1 && number <= 12;
    }
    const number = parseInt(minutes);
    return (number.toString() === minutes && Number.isInteger(number) && number >= 1 && number <= 12)
}

// for kit select
const optionsTriggerStatuses = [
    {
        value: ETriggerTypes.cron,
        label: 'cron'
    },
    {
        value: ETriggerTypes.webhook,
        label: 'webhook'
    },
    {
        value: ETriggerTypes.syncWebhook,
        label: 'sync webhook'
    }
];


const cronOptionsIsValid = reactive({
    minutes: true,
    hours: true,
    days: true,
    months: true
});

// watch callbacks
const cronWatchCb = () => {
    const { m, h, d, mn } = trigger.options;
    cronOptionsIsValid.minutes = cronMinutesValidator(m);
    cronOptionsIsValid.hours = cronHoursValidator(h);
    cronOptionsIsValid.days = cronDaysValidator(d);
    cronOptionsIsValid.months = cronMonthsValidator(mn);

    const isAllOptionsValid = Object.values(cronOptionsIsValid).reduce((a, b) => a && b, true);

    if (isAllOptionsValid) {
        const updated: TTrigger = {
            type: trigger.type,
            options: { m, h, d, mn }
        };
        emits('update:modelValue', updated)
    }
}

const webhookWatchCb = () => {

    const updated: TTrigger = {
        type: trigger.type,
        options: {
            path: trigger.options.path,
            method: trigger.options.method,
            "header-auth": JSON.stringify(trigger.options.headerAuth),
            "query-auth": JSON.stringify(trigger.options.queryAuth),
        }
    }
    emits('update:modelValue', updated)
}



const jsonParse = (data: any) => {
    let result: Record<string, string> = {};

    try {
        result = JSON.parse(data);
    }
    catch(e){
        //
    }

    return result;
}

const importTriggerOptions = () => {
    return {
        type: props.modelValue.type,
        options: {
            // cron
            m: props.modelValue.options?.m || '*',
            h: props.modelValue.options?.h || '*',
            d: props.modelValue.options?.d || '*',
            mn: props.modelValue.options?.mn || '*',

            // webhook
            path: props.modelValue.options?.path || '',
            method: props.modelValue.options?.method || 'get',
            headerAuth: jsonParse(props.modelValue.options?.['header-auth']),
            queryAuth: jsonParse(props.modelValue.options?.['query-auth']),
        }
    }
}

const trigger = reactive(importTriggerOptions());


const addTriggerSettingsWatcher = ()=> watch(trigger, () => {
    if ( trigger.type === 'cron') {
        cronWatchCb()
    }
    else if ( isWebhookTypeHelper(trigger.type) ) {
        webhookWatchCb()
    }
});

let removeTriggerSettingsWatcher = addTriggerSettingsWatcher();

watch(()=>props.modelValue, ()=>{
    removeTriggerSettingsWatcher();

    Object.assign(trigger, importTriggerOptions());

    removeTriggerSettingsWatcher = addTriggerSettingsWatcher();
})


const webhookHost = location.protocol + '//' + location.host;

const webhookPathHelper = (t: string)=>{
    return t === 'sync-webhook' ? 'sync-webhook' : 'webhook'
}
</script>


<template>
    <div class="pipeline-trigger-editor">

        <QuestionViewerEditorSelect v-model="trigger.type" :options="optionsTriggerStatuses" :isEditable="true" />

        <div v-if="isWebhookTypeHelper(trigger.type)" class="pipeline-trigger-editor-options">
            <div class="trigger-webhook-editor">

                <div class="form-group">
                    <Label>
                        Webhook URL
                        <LabelDescription>{{ 
                            trigger.options.path ? 
                                webhookHost + '/' + webhookPathHelper(trigger.type) + trigger.options.path : 
                                'it will be installed automatically after saving' 
                            }}
                        </LabelDescription>
                    </Label>
                </div>

                <div class="form-group webhook-method">
                    <Label>Method</Label>
                    <QuestionViewerEditorSelect v-model="trigger.options.method" :options="webhookMethods" :isEditable="true" />
                </div>

                <div class="form-group">
                    <Label>Header auth</Label>
                    <EnvEditor v-model="trigger.options.headerAuth" />
                </div>

                <div class="form-group">
                    <Label>Query auth</Label>
                    <EnvEditor v-model="trigger.options.queryAuth" />
                </div>
            </div>
        </div>

        <div v-else-if="trigger.type === 'cron'" class="pipeline-trigger-editor-options">
            <div class="trigger-cron-editor">

                <div class="form-group">
                    <Label>Minutes <code>(0-59)</code></Label>
                    <QuestionViewerEditor v-model="trigger.options.m" :isEditable="true" :validator="cronValidatorM">
                        <p>minutes should be in the range 1-59.</p>
                        <p>only * or number or */number ex.: */5.</p>
                    </QuestionViewerEditor>
                </div>

                
                <div class="form-group">
                    <Label>Hours <code>(0-23)</code></Label>
                    <QuestionViewerEditor v-model="trigger.options.h" :isEditable="true" :validator="cronValidatorH">
                        <p>{{ cronErrorCaption }}</p>
                    </QuestionViewerEditor>
                </div>
                
                <div class="form-group">
                    <Label>Days <code>(1-31)</code></Label>
                    <QuestionViewerEditor v-model="trigger.options.d" :isEditable="true" :validator="cronValidatorD">
                        <p>{{ cronErrorCaption }}</p>
                    </QuestionViewerEditor>
                </div>

                <div class="form-group">
                    <Label>Months <code>(1-12)</code></Label>
                    <QuestionViewerEditor v-model="trigger.options.mn" :isEditable="true" :validator="cronValidatorMN">
                        <p>{{ cronErrorCaption }}</p>
                    </QuestionViewerEditor>
                </div>

            </div>
        </div>

        <p v-else class="card-description text-danger">An unsupported trigger type is installed</p>

    </div>
</template>
<style lang="scss">
.pipeline-trigger-editor {
    .pipeline-trigger-editor-options {
        padding-left: 50px;

        @media screen and (max-width: 680px) {
            padding-left: 35px;
        }
    }

    .webhook-path {
        strong {
            display: block;
            padding-top: 10px;
        }
    }

    .webhook-method {
        label {
            margin-bottom: 0;
        }
    }
}

.color-red {
    color: #d10707
}
</style>