<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({name: 'kit-table-pagination-delimeter'});

</script>

<template>
    <li class="paginate_button page-item">
        <a class="page-link delimeter">
            <span class="delimeter-max">&hellip;</span>
            <span class="delimeter-min">|</span>
        </a>
    </li>
</template>

<style lang="scss">
    .paginate_button.page-item .delimeter {
        cursor: no-drop;

        .delimeter-min {
            display: none;
            opacity: .4;
        }

        .delimeter-max {
            opacity: .4;
        }
        @media screen and (max-width: 680px) {
            .delimeter-max {
                display: none;
            }
            .delimeter-min {
                display: initial;
            }
        }
    }
</style>