import { get } from "@/service/bffService";
import { TGetLogItemDto, TGetLogListDto, TGetLogListQueryReqDto } from "./types";

export default class LogsService {
        // get log item 
        static async getLogItem(pipelineId: string, logId: string): Promise<TGetLogItemDto> {
            return get<TGetLogItemDto>(`/api/logs/${pipelineId}/${logId}`)
        }

        // get log list
        static async getLogList(query: TGetLogListQueryReqDto): Promise<TGetLogListDto> {
            const searchParams = new URLSearchParams();
            query.pipeline_id && searchParams.append('pipeline_id', query.pipeline_id);
            query.status && searchParams.append('status', query.status);
            query.page && searchParams.append('page', query.page + '');
            query.page_size && searchParams.append('per_page', query.page_size + '');

            return get<TGetLogListDto>(`/api/logs?${searchParams.toString()}`)
        }
}