<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import { routeNames } from '@/router';

const props = defineProps<ITableCellProps>();

</script>

<template>
    <td :class="props.class || ''">
        <router-link :to="{ name: routeNames.chainEditor, params: { chainID: props.rowData.id }}"
            :title="props.value"
        >
            {{ props.value }}
        </router-link>
    </td>
</template>