export enum EJobType {
    // js
    jsSimple = 'js-simple',
    jsLocalStorage = 'js-local-storage',
    
    // net
    jsFetch = 'js-fetch',
    jsDns = 'js-dns',
    jsNet = 'js-net',
    
    // storages
    jsPostgres = 'js-postgres',
    jsMysql = 'js-mysql',
    jsClickhouse = 'js-clickhouse',
    
    // utils
    jsHtmlParser2 = 'js-html-parser',
    jsNodeHtmlParser = 'js-node-html-parser',
    jsYup = 'js-yup',

    // services
    jsTelegram = 'js-telegram',
    jsChatgpt = 'js-chatgpt',

    // text
    text = 'text',
}

export const typeNameHelper = (jobType: string): string => {
    const dictionary: Record<string, string> = {
        [EJobType.jsSimple]: 'JavaScript job',
        [EJobType.jsLocalStorage]: 'JavaScript job with localStorage utility',
        
        // net
        [EJobType.jsFetch]: 'JavaScript job with fetch',
        [EJobType.jsDns]: 'JavaScript job with DNS',
        [EJobType.jsNet]: 'JavaScript job with NET',
        
        // storages
        [EJobType.jsPostgres]: 'JavaScript job with Postgres',
        [EJobType.jsMysql]: 'JavaScript job with Mysql',
        [EJobType.jsClickhouse]: 'JavaScript job with Clickhouse',
        
        // utils
        [EJobType.jsNodeHtmlParser]: 'JavaScript job with Node-HTML-Parser',
        [EJobType.jsHtmlParser2]: 'JavaScript job with HTMLParser2',
        [EJobType.jsYup]: 'JavaScript job with Yup lib',


        // services
        [EJobType.jsTelegram]: 'JavaScript job with Telegram',
        [EJobType.jsChatgpt]: 'JavaScript job with ChatGPT',

        // text
        [EJobType.text]: 'Text job',
    }

    return dictionary[jobType] ?? 'unknown';
}

export const converWorkerType = (workerType: string)=>{

    const dict: Record<string, string> = {
        [EJobType.jsSimple]: 'Js',
        [EJobType.jsLocalStorage]: 'LSt',
        
        // net
        [EJobType.jsFetch]: 'Fe4', 
        [EJobType.jsDns]: 'Dns', 
        [EJobType.jsNet]: 'Net',
        
        // storages
        [EJobType.jsPostgres]: 'Pg', 
        [EJobType.jsMysql]: 'Mys',
        [EJobType.jsClickhouse]: 'Cho',
        
        // utils
        [EJobType.jsNodeHtmlParser]: 'Pnh',
        [EJobType.jsHtmlParser2]: 'Ph2',
        [EJobType.jsYup]: 'Yup',

        // services
        [EJobType.jsTelegram]: 'Tel',
        [EJobType.jsChatgpt]: 'Gpt',

        // text
        [EJobType.text]: 'Txt',
    };

    return dict?.[workerType] || '?';
}