<script setup lang="ts">
// components
import LeftNavy from './LeftNavy.vue';
// store
import { useNavyStore } from '@/store';
const navyStore = useNavyStore();

</script>


<template>
    <div class="chain-editor" :class="{ '--navy-opened': navyStore.leftNavyOpen }">
        <LeftNavy />
        <router-view />
    </div>
</template>


<style lang="scss">
@import "./reset.css";
@import "./fello-builded.css";
@import "./animation.css";

html,
body {
    height: 100%;
}

@import url('@/assets/css/ubuntu-fonts.css');
@import url('@/assets/css/inputs.scss');

body {
    background-color: #363e40;
    // background-image: url(https://themewagon.github.io/dashtreme/assets/images/bg-themes/2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "ubuntu-light";
}

a {
    color: inherit;
}

.chain-editor {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;

    // background: gray;
    position: absolute;
    left: 0;
    // right: 0;
    top: 0;
    // bottom: 0;

    position: fixed;
    height: 100dvh;
    width: 100vw;
}


.box {
    padding: 10px 12px;
    box-sizing: border-box;
    color: #aeb3b4;

    .box-title {
        margin-bottom: 10px;

        h2 {
            font-size: 16px;
            line-height: 20px;
            height: 20px;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .box-title+.box-body {
        margin-top: 15px;
    }

    .box-body+.box-body {
        margin-top: 10px;
    }

    .box-navy {
        ul {
            li {
                a {
                    color: #fff;
                }
            }
        }
    }

    .box-body-tabs {
        color: #fff;
    }

}

.ui-btn {
    min-width: 30px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    line-height: 30px;
    border: none;
    background: inherit;
    color: #aeb3b4;
    cursor: pointer;
    user-select: none;
    transition-duration: .3s;
    outline: 2px #ffa50000 solid;
    border-radius: 2px;
    text-decoration: none;

    i {
        color: inherit;
        height: 100%;
        text-align: center;
        min-width: 17px;
    }

    span {
        color: inherit;
    }

    i+span {
        margin-left: 5px;
    }

    &:hover {
        opacity: .9;
        color: #fff;
    }

    &:focus {
        opacity: .9;
        color: #fff;
    }

    &:disabled:hover {
        opacity: 1;
        color: inherit;
        cursor: initial;
    }
}

@media print {
    .chain-editor {
    
        position: initial;
    
        height: auto;
        width: 100%;

        .navy {
            display: none;
        }
    }

}

</style>