<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { appBus } from '@/utils';
// components
import ChainConsole from './ChainConsole.vue';
import ChainOptionsEditor from './ChainOptionsEditor.vue';
import JobList from './JobList.vue';
// store
import { useNavyStore } from '@/store';
import { usePipelineStore } from "../store";
const navyStore = useNavyStore();
const store = usePipelineStore();


const changeWorkSpaceType = (type: 'options' | 'code' | 'split')=>{
    if (!navyStore.chainPage) { return }
    navyStore.chainPage.editorViewType = type;
}

const consoleOpenClickHandler = () => {
    if (!navyStore.chainPage) { return }
    navyStore.chainPage.consoleOpened = true;
}

const saveChainHandler = ()=>{
    if (!navyStore.chainPage) { return }
    if (!navyStore.chainPage.unsaved) { return }
    store.pipelineName = navyStore.chainPage.name;
    store.status = navyStore.chainPage.enabled;
    store.env = JSON.parse(JSON.stringify( navyStore.chainPage.options.env ));
    store.trigger = JSON.parse(JSON.stringify( navyStore.chainPage.options.trigger ));
    store.updatePipeline();
}

// navy
onMounted(()=>{
    const openOptionsHandler = ()=>changeWorkSpaceType('options');
    const openPayloadHandler = ()=>changeWorkSpaceType('code');
    const openUnionHandler = ()=>changeWorkSpaceType('split');
    const toggleConsoleHandler = ()=>{
        if (!navyStore.chainPage) { return }
        if (navyStore.chainPage.consoleOpened) {
            navyStore.chainPage.consoleMaximized = false;
            navyStore.chainPage.consoleOpened = false;
        }
        else {
            navyStore.chainPage.consoleOpened = true;
        }
    };

    appBus.subscribe(appBus.eventList.optionsToggle, openOptionsHandler);
    appBus.subscribe(appBus.eventList.payloadToggle, openPayloadHandler);
    appBus.subscribe(appBus.eventList.unionToggle, openUnionHandler);
    appBus.subscribe(appBus.eventList.consoleToggle, toggleConsoleHandler);
    appBus.subscribe(appBus.eventList.ctrlS, saveChainHandler);

    onUnmounted(()=>{
        appBus.unsubscribe(appBus.eventList.optionsToggle, openOptionsHandler);
        appBus.unsubscribe(appBus.eventList.payloadToggle, openPayloadHandler);
        appBus.unsubscribe(appBus.eventList.unionToggle, openUnionHandler);
        appBus.unsubscribe(appBus.eventList.consoleToggle, toggleConsoleHandler);
        appBus.unsubscribe(appBus.eventList.ctrlS, saveChainHandler);
    });
});

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveChainHandler();
}
</script>

<template>
    <div class="options-bar options-bar-chain-page" v-if="navyStore.chainPage">
        <div class="options-bar-box">
            
            <div class="box">
                <!-- chain editor navy -->
                <div class="box-title">
                    <div class="ctrl-btns">
                        <button @click="changeWorkSpaceType('options')" title="Chain`s options" class="ui-btn">
                            <i class="icon-cog"></i>
                            <span>Options</span>
                        </button>

                        <button @click="changeWorkSpaceType('code')" title="Workers list" class="ui-btn">
                            <i class="icon-flow-cascade"></i>
                            <span>Workers</span>
                        </button>

                        <button v-if="!navyStore.chainPage.consoleOpened" @click="consoleOpenClickHandler" title="Test console" class="ui-btn">
                            <i class="icon-terminal"></i>
                            <span>Console</span>
                        </button>

                        <button @click="changeWorkSpaceType('split')" title="Split Editor right" class="ui-btn">
                            <i class="icon-columns"></i>
                        </button>
                    </div>
                </div>

                <!-- editor -->
                <div class="box-body pipeline-work-space" :class="`--${navyStore.chainPage.editorViewType || 'options'}`">
                    <div class="options">
                        <ChainOptionsEditor />
                    </div>
                    <div class="code">
                        <JobList />
                    </div>
                </div>

                <!-- save btn -->
                <div id="save-btn">
                    <button class="ui-btn" title="Save chain" :disabled="!navyStore.chainPage.unsaved" @click="saveBtnClickHandler">
                        <i class="icon-ok"></i>
                            <span>Save</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
    <ChainConsole />
</template>

<style lang="scss">
.options-bar-chain-page {
    @media print {
        & + .console-bar {
            display: none;
        }

        #save-btn {
            display: none;
        }
    }
}
</style>