import { postWithoutAuth, get } from '@/service/bffService';
export type { TBffResponseError } from '@/service/bffService';


type TUserLoginReqDto = {
	identifier: string;
	password: string;
}

type TUserLoginResDto = {
	jwt: string;

	user: {
		id: number;
		email: string;
		phone: string;
	}
}


export const loginUserByPassword = async (identifier: string, password: string): Promise<TUserLoginResDto> => {
	return await postWithoutAuth<TUserLoginReqDto, TUserLoginResDto>('/api/login', { identifier, password })
}

type TUserMeResDto = {
	id: number;
	email: string;
	phone: string;
	confirmed: boolean;
}

export const getMe = async () => {
	const user = await get<TUserMeResDto>('/api/users/me')
	return {
		id: user.id,
		email: user.email,
		phone: user.phone,
		confirmed: user.confirmed,

		// mock
		permissions: [],
		first_name: "",
		last_name: "",
		telegram: "",
		instagram: "",
		watsapp: "",
		avatar: ""
	}
}