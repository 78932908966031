<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({ name: "label-description" });
</script>

<template>
    <span class="label-desc">
        <slot />
    </span>
</template>

<style lang="scss">
    .label-desc {
        opacity: .7;
    }
</style>