<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import { isValidDate } from '@/utils'; 


const props = defineProps<ITableCellProps>();

const date = new Date(props.value);

const getTime = () => {
    if (!isValidDate(date)) { return '' }
    return date.toLocaleTimeString()
}

const getDate = () => {
    if (!isValidDate(date)) { return '' }
    return date.toLocaleDateString()
}
</script>

<template>
    <td :class="props.class || ''">
        <span>{{ getDate() }}</span>
        <span class="time">{{ getTime() }}</span>
    </td>
</template>

<style lang="scss">
.table td.logs-chain-date {
    font-size: 12.5px;
    padding: 5px 12px;
    width: 90px;
    box-sizing: border-box;
    vertical-align: middle;

    span {
        display: block;
        line-height: 14px;
        opacity: .7;

        &.time {
            opacity: 1;
        }
    }
}
</style>