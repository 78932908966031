<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import Row from './Row.vue';
// types
import { ITableTbody, ITableColumnOpts } from './types';


const props = defineProps<ITableTbody<any>>();
defineComponent({ name: 'table-tbody' });

const store = props.store();

const uniqKyeHelper = (index: number) => {
    return Date.now() + Math.random().toString(36) + index;
}
</script>

<template>
    <tbody :class="store?.body?.class || ''">
        <Row
            v-for="(rowData, k) of store.viewData"
            :key="uniqKyeHelper(k)"

            :rowData="rowData"
            :rowIndex="k"

            :columns="store.columns as ITableColumnOpts[]"
            :component="store?.row?.component || undefined"

            :class="[
                store?.row?.class || '',
                !((k as number) % 2) && store?.row?.oddClass || ''
            ].join(' ')"
            
            :devMode="props.devMode"
        />
    </tbody>
</template>