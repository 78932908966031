const appBusEvent = '__appBus';

type THandler<T> = (payload?: T)=>void;

class AppBus {
    private element = document.createElement('div');
    private handlersList = new WeakMap();
    eventList = {
        routerAfterEach: 'router-after-each',
        routerBeforeResolve: 'router-before-resolve',
        ctrlLeft: 'ctrl-left',
        ctrlRight: 'ctrl-right',
        ctrlFirst: 'ctrl-first',
        ctrlLast: 'ctrl-last',
        ctrlS: 'ctrl-s',
        consoleToggle: 'console-toggle',
        unionToggle: 'union-toggle',
        optionsToggle: 'options-toggle',
        payloadToggle: 'payload-toggle',
    }

    constructor() {
        this.element.hidden = true;
        document.body.appendChild(this.element);
    }

    subscribe<T>(name: string, handler: THandler<T>) {
        const thisHandler = (e: any) => {
            if (e.detail.action === name ) {
                handler(e.detail.payload as T)
            }
        };

        this.element.addEventListener(appBusEvent, thisHandler);

        this.handlersList.set(handler, thisHandler);
    }

    unsubscribe<T>(name: string, handler: THandler<T>) {
        const thisHandler = this.handlersList.get(handler);
        if (thisHandler) {
            this.element.removeEventListener(appBusEvent, thisHandler);
            this.handlersList.delete(handler);
            // console.log(this.handlersList);
            // console.log(handler);
            // console.log(this.handlersList)
        }
    }

    dispatch(action: string, payload: any) {
        const customEvent = new CustomEvent( appBusEvent, { detail: { action, payload } });
        this.element.dispatchEvent(customEvent);
    }
}

export const appBus = new AppBus();
(window as any).appBus = appBus;