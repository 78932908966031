<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationButton } from '@/components/Table/types';

defineComponent({name: 'kit-table-pagination-btn'});

const props = defineProps<ITablePaginationButton>();

</script>

<template>
    <li class="paginate_button page-item" :class="props.isActive ? 'active' : ''">
        <a class="page-link" href="#">{{ props.index }}</a>
    </li>
</template>

<style lang="scss">
li.paginate_button.page-item {
    & a {
        user-select: none;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        outline: none;
    }
    &.active a {
        cursor: initial;
    }

    &.disabled a {
        opacity: .3;
    }
}
</style>