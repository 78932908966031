<script setup lang="ts">
import { ref } from 'vue';
import { routeNames } from '@/router';
// components
import ChainEditorWidget from '@/modules/pipeline/components/LeftNavyWidget.vue';
import NewPageLogo from './NewPageLogo.vue';
// app bus
import { appBus } from '@/utils';
// store
import { useNavyStore } from '@/store';
const navyStore = useNavyStore();


// methods
const chainNavyClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    navyStore.toggleLeftNavy();
}

// full screen
const fullScreenToogle = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    var element = document.documentElement as any; // открыть на весь экран весь документ

    var doc = document as any;
    
    if (doc.fullscreen) {
        if (doc.exitFullscreen) {
            // Выходим из полноэкранного режима
            doc.exitFullscreen();
        } else if (doc.mozCancelFullScreen) { /* Firefox */
            doc.mozCancelFullScreen();
        } else if (doc.webkitExitFullscreen) { /* Chrome, Safari и Opera */
            doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) { /* IE/Edge */
            doc.msExitFullscreen();
        }
    }
    else {

        // Проверяем, поддерживает ли браузер API Fullscreen
        if (element.requestFullscreen) {
            // Вызываем метод requestFullscreen() для открытия на весь экран
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari и Opera */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
            element.msRequestFullscreen();
        }
    }
}

const isDocumentFullScreen = ()=>!!(document as any).fullscreen;
const isFullScreen = ref(isDocumentFullScreen());
window.addEventListener('resize', () => {
    var isFull = isDocumentFullScreen();
    if (isFullScreen.value !== isFull) {
        isFullScreen.value = isFull
    }
});


// toogle navy
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
document.addEventListener('keydown', function(event) {
    // На Mac проверяем, удерживается ли клавиша Command
    const isCtrlPressed = event.ctrlKey || (isMac && event.metaKey);
    const isShiftPressed = event.shiftKey;

    
    if (event.key === "b" && isCtrlPressed) {
        event.preventDefault();
        event.stopPropagation();
        navyStore.toggleLeftNavy();
    }

    if (event.key === "s" && isCtrlPressed) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.ctrlS, {});
    }

    if (event.key === "i" && isCtrlPressed) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.payloadToggle, {});
    }

    if (event.key === "o" && isCtrlPressed) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.optionsToggle, {});
    }

    if (event.key === "u" && isCtrlPressed) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.unionToggle, {});
    }

    if (event.key === "`" && isCtrlPressed && isShiftPressed) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.consoleToggle, {});
    }

    if (isCtrlPressed && (event.key === "," || event.key === "<")) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.ctrlLeft, null)
    }

    if (isCtrlPressed && (event.key === "." || event.key === ">")) {
        event.preventDefault();
        event.stopPropagation();
        appBus.dispatch(appBus.eventList.ctrlRight, null)
    }
});
</script>

<template>
    <div class="navy">
        <div class="navy-box">
            <nav>
                <div id="logo">
                    <a href="/" title="Wor4 service">
                        <NewPageLogo />
                    </a>
                </div>

                <div @click="chainNavyClickHandler" class="navy-toogle-btn">
                    <button>
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </div>


                <ul>
                    <li>
                        <router-link :to="{ name: routeNames.home }" active-class="--nav-anchor-active" title="dashboard">
                            <i class="ico icon-search"></i>
                            <span>Dashboard</span>
                        </router-link>
                    </li>
                    <li>
                        <a title="fullscreen" href="#" @click="fullScreenToogle">
                            <i class="ico" :class="{
                                'icon-resize-full': !isFullScreen,
                                'icon-resize-small': isFullScreen,
                            }"></i>
                            <span>Fullscreen<strong v-show="false">off</strong></span>
                        </a>
                    </li>

                    <li>
                        <router-link :to="{ name: routeNames.account }" active-class="--nav-anchor-active" title="account">
                            <i class="ico icon-user"></i>
                            <span>Account</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: routeNames.logs }" active-class="--nav-anchor-active" title="logs">
                            <i class="ico icon-list-bullet"></i>
                            <span>Logs</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: routeNames.chains }" active-class="--nav-anchor-active" title="chains list">
                            <i class="ico icon-link-1"></i>
                            <span>Chains</span>
                        </router-link>

                        <ChainEditorWidget />
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>


<style lang="scss">
.navy {

    width: 100%;
    max-width: 60px;
    min-width: 60px;
    position: relative;
    transition-duration: .3s;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% - 5px);
        height: 45px;
        background: #363e40;
        z-index: 5;
        transition-duration: .3s;
    }

    #logo {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        overflow: hidden;
        height: 0;
        padding: 0;
        transition-duration: .3s;
        z-index: 10;

        img {
            max-width: 100%;
            max-height: 50px;
            width: auto;
            opacity: 0;
            transition-duration: .1s;
        }
    }

    .navy-toogle-btn {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 0;
        right: 5px;
        z-index: 10;
        box-sizing: border-box;

        button {
            width: 45px;
            height: 45px;
            margin: 0;
            padding: 0;
            border: none;
            position: relative;
            outline: none;
            cursor: pointer;
            margin: 0;
            background-color: unset;

            div {
                width: 30px;
                height: 3px;
                background: #aeb3b4;
                position: absolute;
                transition-duration: .3s;
                left: 4px;
            }

            & div:first-child {
                top: 12px;
                transform: rotate(0deg);
            }

            & div:nth-child(2) {
                opacity: 1;
                top: 20px;
                transition-delay: 0s;
                transition-duration: .1s;
            }

            & div:last-child {
                top: 28px;
                transform: rotate(0deg);
            }
        }
    }

    #chain-navy-anchor {
        margin-left: -10px;

        & a>i {
            color: #373e40;
        }

        &.--chain-navy-enabled a>i {
            color: #0bbb08;
        }
    }

    .navy-box {
        background-color: rgba(0, 0, 0, .2);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        margin-right: 5px;
        height: 100%;
        overflow-y: auto;
        padding-top: 70px;
        box-sizing: border-box;

        nav {
            ul {
                li {
                    user-select: none;
                    a.--nav-anchor-active {
                        color: #fff !important;
                        background-color: rgba(0, 0, 0, 0.3);

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.3);
                        }

                        &:active {
                            background-color: rgba(0, 0, 0, 0.3);
                        }
                    }

                    a {
                        display: flex;
                        text-decoration: none;
                        color: #aeb3b4;
                        font-weight: 600;
                        letter-spacing: 0.5px;
                        position: relative;
                        transition: 0.3s;
                        line-height: 42px;

                        span {
                            margin-left: 10px;
                            font-size: 15px;
                            font-weight: 600;
                            overflow: hidden;
                            white-space: nowrap;
                            display: block;
                            text-overflow: ellipsis;
                            opacity: 0;
                            transition: opacity 0.3s;
                            position: relative;
                            letter-spacing: .35px;
                            display: none;
                        }

                        i,
                        i.ico {
                            margin: 0 -3px 0 10px;
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.15);
                        }

                        &:focus {
                            outline: none;
                            background-color: rgba(0, 0, 0, 0.15);
                        }
                    }
                }
            }

            ul.chain-navy {

                &>li a {
                    color: #7b7b7b;
                    padding-left: 20px;
                    transition: .3s;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.15);
                    }

                    &:focus {
                        outline: none;
                        background-color: rgba(0, 0, 0, 0.15);
                    }


                    span {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            width: 100%;
                            top: calc(50% + 1px);
                            height: 0;
                            border-bottom: 1px solid #363e40;
                            transition: .3s;
                        }
                    }
                }

                &>li.--chain-navy-enabled {
                    &::before {
                        background: orange;
                    }

                    a {
                        color: #aeb3b4;

                        span {
                            &::before {
                                width: 0%;

                            }
                        }
                    }
                }

                &>li.--chain-navy-unsaved {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background: orange;
                        border-radius: 100%;
                        top: calc(50% - 3px);
                        left: 20px;
                        z-index: 1;
                    }
                }

                .chain-navy-worker-ico {
                    width: 17px;
                    min-width: 17px;
                    height: 42px;
                    position: relative;

                    svg {
                        position: absolute;
                        top: 12.5px;
                        border-radius: 1px;
                    }
                }


            }
        }
    }

    @media screen and (max-width: 1023px) {



        & {
            position: absolute;
            width: 100%;
            left: -100%;
            z-index: 10;
            margin: 0;
            overflow: hidden;
            height: 100vh;
            background: #1f1f1f;
            width: 100%;
            max-width: none;
        }

        .navy-box {
            margin: 0;
            background-color: initial;
        }
    }
}


.--navy-opened {

    .navy {
        // width: 250px;
        // width: 100%;
        max-width: 240px;
        min-width: 240px;
        // padding-top: 0;

        &::before {
            height: 66px;
        }

        nav {
            ul {
                li {
                    a {
                        span {
                            opacity: 1;
                            display: initial;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1023px) {
            & {
                left: 0;
                max-width: none;

                &::before {
                    width: 100%;
                }
            }
        }
    }

    #logo {
        height: 70px;
        padding: 10px;

        img {
            width: 100%;
            opacity: 1;
            transition-duration: .1s;
            // transition-delay: .15s;
        }
    }

    .navy-toogle-btn {
        width: 40px;
        box-sizing: initial;
        margin-left: 0px;

        button {
            width: 50px;

            & div:first-child {
                top: 17px;
                transform: rotate(45deg);
            }

            & div:nth-child(2) {
                opacity: 0;
                transition-delay: 0s;
                transition-duration: .1s;
            }

            & div:last-child {
                top: 17px;
                transform: rotate(-45deg);
            }
        }
    }
}
</style>