<script setup lang="ts"></script>

<template>
    <div class="logo-svg-box --logo-animated">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:se="http://svg-edit.googlecode.com"
            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" viewBox="0 0 185 210">
            <g class="currentLayer" style="">
                <path class="logo-path-1" />
                <path class="logo-path-2" />
                <path class="logo-path-3" />
                <path class="logo-path-4" />
                <path class="logo-path-5" />
                <path class="logo-path-6" />
                <path class="logo-path-7" />
                <path class="logo-path-8" />
                <path class="logo-path-9" />
            </g>
        </svg>
    </div>
</template>

<style>
.logo-svg-box {
    background: #373e40;
    /* padding: 50px; */

    svg {
        height: 46px;
    }

    .logo-path-1 {
        fill: #1e2223;
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero;
        opacity: 1; */
        /* d: path("M 445 110 L 590 320 L 605 320 L 460 110 L 445 110 Z"); */

        d: path('M 0 0 L 145 210 L 160 210 L 15 0 L 0 0 Z');
    }

    .logo-path-2 {
        fill: #1e2223;
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero;
        opacity: 1; */
        d: path('M 25 0 L 170 210 L 185 210 L 40 0 L 25 0 Z');
    }

    .logo-path-3 {
        fill: #1e2223;
        /* color: rgb(0, 0, 0); */
        /* stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero;
        opacity: 1; */
        d: path('M 185 0 L 30 210 L 0 210 L 155 0 L 185 0 Z');
    }


    .logo-path-4 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 0 0 L 89 129 L 97 119 L 15 0 L 0 0 Z');
    }

    .logo-path-5 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 25 0 L 102 112 L 110 101 L 40 0 L 25 0 Z')
    }

    .logo-path-6 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 185 0 L 89 130 L 75 108 L 155 0 L 185 0 Z');
    }

    .logo-path-7 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 102 112 L 170 210 L 185 210 L 110 101 L 102 112 Z')
    }

    .logo-path-8 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 89 129 L 97 119 L 160 210 L 145 210 L 89 129 Z');
    }

    .logo-path-9 {
        fill: #1e2223;
        /* opacity: .5; */
        /* color: rgb(0, 0, 0);
        stroke: rgb(0 0 0);
        stroke-opacity: 1;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linejoin: round;
        stroke-linecap: butt;
        fill-rule: nonzero; */
        /* opacity: 1; */
        d: path('M 30 210 L 89.5 129.5 L 75 108 L 0 210 Z');
    }

    &.--logo-animated {
        .logo-path-1 {}

        .logo-path-2 {}

        .logo-path-3 {}

        .logo-path-4 {
            animation: logo-scene-4 9s linear forwards;
        }

        .logo-path-5 {
            animation: logo-scene-5 9s linear forwards;
        }

        .logo-path-6 {
            animation: logo-scene-6 9s linear forwards;
        }

        .logo-path-7 {
            animation: logo-scene-7 9s linear forwards;
        }

        .logo-path-8 {
            animation: logo-scene-8 9s linear forwards;
        }

        .logo-path-9 {
            animation: logo-scene-9 9s linear forwards;
        }
    }
}

@keyframes logo-scene-4 {
    0% {
        fill: #373e40;
    }

    5% {
        fill: #aeb3b4;
    }

    10% {
        fill: #1e2223;
    }

    15% {
        fill: #aeb3b4;
    }

    20% {
        fill: #1e2223;
    }

    30% {
        fill: #1e2223;
    }

    35% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #d86212;
        fill: linear-gradient(180deg, rgba(233, 166, 65, 1) 0%, rgba(255, 144, 72, 1) 32%, rgba(255, 103, 0, 1) 95%);
    }
}

@keyframes logo-scene-5 {
    0% {
        fill: #373e40;
    }

    5% {
        fill: #aeb3b4;
    }

    10% {
        fill: #1e2223;
    }

    15% {
        fill: #aeb3b4;
    }

    20% {
        fill: #1e2223;
    }

    35% {
        fill: #1e2223;
    }

    40% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #d86212;
        fill: linear-gradient(180deg, rgba(233, 166, 65, 1) 0%, rgba(255, 144, 72, 1) 32%, rgba(255, 103, 0, 1) 95%);
    }
}

@keyframes logo-scene-6 {
    0% {
        fill: #373e40;
    }

    5% {
        fill: #aeb3b4;
    }

    10% {
        fill: #1e2223;
    }

    15% {
        fill: #aeb3b4;
    }

    20% {
        fill: #1e2223;
    }

    25% {
        fill: #aeb3b4;
    }

    30% {
        fill: #1e2223;
    }

    40% {
        fill: #1e2223;
    }

    45% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #1e2223;
    }
}

@keyframes logo-scene-7 {
    0% {
        fill: #373e40;
    }

    20% {
        fill: #373e40;
    }

    25% {
        fill: #aeb3b4;
    }

    30% {
        fill: #1e2223;
    }

    45% {
        fill: #1e2223;
    }

    50% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #d86212;
        fill: linear-gradient(180deg, rgba(233, 166, 65, 1) 0%, rgba(255, 144, 72, 1) 32%, rgba(255, 103, 0, 1) 95%);
    }
}

@keyframes logo-scene-8 {
    0% {
        fill: #373e40;
    }

    20% {
        fill: #373e40;
    }

    25% {
        fill: #aeb3b4;
    }

    30% {
        fill: #1e2223;
    }

    50% {
        fill: #1e2223;
    }

    55% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #d86212;
        fill: linear-gradient(180deg, rgba(233, 166, 65, 1) 0%, rgba(255, 144, 72, 1) 32%, rgba(255, 103, 0, 1) 95%);
    }
}

@keyframes logo-scene-9 {
    0% {
        fill: #373e40;
    }

    10% {
        fill: #373e40;
    }

    15% {
        fill: #aeb3b4;
    }

    20% {
        fill: #1e2223;
    }

    55% {
        fill: #1e2223;
    }

    60% {
        fill: #aeb3b4;
    }

    65% {
        fill: #aeb3b4;
    }

    /* 70% {
        fill: #1e2223;
    } */

    100% {
        fill: #1e2223;
    }
}
</style>