<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref, watch } from 'vue';
import { IRadio } from './types';
import { getValueFromEvent } from './helpers';

// props
const props = defineProps<IRadio>();
const inputValue = ref(props.modelValue || '');

defineComponent({ name: 'radio-box' });

// emits
const emits = defineEmits(['update:modelValue']);

// methods
const inputHandler = (e: Event) => {
    const value = getValueFromEvent(e);
    emits('update:modelValue', value);
}

watch(()=>props.modelValue, ()=>{
    inputValue.value = props.modelValue || ''
})
</script>

<template>
    <div class="form-radio" :class="{ '--form-radio-disabled': disabled }">
        <label class="form-radio-label">
            <input
                class="form-radio-input"
                type="radio"
                :required="Boolean(required)" 
                :disabled="Boolean(disabled)"
                :name="name"
                
                :value="value"
                v-model="inputValue" 
                @input="inputHandler"
            />
            <slot />
            <i class="input-helper"></i>
        </label>
    </div>
</template>