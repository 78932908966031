<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import { Table } from '@/components/Table';
// types
import { TTableStore } from '@/components/Table/tableStore';


const props = defineProps<{ useStore: TTableStore<any> }>();
const store = props.useStore();

// add sort classes for kit styles
store.columns.forEach(column => {
    if (!column.sortable) { return }

    const ordered = (column?.headOrderedClass || '').split(' ').filter(i => i && i != '--sorting');
    ordered.push('--sorting');
    const orderedAsc = (column?.headOrderedClassAsc || '').split(' ').filter(i => i && i != '--sorting_asc');
    orderedAsc.push('--sorting_asc');
    const orderedDesc = (column?.headOrderedClassDesc || '').split(' ').filter(i => i && i != '--sorting_desc');
    orderedDesc.push('--sorting_desc');

    column.headOrderedClass = ordered.join(' ')
    column.headOrderedClassAsc = orderedAsc.join(' ')
    column.headOrderedClassDesc = orderedDesc.join(' ')
})

defineComponent({ name: 'kit-table' });
</script>

<template>
    <Table :store="props.useStore" class="table">
        <slot />
    </Table>
</template>

<style lang="scss">
.table {

    min-width: 100%;
    background: #1e2324;
    line-height: 20px;

    thead {
        background: #181c1d;
    }


    & th {
        font-weight: 600;
        text-align: left;
        color: #fff;
        position: relative;
        padding: 17px 12px;
        position: relative;
        letter-spacing: .35px;
    }

    & tr {
        border-bottom: 2px solid #2c3233;

        &.border-color-unset {
            user-select: none;
            border-bottom: 2px solid #2c323300;
        }
    }

    & td {
        padding: 12.5px 12px;
    }

    .--sorting {
        padding-right: 30px;
        cursor: pointer;
        position: relative;
        user-select: none;

        &::before {
            font-family: "fontello";
            content: "\f176";
            position: absolute;
            top: 18px;
            right: 14px;
            opacity: 0.3;
            font-style: normal;
            font-weight: normal;
            speak: never;
            display: inline-block;
            text-decoration: inherit;
            width: 10px;
            transition: opacity .25s;
        }

        &::after {
            font-family: "fontello";
            content: "\f175";
            position: absolute;
            top: 19px;
            right: 5px;
            opacity: 0.3;
            font-style: normal;
            font-weight: normal;
            speak: never;
            display: inline-block;
            text-decoration: inherit;
            width: 10px;
        }
    }

    .--sorting_asc {
        &::before {
            opacity: 1;
        }
    }

    .--sorting_desc {
        &::after {
            opacity: 1;
        }
    }
}


.table-loading {
    position: relative;

    &::after {
        content: "loading...";
        color: #fff;
        margin-top: 20px;
        opacity: 0.8;
        left: 20px;
        top: 0;
        display: block;
        position: sticky;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: table-loading-animated-bg 2s linear infinite;
    }

    &+tbody {
        display: none;
    }
}

@keyframes table-loading-animated-bg {
    0% {
        background-color: #1e2324;
    }


    50% {
        background-color: #22282a;
    }

    100% {
        background-color: #1e2324;
    }
}
</style>