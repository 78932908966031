<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import { Label, Input, LabelDescription } from '@/components/kit';
import Loader from '@/components/Loader.vue';
// store
import { useNavyStore } from '@/store';
import { usePipelineStore } from "../store";
const navyStore = useNavyStore();
const store = usePipelineStore();


// get active tab worker
const getActiveWorker = () => navyStore.chainPage?.workersList.find(i => i.active);
const getStoreActiveWorker = () => {
    if (!worker.value) { return void 0 }
    const id = worker.value.id;
    return store.jobs?.find(i => i.id === id);
}
const worker = ref(getActiveWorker());
const storeWorker = ref(getStoreActiveWorker());
watch(getActiveWorker, (aw) => {
    worker.value = aw;
    storeWorker.value = getStoreActiveWorker();
});

const testData = ref<string>('');
const isDev = ~window.location.search.indexOf('kerd=1');

// handlers
const consoleMaxClickHandler = ()=>{
    if (!worker.value) { return }
    worker.value.consoleMaximized = !worker.value.consoleMaximized;
}

const consoleCloseClickHandler = ()=>{
    if (!worker.value) { return }
    worker.value.consoleMaximized = false;
    worker.value.consoleOpened = false;
}

const runBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    if (!worker.value || !storeWorker.value) { return}

    store.runJobTest(
        worker.value.id, // jobId
        testData.value, // testData
        worker.value.options.env, // jobEnv
        storeWorker.value.type, // jobType
        worker.value.options.code, // jobCode
    );
}

const returnedData = ref<string>('');
const logsData = ref<string>('');
const devInfo = ref<string>('');
watch(()=>[storeWorker.value?.testResult?.result], ([data])=>{
    if (!storeWorker.value?.testResult) { return }
    returnedData.value = jsonBeautifierHelper(data);
    logsData.value = storeWorker.value.testResult.console_log.join("\n");
    isDev && (devInfo.value = jsonBeautifierHelper( JSON.stringify(storeWorker.value.testResult) ) )
})

// helpers
const jsonBeautifierHelper = (data: any): string => {
    const result = data;

    try {
        return JSON.stringify( JSON.parse(result), null, "\t" )
    }
    catch(e) {
        return result;
    }
}
</script>


<template>
    <!-- console bar -->
    <div class="console-bar" v-if="worker && storeWorker">
        <div class="console-bar-box box">
            <div class="box-title">
                <h2>Worker testing console</h2>

                <div class="ctrl-btns">
                    <button 
                        @click="consoleMaxClickHandler"
                        :title="worker.consoleMaximized ? 'restore panel size' : 'maximize panel size'" 
                        class="ui-btn"
                    >
                        <i :class="{
                            'icon-resize-small': worker.consoleMaximized,
                            'icon-resize-full': !worker.consoleMaximized
                        }"></i>
                    </button>
                    <button @click="consoleCloseClickHandler" title="close panel" class="ui-btn">
                        <i class="icon-cancel"></i>
                    </button>
                </div>
            </div>

            <div class="box-body qa-console-body">

                <div class="qa-worker-console">

                    <Loader v-if="storeWorker.$testing" />
                    <template v-else>
                        <div class="form-group">
                            <Label>
                                Test`s input data
                                <LabelDescription>JSON or String</LabelDescription>
                            </Label>

                            <Input v-model="testData" type="textarea" />
                        </div>

                        <button 
                            @click="runBtnClickHandler"
                            class="ui-btn run-test-btn"
                        >
                            <i class="icon-right-big"></i>
                            <span>Run</span>
                        </button>

                        <div class="qa-result" v-if="storeWorker.testResult">
                            <p v-if="storeWorker.testResult?.status" class="--log-viewer-success">
                                <i class="icon-ok-circled"></i>
                                <span>success</span>
                            </p>
                            <p v-else class="--log-viewer-error">
                                <i class="icon-cancel-circled"></i>
                                <span>error</span>
                            </p>

                            <div class="form-group">
                                <Label v-if="storeWorker.testResult?.error_message">
                                    Error:
                                    <LabelDescription>{{ storeWorker.testResult?.error_message }}</LabelDescription>
                                </Label>
                            </div>

                            <div class="form-group">
                                <Label>
                                    Duration:
                                    <LabelDescription>{{ storeWorker.testResult?.duration }}</LabelDescription>
                                </Label>
                            </div>

                            <div class="form-group">
                                <Label>
                                    Result Data
                                </Label>
                                <Input v-model="returnedData" type="textarea" disabled />
                            </div>

                            <div class="form-group">
                                <Label>
                                    Logs
                                </Label>
                                <Input v-model="logsData" type="textarea" disabled />
                            </div>

                            <div class="form-group dev-info" v-if="isDev">
                                <Label>
                                    Dev / QA info
                                </Label>
                                <Input v-model="devInfo" type="textarea" disabled />
                            </div>
                        </div>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">

.qa-worker-console {
    @media print {
        display: none;
    }

    position: relative;
    padding: 10px 0 30px;

    .run-test-btn {

    }

    .qa-worker-result {
        margin: 10px 0;
        padding-left: 10px;
        text-decoration: none;
        display: block;

        .--success {
            color: #0bbb08;
        }

        .--error {
            color: #d10607;
        }

        .name {
            margin: 0 8px;
        }

        .duration {
            opacity: .6;
        }

        .error {
            padding-left: 20px;
        }

        .form-group {
            margin: 30px 0 0;
        }

    }

    .form-group {

        textarea {
            resize: vertical;
            min-height: 50px;
            line-height: 21px;
        }
    }

    .qa-result {
        margin: 20px 0;

        .form-group {
            margin: 30px 0 0;
        }

        p {
            // margin: 25px 0;
            line-height: 1.35em;

            & > a {
                text-decoration: none;
            }

            b {
                font-weight: 600;
                color: #fff;
            }
        }

        p.--log-viewer-success {
            color: #0bbb08;
            margin-bottom: 20px;

            span {
                letter-spacing: .5px;
                padding-left: 7px;
            }
        }

        p.--log-viewer-error {
            color: #d10607;
            margin-bottom: 20px;

            span {
                letter-spacing: .5px;
                padding-left: 7px;
            }
        }

        .dev-info textarea {
            height: 300px;
        }
    }
}
</style>