import Ace from 'ace-builds';
// js worker
Ace.config.setModuleUrl("ace/mode/javascript_worker", '/js/worker-javascript.js');
Ace.config.setModuleUrl("ace/mode/html_worker", '/js/worker-html.js');
Ace.config.setModuleUrl("ace/mode/css_worker", '/js/worker-css.js');
Ace.config.setModuleUrl("ace/mode/json_worker", '/js/worker-json.js');
Ace.config.setModuleUrl("ace/mode/xml_worker", '/js/worker-xml.js');

// search
const searchExtUrl = '/js/ext-searchbox.js';
Ace.config.setModuleUrl("ace/ext/searchbox", searchExtUrl);

// theme
Ace.config.setModuleLoader('ace/theme/monokai', async () => {
    return await import(`ace-builds/src-noconflict/theme-monokai`).then(module => module);
});

// js editor mode
Ace.config.setModuleLoader('ace/mode/javascript', async () => {
    return await import(`ace-builds/src-noconflict/mode-javascript`).then(module => module);
});

// text editor
Ace.config.setModuleLoader('ace/mode/text', async () => {
    return await import(`ace-builds/src-noconflict/mode-text`).then(module => module);
});

// text editor HTML mode
Ace.config.setModuleLoader('ace/mode/html', async () => {
    return await import(`ace-builds/src-noconflict/mode-html`).then(module => module);
});
// text editor CSS mode
Ace.config.setModuleLoader('ace/mode/css', async () => {
    return await import(`ace-builds/src-noconflict/mode-css`).then(module => module);
});
// text editor JSON mode
Ace.config.setModuleLoader('ace/mode/json', async () => {
    return await import(`ace-builds/src-noconflict/mode-json`).then(module => module);
});
// text editor XML mode
Ace.config.setModuleLoader('ace/mode/xml', async () => {
    return await import(`ace-builds/src-noconflict/mode-xml`).then(module => module);
});

export const ace = Ace;

export const getJsEditor = (onChange?: ()=>void): ICustomAceEditor => {
    const editorBox = document.createElement('div');

    const jsEditor = ace.edit(
        editorBox,
        {
            theme: 'ace/theme/monokai',
            mode: 'ace/mode/javascript',
        }
    ) as ICustomAceEditor;

    jsEditor.box = editorBox;
    jsEditor.session.setTabSize(4);
    jsEditor.setFontSize(15);
    jsEditor.setValue('');
    jsEditor.setReadOnly(false);

    if (onChange) {
        jsEditor.getSession().on("changeAnnotation" as "change", onChange);
    }

    return jsEditor;
}

export const isJSEditorCodeValid = (jsEditor: ReturnType<typeof ace.edit>): boolean => {
    const annotations = jsEditor.getSession().getAnnotations();
    return !annotations.find(
        (item) => item.type === "error" || item.type === "warning"
    );
};

export const getTextEditor = (onChange?: ()=>void): ICustomAceEditor => {
    const editorBox = document.createElement('div');

    const textEditor = ace.edit(
        editorBox,
        {
            theme: 'ace/theme/monokai',
            mode: 'ace/mode/text',
        }
    ) as ICustomAceEditor;

    textEditor.box = editorBox;
    textEditor.session.setTabSize(4);
    textEditor.setFontSize(15);
    textEditor.setValue('');
    textEditor.setReadOnly(false);

    if (onChange) {
        textEditor.getSession().on("change", onChange);
    }
    
    return textEditor;
}

export type ICustomAceEditor = ReturnType<typeof ace.edit> & {
    box: Element;
}