import { defineStore } from 'pinia';
import { 
    routesPermissions,
    TRoutes
} from '@/router';


type TWorkSpaceType = 'split' | 'options' | 'code';

export type TNavy = {
    loading: boolean;

    leftNavyOpen: boolean;
    mobileNavyOpen: boolean;
    userNavyList: TRoutes[];

    // chain opts
    consoleOpened: boolean;
    consoleMaximized: boolean;
    workSpaceType: TWorkSpaceType;

    chainPage?: {
        chainId: string;
        enabled: boolean;
        name: string;
        active?: boolean;
        unsaved?: boolean;
        consoleOpened?: boolean;
        consoleMaximized?: boolean;
        editorViewType?: string;
        viewActiveWorkers?: boolean;

        options: {
            env: Record<string, string>;
            trigger: {
                type: string;
                options: Record<string, string>;
            };
        };

        workersList: {
            id: string;
            name: string;
            type: string;
            enabled?: boolean;
            unsaved?: boolean;
            active?: boolean;
            opened?: boolean;
            consoleOpened?: boolean;
            consoleMaximized?: boolean;
            editorViewType?: string;

            editor?: {
                cursor?: {
                    row: number, 
                    column: number
                },
                textMode?: string;
            }

            options: {
                data: string;
                env: Record<string, string>;
                code: string;
                codeInvalid?: boolean;
            }
        }[];

    };

    openedTabs: Set<string>;
}

// store
export const useNavyStore = defineStore({
    id: 'navy',
    state: (): TNavy => ({
        loading: true,
        leftNavyOpen: document.body.offsetWidth > 1024,
        mobileNavyOpen: false,
        userNavyList: [],

        // chain opts
        consoleOpened: false,
        consoleMaximized: false,
        workSpaceType: 'options',

        openedTabs: new Set(),
    }),
    
    actions: {
        toggleLeftNavy() {
            this.leftNavyOpen=!this.leftNavyOpen;
        },

        toggleMobileNavy() {
            this.mobileNavyOpen=!this.mobileNavyOpen;
        },

        closeMobileNavy() {
            if (document.body.offsetWidth < 1024) {
                setTimeout(()=>{
                    this.leftNavyOpen = false;
                }, 300)
            }
        },

        setUserNavyList(permissions: number[] = []) {
            const list: TRoutes[] = [];
            // permissions.forEach(perm => {
                for (const key in routesPermissions) {
                    const tkey = key as TRoutes;
                    // if (~routesPermissions[tkey].indexOf(perm)) {
                        list.push(tkey);
                    // }
                }
            // });

            this.userNavyList = list;
        },

        consoleToogle() {
            this.consoleOpened = !this.consoleOpened;
        },

        consoleMaximizeToogle() {
            this.consoleMaximized = !this.consoleMaximized;
        },

        changeWorkSpaceType(t: TWorkSpaceType) {
            this.workSpaceType = t;
        },

        updateChainNavy(chainPage: TNavy['chainPage']) {
            if (!chainPage) { return }
            
            if (this.chainPage) {
                Object.assign(this.chainPage, chainPage)
            }
            else {
                this.chainPage = chainPage;
            }
        },

        setChainTabActive() {
            if (!this.chainPage) { return }
            this.chainPage.workersList.map(worker=>{
                worker.active = false;
            });
            this.chainPage.active = true;
        },

        openTab(workerID: string, active: boolean) {
            // search worker by id
            if (!this.chainPage) { return }

            const worker = this.chainPage.workersList.find(i=>i.id === workerID);
            if (!worker) { return }

            if (active) {
                this.chainPage.active = false;
                this.chainPage.workersList.map(worker=>{
                    worker.active = false;
                });
            }

            worker.opened = true;
            worker.active = active;

            this.openedTabs.add(workerID);
        },

        closeTab(workerID: string) {
            const worker = this.chainPage?.workersList.find(i => i.id === workerID);
            if (!worker) { return }

            this.openedTabs.delete(workerID);
            worker.opened = false;
            worker.active = false;
        },
    },

    getters: {
        openedWorkers(): string[] {
            return [...this.openedTabs.values()]
        },

        activeWorker(): {id: string, index: number} | null {
            if (!this.chainPage) { return null }
            if (this.chainPage.active) { return null }

            const chainNavyOpenedList = this.chainPage.workersList;
            const index = Object.values(chainNavyOpenedList).findIndex(i=>i.active);
            if (index < 0) { return null }

            const active = chainNavyOpenedList?.[index]?.id;
            if (!active) { return null }

            return {
                id: active,
                index,
            }
        }
    },
});