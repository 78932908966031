<script setup lang="ts">
import { watch, onMounted, onUnmounted, reactive, ref } from 'vue';
import { router, routeNames } from '@/router';
// components
// import LogsTable from './components/LogsTable.vue';
import { Input, Label, Radio } from '@/components/kit';
import PipelineTableButtons from './PipelineTableButtons.vue';
import PipelineTCellStatus from './PipelineTCellStatus.vue';
import PipelineTCellItemLink from './PipelineTCellItemLink.vue';
import KitTable from '@/components/kit/Table/Table.vue';
import KitPagination from '@/components/kit/Table/TablePagination.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
// utils
import {
    getQueryParams,
    getInteger,
    // dateIsoToHumanDateTime,
} from '@/utils';
// app bus
import { appBus } from '@/utils';
// table store
import { useTablePipelineStore } from '../store';
const store = useTablePipelineStore();


interface ITableParams {
    chainId: string;
    status: string;
    page: number;
    perPage: number;
}

// history
const getTableQueryParams = () => {
    const queryParams = getQueryParams();

    const historyStatus = queryParams.get('status');
    const status = historyStatus ? (~['all', 'ok', 'error'].indexOf(historyStatus) ? historyStatus : 'all') : 'all';

    return {
        chainId: queryParams.get('chain_id') || '',
        status: status,
        page: getInteger(queryParams.get('page'), 1),
        perPage: getInteger(queryParams.get('ppage'), 10),
    };
}

const setTableQueryParams = () => {
    const params = { ...tableStatus };
    const searchParams = new URLSearchParams();
    searchParams.append('chain_id', params.chainId);
    searchParams.append('status', params.status);
    searchParams.append('page', params.page + '');
    searchParams.append('ppage', params.perPage + '');

    router.push({
        name: routeNames.chains,
        query: Object.fromEntries(searchParams.entries())
    })
}

const tableStatus = reactive<ITableParams>({ ...getTableQueryParams() });

const inputFilterValidator = (val: string) => {
    return /^[a-z0-9-]{0,36}$/i.test(val)
}

// table
store.columns = [
    {
        key: 'status',
        title: 'status',
        component: PipelineTCellStatus,
        filterable: true,
    },
    {
        key: 'id',
        title: '#',
        component: PipelineTableButtons,
        cellClass: 'chainslist-page-btn',
    },
    {
        key: 'name',
        title: 'name',
        component: PipelineTCellItemLink,
        cellClass: 'chainslist-page-name',
    },
    {
        key: 'id',
        title: 'chain ID',
        component: PipelineTCellItemLink,
        cellClass: 'chainslist-page-id',
    },
    {
        key: 'version',
        title: 'version',
        cellClass: 'chainslist-page-version',
    },
    {
        key: 'error_counter',
        title: 'errors',
        render: (data, value) => {
            return value ?? '0';
        },
        cellClass: 'chainslist-page-errors',
    },
];

const chainIndexColumn = 1;
const statusIndexColumn = 0;


const historyToState = () => {
    Object.assign(tableStatus, getTableQueryParams());
}

const updateTableData = (setupPage?: boolean) => {

    const chainId = tableStatus.chainId;
    const status = tableStatus.status;

    // для всех обновлений - сначала устанавливаем пагинацию
    if (!setupPage) {
        store.paginatePerPage = tableStatus.perPage;
        store.paginatePage = tableStatus.page;
    }

    store.columns.forEach((i, k) => {
        const columnFilter = store.filters?.[k];

        if (k === chainIndexColumn && columnFilter?.value?.[0] !== chainId) {
            store.filterBy(chainIndexColumn, [chainId]);
        }
        if (k === statusIndexColumn && columnFilter?.value?.[0] !== status) {
            store.filterBy(statusIndexColumn, [status])
        }
    });

    if (setupPage) {
        store.paginatePerPage = tableStatus.perPage;
        store.setPage(tableStatus.page);
        store.paginatePage = tableStatus.page;
    }

}

let updateFiltersTimeout: number;
const updateFiltersHandler = () => {
    updateFiltersTimeout && clearTimeout(updateFiltersTimeout);

    updateFiltersTimeout = setTimeout(() => {
        setTableQueryParams();
        updateTableData();
    }, 400)
}

const scrollToTable = ref<HTMLElement>();

const paginationEventName = 'chainslist-page-table-pagination';

onMounted(() => {

    // нужно сделать следующее
    // 1. при загрузке страницы устанавливаем tableStatus и делаем запрос в таблицу
    // 2. при popstate - устанавливаем tableStatus и делаем запрос в таблицу
    // 3. при изменении tableStatus - устанавливаем history и делаем запрос в таблицу
    // 4. при изменении пагинации таблиц устанавдиваем tableStatus и обновляем history, 
    // при этом нужно учесть, что пагинация изменяется при введении нового фильтра


    // 1
    historyToState();
    updateTableData(true);

    // 2
    const popstateHandler = (event: any) => {
        if (event.state === null) { return }
        historyToState();
        updateTableData(true);
    }

    window.addEventListener('popstate', popstateHandler);
    onUnmounted(() => {
        window.removeEventListener('popstate', popstateHandler);
    });


    // 3
    watch(() => [tableStatus.chainId, tableStatus.status], () => {
        tableStatus.page = 1;
        updateFiltersHandler();
    });

    // 4
    const tablePaginationHandler = (data: any) => {
        if (!data?.page) { return }
        tableStatus.page = data.page;
        setTableQueryParams();
        updateTableData(true);
    };

    appBus.subscribe(paginationEventName, tablePaginationHandler);

    onUnmounted(() => {
        appBus.unsubscribe(paginationEventName, tablePaginationHandler);
    });

    // scroll
    watch(() => [store.paginatePage, store.paginateFiltered, store.paginatePerPage, store.paginateViewed], () => {
        if (scrollToTable.value) {
            scrollToTable.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    });

    // control left / right
    const leftHandler = () => {
        const newPage = tableStatus.page - 1;
        if (newPage < 1) { return }
        tableStatus.page = newPage;
        updateFiltersHandler();
        updateTableData(true);
    };
    const rightHandler = () => {
        const newPage = tableStatus.page;
        if (store.paginateFiltered < newPage * tableStatus.perPage) { return }
        tableStatus.page = newPage + 1;
        updateFiltersHandler();
        updateTableData(true);
    };

    appBus.subscribe(appBus.eventList.ctrlLeft, leftHandler);
    appBus.subscribe(appBus.eventList.ctrlRight, rightHandler);

    onUnmounted(() => {
        appBus.unsubscribe(appBus.eventList.ctrlLeft, leftHandler);
        appBus.unsubscribe(appBus.eventList.ctrlRight, rightHandler);
    });
});

// empty
const emptyTitle = "Chains wasn't found";
if (store.body) {
    store.body.emptyTitle = emptyTitle;
}
else {
    store.body = { emptyTitle }
}

</script>

<template>
    <div class="chainslist-page-filters" v-if="false">
        <div class="filter">
            <div class="form-group">
                <Label for="chainslist-page-filter">Filter by <strong>chain ID</strong></Label>
                <Input id="chainslist-page-filter" v-model="tableStatus.chainId" :validator="inputFilterValidator" />
            </div>
        </div>

        <div class="filter">
            <div class="form-group">
                <Label for="chainslist-page-filter">Filter by status</Label>

                <div class="status-filter">
                    <Radio id="chainslist-page-filter" name="log-page-status[]" value="all" v-model="tableStatus.status">all</Radio>
                    <Radio id="chainslist-page-filter" name="log-page-status[]" value="ok" v-model="tableStatus.status">ok</Radio>
                    <Radio id="chainslist-page-filter" name="log-page-status[]" value="error" v-model="tableStatus.status">error</Radio>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="chainslist-page-filters"></div>

    <div ref="scrollToTable"></div>
    <MinWidthAndScroll :min-width="1250">
        <KitTable :useStore="useTablePipelineStore" class="chainslist-page-table">
            <tbody v-if="store.loading" class="table-loading"></tbody>
        </KitTable>
    </MinWidthAndScroll>
    <KitPagination :useStore="useTablePipelineStore" :paginationEventName="paginationEventName" />
</template>


<style lang="scss">
.chainslist-page-filters {
    display: flex;
    margin: 30px 0;

    .filter {
        .status-filter {
            display: flex;
            margin: 0 -10px;

            .form-radio {
                margin: 8px 10px 0;
            }
        }
    }

    .filter+.filter {
        margin: 0 20px;
    }

    .filter+.filter:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 680px) {
        flex-direction: column;

        .filter+.filter {
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
        }
    }
}

.chainslist-page-table {
    tbody {
        height: 494px;
    }

    .chainslist-page-name {
        max-width: 550px;
        padding: 0;
        
        a {
            display: block;
            padding: 12.5px 12px;
            text-decoration: none;
            height: 1.4em;
            word-wrap: break-word;
            word-spacing: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color .3s;

            &:hover {
                color: #fff;
            }

            &:focus {
                outline: none;
                color: #fff;
            }

        }
        @media screen and (max-width: 720px) {
            & {
                max-width: 320px;
            }
        }
        
    }

    .chainslist-page-id {
        padding: 0;
        
        a {
            display: block;
            padding: 12.5px 12px;
            text-decoration: none;
            max-width: 310px;
            height: 1.4em;
            word-wrap: break-word;
            word-spacing: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color .3s;

            &:hover {
                color: #fff;
            }

            &:focus {
                outline: none;
                color: #fff;
            }
        }
    }

    .chainslist-page-version {
        max-width: 100px;
    }

    .chainslist-page-errors {
        max-width: 100px;
    }

    .chainslist-page-btn {
        max-width: 50px;
        padding: 8px 12px;

        .chainslist-page-ctrl {
            display: flex;
            // width: 110px;
    
            a {
                text-decoration: none;
            }
    
            a + a {
                margin-left: 15px;
    
            }
        }
    }


}

</style>