<script setup lang="ts">
import { ref, watch } from "vue";
import { dateIsoToHumanDateTime, comparingRecordObjects } from '@/utils';
import { routeNames } from '@/router';
// components
import { QuestionViewerEditor } from "@/components/kit/QuestionViewer";
import TriggerEditor from "./TriggerEditor.vue";
import { Label, Checkbox } from '@/components/kit';
import EnvEditor from "./EnvEditor.vue";
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


const isDev = ~window.location.search.indexOf('kerd=1');
isDev;

watch(()=>[
    navyStore.chainPage?.name, 
    navyStore.chainPage?.enabled,
    navyStore.chainPage?.options.env,
    navyStore.chainPage?.options.trigger,
], ( [ name, enabled, env, trigger ] ) => {
    if (!navyStore.chainPage) { return }

    let isEdited = false;

    removeChainNameWatcher();
    chainName.value = navyStore.chainPage.name;
    removeChainNameWatcher = addChainNameWatcher();

    if (name !== store.pipelineName) { isEdited = true }
    if (enabled !== store.status) { isEdited = true }

    if (!comparingRecordObjects(env as Record<string, string>, store.env)) {
        isEdited = true;
    }


        
    const trig = trigger as any;

    if (trig?.type !== store.trigger.type) {
        isEdited = true;
    }
    else {

        if (trig.type === 'cron') {

            if (
                trig?.options?.['m'] !== store.trigger.options?.['m'] ||
                trig?.options?.['h'] !== store.trigger.options?.['h'] ||
                trig?.options?.['mn'] !== store.trigger.options?.['mn'] ||
                trig?.options?.['d'] !== store.trigger.options?.['d']
            ) {
                isEdited = true
            }
        }

        if ( ~[ 'webhook', 'sync-webhook' ].indexOf(trig.type) ) {

            if (
                trig?.options?.['method'] !== store.trigger.options?.['method']
            ) {
                isEdited = true
            }
            else {
                // query-auth json in string
                // header-auth json in string
                // "path" string
                // "method" string

                try {
                    const trigQuery = JSON.parse(trig.options?.['query-auth']);
                    const storeQuery = JSON.parse(store.trigger.options?.['query-auth']);
                    const trigHeaders = JSON.parse(trig.options?.['header-auth']);
                    const storeHeaders = JSON.parse(store.trigger.options?.['header-auth']);

                    if (
                        !comparingRecordObjects(trigQuery, storeQuery) || 
                        !comparingRecordObjects(trigHeaders, storeHeaders)
                    ) {
                        isEdited = true
                    }
                }
                catch(e) {
                    isEdited = true
                }
            }
        }

    }

    navyStore.chainPage.unsaved = isEdited;
})

const nameValidator = (name: string)=>{
    const nameLength = name.length;
    return nameLength < 150
}

// hack for name
const chainName = ref<string>(navyStore.chainPage?.name || '');
const addChainNameWatcher = () => watch(chainName, (newName)=>{
    if (!navyStore.chainPage) { return }
    if (nameValidator(newName) && newName.length > 1) {
        navyStore.chainPage.name = newName;
    }
    else {
        chainName.value = navyStore.chainPage.name;
        console.log('WTF!!!', navyStore.chainPage.name, chainName.value )
    }
});
let removeChainNameWatcher = addChainNameWatcher();

// restore options
const restoreOptionsHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    removeChainNameWatcher();

    if (!navyStore.chainPage) { return }

    navyStore.chainPage.name = store.pipelineName;
    navyStore.chainPage.enabled = store.status;
    navyStore.chainPage.options.trigger = JSON.parse(JSON.stringify(store.trigger));
    navyStore.chainPage.options.env = JSON.parse(JSON.stringify(store.env));
    navyStore.chainPage.unsaved = false;

    removeChainNameWatcher = addChainNameWatcher();
}
</script>

<template>
    <div class="chain-options-editor" v-if="navyStore.chainPage">

        <h2>{{ navyStore.chainPage.name || '&nbsp;' }}</h2>

        <p v-if="navyStore.chainPage.enabled" class="power-on">
            <i class="icon-off-1"></i>
            <b>powered ON</b>
        </p>
        <p v-else class="power-off">
            <i class="icon-off-1"></i>
            <b>powered OFF</b>
        </p>

        <p v-if="store.tested" class="tested">
            <i class="icon-ok-circled"></i>
            <b>tested</b>
        </p>
        <p v-else class="not-tested">
            <i class="icon-cancel-circled"></i>
            <b>not tested</b>
        </p>

        <router-link class="ui-btn" :to="{ name: routeNames.logs, query: { chain_id: navyStore.chainPage.chainId }}"> 
            <i class="icon-list-bullet"></i>
            <span>View logs</span>
        </router-link>

        <p>version: <b>{{ store.version }}</b></p>
        <p>updated: <b>{{ dateIsoToHumanDateTime(store.updatedAt) }}</b></p>
        <p>errors number: <b>{{ store.errorCounter }}</b></p>
        
        <div class="editable-options">

            <h3>Editable options</h3>

            <div class="form-group">
                <button 
                    class="ui-btn restore-options" 
                    :disabled="!navyStore.chainPage.unsaved"
                    @click="restoreOptionsHandler"
                >
                    <i class="icon-arrows-cw"></i>
                    <span>restore settings</span>
                </button>
            </div>
    
            <div class="form-group">
                <Label for="chain-options-editor-name">Chain`s name</Label>
                <QuestionViewerEditor 
                    v-model="chainName" 
                    :isEditable="true" 
                    :validator="nameValidator"
                    placeholder="Name" 
                    id="chain-options-editor-name"
                />
            </div>
            <div class="form-group power-option">
                <Label for="chain-options-editor-power">Power</Label>
                <Checkbox v-model="navyStore.chainPage.enabled" id="chain-options-editor-power" />
            </div>
            <div class="form-group">
                <Label for="chain-options-editor-trigger">Trigger</Label>
                <TriggerEditor v-model="navyStore.chainPage.options.trigger" />
            </div>
            <div class="form-group">
                <Label for="chain-options-editor-env">Envs</Label>
                <EnvEditor v-model="navyStore.chainPage.options.env" />
            </div>
        </div>

    </div>
    
</template>

<style lang="scss">

.chain-options-editor {
    $color-success: #08bb08;
    $color-error: #d10707;
    $color-dark: #1e2324;

    box-sizing: border-box;

    h2 {
        font-size: 22px;
        line-height: 27px;
        margin: 10px 0 20px;
        color: #fff;
        letter-spacing: 0.5px;
    }

    p {
        margin: 15px 0;

        i + b {
            padding-left: 7px;
        }
    }

    .power-on {
        color: $color-success;
    }

    .tested {
        color: $color-success;
    }

    .not-tested {
        color: $color-error;
    }

    .editable-options {
        margin: 50px 0 30px;
        max-width: 900px;

        h3 {
            margin-bottom: 20px;
            color: #fff;
        }
    }

    .power-option {
        width: 60px;
    }

    .restore-options {

        padding-left: 0;
        width: 140px;

        &:disabled {
            background-color: inherit;
            cursor: not-allowed;
            color: gray;
        }
    }
}
</style>