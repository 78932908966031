<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
// components
import Loader from '@/components/Loader.vue';
// ace editor
import { getJsEditor, isJSEditorCodeValid } from "../ace";
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


// get active tab worker
const getActiveWorker = () => navyStore.chainPage?.workersList.find(i => i.active);
const getStoreActiveWorker = () => {
    if (!worker.value) { return void 0 }
    const id = worker.value.id;
    return store.jobs?.find(i => i.id === id);
}
const worker = ref(getActiveWorker());
const storeWorker = ref(getStoreActiveWorker());
watch(getActiveWorker, (aw) => {
    worker.value = aw;
    storeWorker.value = getStoreActiveWorker();

    if (mountedBox.value) {
        setupEditor();
    }
});

// editor
const mountedBox = ref<HTMLDivElement>();

// editor
const editor = getJsEditor(() => {
    // await hack
    const annotations = editor.getSession().getAnnotations();
    const errorCheck = 'Missing ";"';

    const isAwaitAnnotation = (i: typeof annotations[number]) => {
        if (i.row === void 0) { return false }
        return /await\s+\S+\(/.test(editor.getSession().getLine(i.row));
    }

    const awaitAnnotation = annotations.find(i => i.text.includes(errorCheck) && isAwaitAnnotation(i));
    if (awaitAnnotation) {
        editor.getSession().setAnnotations(
            annotations.filter(i => !isAwaitAnnotation(i))
        );
    }

    if (worker.value) {
        const code = editor.getValue();
        if (worker.value.options.code !== code) {
            removeUpNavyStoreCodeWatcher();
            worker.value.options.code = code;
            worker.value.options.codeInvalid = !isJSEditorCodeValid(editor);
            removeUpNavyStoreCodeWatcher = setUpNavyStoreCodeWatcher();
        }
    }
});
// editor;

const codeEditorLoading = ref<boolean>(true);

const setupEditor = async () => {
    if (!worker.value) { return }
    if (mountedBox.value) {

        codeEditorLoading.value = false;

        editor.setValue(worker.value.options.code);
        mountedBox.value.appendChild(editor.box);

        if (worker.value?.editor?.cursor) {
            editor.moveCursorToPosition(worker.value.editor.cursor);
        }

        editor.clearSelection();
        editor.focus();
    }
}

onMounted(() => {
    if (!worker.value || !storeWorker.value) { return }
    setupEditor();
});

onUnmounted(() => {
    if (!worker.value) { return }

    if (worker.value?.editor?.cursor) {
        worker.value.editor.cursor = editor.getCursorPosition();
    }
    else {
        worker.value.editor = worker.value.editor ?? {};
        worker.value.editor.cursor = editor.getCursorPosition();
    }
});

const setUpNavyStoreCodeWatcher = ()=>watch(()=>[worker.value?.options.code], ([code])=>{
    if (typeof code !== 'string') { return }

    if (code !== editor.getValue()) {
        editor.setValue(code, 0);
        editor.clearSelection();
        editor.focus();
    }
});

let removeUpNavyStoreCodeWatcher = setUpNavyStoreCodeWatcher();
</script>


<template>
    <div class="worker-code-editor" v-if="worker && storeWorker">
        <div class="worker-code-editor-box" ref="mountedBox">
            <Loader v-show="codeEditorLoading" />
        </div>
    </div>
</template>

<style lang="scss">
.worker-code-editor {

    position: relative;
    height: calc(100vh - 150px);

    .loader .loader-box {
        z-index: 999;
    }
}

.--console-opened {
    .worker-code-editor {
        height: calc(100vh - 380px);
    }

    .--split {
        .worker-code-editor {
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            .worker-code-editor {
                height: 50vh;
            }
        }
    }
}

.worker-code-editor-box {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    // background: gray;

    .ace_editor {
        width: 100%;
        height: 100%;
        font: 12px / normal 'Ubuntu Mono', monospace;
    }

    .ace-monokai {
        color: #aeb3b4;
        background: #1e2324;

        .ace_print-margin {
            display: none;
        }

        .ace_entity.ace_name.ace_tag,
        .ace_keyword,
        .ace_meta.ace_tag,
        .ace_storage {
            color: #d10707;
        }

        .ace_gutter {
            background: #2c3233;
            color: #aeb3b4;
            font-family: 'ubuntu-light';
        }

        .ace_storage.ace_type,
        .ace_support.ace_class,
        .ace_support.ace_type {
            color: #07658e;
        }

        .ace_entity.ace_name.ace_function,
        .ace_entity.ace_other,
        .ace_entity.ace_other.ace_attribute-name,
        .ace_variable {
            color: #08bb08;
        }

        .ace_identifier {
            color: #7edff2;
        }

        .ace_punctuation,
        .ace_punctuation.ace_tag {
            color: #aeb3b4;

            // & + .ace_identifier {
            //     // color: red
            // }
        }

        .ace_string {
            color: #aeb3b4;
        }

        .ace_comment {
            color: #7b7b7b;
        }
    }
}

.lalala {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity .3s;
}
</style>