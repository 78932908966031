<script setup lang="ts">
import { defineProps } from 'vue';
import { ref, defineEmits, watch } from 'vue';
import { getUniqId } from '@/utils';
import { getValueFromEvent } from '@/components/kit';


interface IQuestionViewerEditor {
    isEditable?: boolean;
    label?: string;
    modelValue: string;
    // eslint-disable-next-line
    validator?: (str: string) => boolean;
    type?: 'textarea';
    secure?: boolean;
}

// props
const props = defineProps<IQuestionViewerEditor>();
const inputId = getUniqId();
const isValid = props.validator || (()=>{return true});
const isValueValid = ref<boolean>(isValid(props.modelValue));

// actions
const emits = defineEmits(['update:modelValue']);
watch(()=>props.modelValue, ()=>{
    const modelValue = props.modelValue
    value.value = modelValue;
    prevValue.value = modelValue;
    isValueValid.value = isValid(value.value);
    isEditorOpened.value = false;
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const inputRef = ref<HTMLElement>();
watch(inputRef, ()=>{
  if (inputRef.value) {
    const element = document.body.querySelector('#' + inputId);
    if (element) {
      // eslint-disable-next-line
      element?.focus();
      element.addEventListener('keydown', function(event: any) {
        if (event.key === "Escape") {
          cancelBtnClickHandler(event as Event);
        }
      });
    }
  }
});

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
}

const saveValue = ()=>{
    if (!isValueValid.value) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const prevValue = ref<string>(props.modelValue);
const inputHandler = (e: Event) => {
    const eventValue = getValueFromEvent(e);

    if (isValid(eventValue)) {
        prevValue.value = eventValue;
        value.value = eventValue;
        isValueValid.value = true;
    }
    else {
        // возвращаем предыдущее значение
        value.value = prevValue.value;
        isValueValid.value = false;
    }
}

const value = ref<string>(props.modelValue ?? '');
</script>

<template>
  <div class="value-editor" :class="!isValueValid && 'value-editor-error'">
    <label :for="inputId" :class="props.isEditable && isEditorOpened && 'value-editor-editable'">{{ props.label ?? '' }}</label>
    <template v-if="props.isEditable">
      <div v-if="!isEditorOpened" class="value-editor-preview">
        <input 
          :value="value.replace(/\s+/g, ' ')"
          :type="props.secure ? 'password' : 'text'"
          disabled="true"
          class="form-control"
        />
        <button v-if="props.isEditable" @click="editBtnClickHandler" 
          class="btn btn-outline-secondary btn-icon" title="Edit">
          <i class="icon-pencil"></i>
        </button>
      </div>
      <form v-else @submit="submitHandler" class="value-editor-conrollers">
          <div class="value-editor-conrollers-input">
            <textarea v-if="props.type" :id="inputId" v-model="value" @input="inputHandler" class="form-control" ref="inputRef"></textarea>
            <input v-else :id="inputId" v-model="value" @input="inputHandler" type="text" class="form-control" ref="inputRef">
          </div>
          <div class="value-editor-conrollers-btn">
            <button @click="saveBtnClickHandler" :disabled="!isValueValid" class="btn btn-outline-success btn-icon" title="Apply">
              <i class="icon-ok"></i>
            </button>
            <button @click="cancelBtnClickHandler" class="btn btn-outline-danger btn-icon" title="Cancel">
              <i class="icon-cancel"></i>
            </button>
          </div>
      </form>
    </template>
    <input v-else :value="value.replace(/\s+/g, ' ')" disabled="true" class="form-control">

    <div class="value-editor-viewer">
      <slot />
    </div>
  </div>
</template>


<style lang="scss">
.value-editor {
  // margin: 20px 0;

  .btn.btn-icon {
    height: 43px;
    border-radius: 0;
    border: none;
    outline: none;
    background: #3b464b;
    color: #aeb3b4;
    transition-duration: 0.25s;
    cursor: pointer;
    width: 30px;

    &:hover {
      color: #fff;
      background-color: #3b464b99;
    }

    &:focus {
      color: #fff;
      background-color: #3b464b99;
    }
  }

  input {
    width: 100%;
  }
  
  textarea {
    width: 100%;
    resize: vertical;
    line-height: 17px;
    padding: 2px 10px;
    box-sizing: border-box;
    min-height: 43px;
  }

  .value-editor-viewer {
    display: none;
  }

  &.value-editor-error {
    color: #d10707;

    .value-editor-viewer {
      display: initial;
    }
  }

  label[for] {
    cursor: initial;
    color: #000007;
    margin-bottom: 5px;
  }

  label.value-editor-editable[for] {
    cursor: pointer;
  }

  .value-editor-preview {
    display: flex;
    input {
      width: 100%;
    }
  }
  .value-editor-conrollers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;

    .value-editor-conrollers-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: normal;
      align-content: normal;
    }

    .value-editor-conrollers-input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
}
</style>