<script setup lang="ts">
import { defineProps } from 'vue';

interface IWorkerIcon {
    text?: string;
    isBackground?: boolean;
}

const props = defineProps<IWorkerIcon>();

// const onlyLatin = (str: string)=>str.match(/[a-z0-9-_]{1,}/ig)?.join('') ?? '?' ;

// const getSvgBase64 = (text: string, isBackground?: boolean )=>{
//     // <!-- <rect x="0" y="0" width="100" height="100" fill="#FFCA28"/> -->
//     // <!-- <rect x="0" y="0" width="100" height="100" fill="#3E3E3E"/> -->

//     return `<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
// <rect x="0" y="0" width="100" height="100" fill="${ isBackground ? '#3E3E3E' : 'none' }" />
// <text x="100" y="90" font-family="ubuntu-light" font-size="70" fill="#019ee3" text-anchor="end">
// <tspan fill="#019ee3">${ (text || '!')[0] }</tspan>
// <tspan fill="#019ee3" font-size="50" >${ (text ?? '   ').slice(1) }</tspan>
// </text>
// </svg>`;
// }

// const getBase64 = (str: string, isBackground?: boolean) => {
//     return `data:image/svg+xml;base64,${btoa(getSvgBase64(onlyLatin(str), isBackground))}`;
// }
</script>

<template>

    <!-- <img :src="getBase64(props.text || '?', props.isBackground)" :title="props.text" :alt="props.text" /> -->

    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="100" height="100" :fill="isBackground ? '#3E3E3E' : 'none'" />
        <text x="100" y="90" font-family="ubuntu-light" font-size="70" fill="#019ee3" text-anchor="end">
            <tspan fill="#019ee3">{{ (text || '!')[0] }}</tspan>
            <tspan fill="#019ee3" font-size="50" >{{ (props?.text ?? '   ').slice(1) }}</tspan>
        </text>
    </svg>

</template>