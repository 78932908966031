<script setup lang="ts">
import { ref, watch } from 'vue';
import { converWorkerType, typeNameHelper, comparingRecordObjects } from '@/utils';
// components
import { WorkerIcon } from '@/modules/NewPage/components';
import { QuestionViewerEditor } from "@/components/kit/QuestionViewer";
import { Label, Checkbox, Input } from '@/components/kit';
import EnvEditor from "./EnvEditor.vue";
// store
import { usePipelineStore } from '../store';
import { useNavyStore } from '@/store';
const store = usePipelineStore();
const navyStore = useNavyStore();


// get active tab worker
const getActiveWorker = () => navyStore.chainPage?.workersList.find(i => i.active);
const getStoreActiveWorker = () => {
    if (!worker.value) { return void 0 }
    const id = worker.value.id;
    return store.jobs?.find(i => i.id === id);
}
const worker = ref(getActiveWorker());
const storeWorker = ref(getStoreActiveWorker());
watch(getActiveWorker, (aw) => { 
    worker.value = aw;
    storeWorker.value = getStoreActiveWorker();
});

// edit options
watch(()=>[
    worker.value?.name, 
    worker.value?.enabled,
    worker.value?.options.env,
    worker.value?.options.code,
], ( [ name, enabled, env, code ] ) => {
    if (!worker.value) { return }
    if (!storeWorker.value) { return }

    let isEdited = false;

    if (name !== storeWorker.value.name) { isEdited = true }
    if (enabled !== storeWorker.value.enabled) { isEdited = true }

    if (!comparingRecordObjects(env as Record<string, string>, storeWorker.value.env)) {
        isEdited = true;
    }

    if (code !== storeWorker.value.code) { isEdited = true }

    worker.value.unsaved = isEdited;
})

// restore options
const restoreOptionsHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!worker.value || !storeWorker.value) { return }

    worker.value.name = storeWorker.value.name;
    worker.value.enabled = storeWorker.value.enabled;
    worker.value.options.env = JSON.parse(JSON.stringify(storeWorker.value.env));
    worker.value.options.code = storeWorker.value.code;
}

// helpers
const nameValidator = (name: string)=>{
    const nameLength = name.length;
    return nameLength < 150
}
</script>


<template>
     <div class="worker-options-editor" v-if="worker && storeWorker">

        <h2>{{ worker.name || '&nbsp;' }}</h2>

        <p v-if="worker.enabled" class="power-on">
            <i class="icon-off-1"></i>
            <b>powered ON</b>
        </p>
        <p v-else class="power-off">
            <i class="icon-off-1"></i>
            <b>powered OFF</b>
        </p>

        <p v-if="storeWorker.tested" class="tested">
            <i class="icon-ok-circled"></i>
            <b>tested</b>
        </p>
        <p v-else class="not-tested">
            <i class="icon-cancel-circled"></i>
            <b>not tested</b>
        </p>

        <div class="worker-type" :title="typeNameHelper(storeWorker.type)">
            <WorkerIcon :text="converWorkerType(storeWorker.type)" class="icon" />
            <span>{{ typeNameHelper(storeWorker.type) }}</span>
        </div>

        <div class="editable-options">

            <h3>Editable options</h3>

            <div class="form-group">
                <button 
                    class="ui-btn restore-options" 
                    :disabled="!worker.unsaved"
                    @click="restoreOptionsHandler"
                >
                    <i class="icon-arrows-cw"></i>
                    <span>restore settings</span>
                </button>
            </div>

            <div class="form-group">
                <Label for="worker-options-editor-name">Chain`s name</Label>
                <QuestionViewerEditor 
                    v-model="worker.name" 
                    :isEditable="true" 
                    :validator="nameValidator"
                    placeholder="Name" 
                    id="worker-options-editor-name"
                />
            </div>
            <div class="form-group power-option">
                <Label for="worker-options-editor-power">Power</Label>
                <Checkbox v-model="worker.enabled" id="worker-options-editor-power" />
            </div>
            
            <div class="form-group">
                <Label for="worker-options-editor-env">Envs</Label>
                <EnvEditor v-model="worker.options.env" />
            </div>

            <div class="form-group">
                <Label for="worker-options-editor-power">Input Data</Label>
                <Input type="textarea" v-model="storeWorker.data" disabled/>
            </div>
        </div>

    </div>
</template>


<style lang="scss">

.worker-options-editor {
    $color-success: #08bb08;
    $color-error: #d10707;
    $color-dark: #1e2324;

    box-sizing: border-box;

    h2 {
        font-size: 22px;
        line-height: 27px;
        margin: 10px 0 20px;
        color: #fff;
        letter-spacing: 0.5px;
    }

    p {
        margin: 15px 0;

        i + b {
            padding-left: 7px;
        }
    }

    .power-on {
        color: $color-success;
    }

    .tested {
        color: $color-success;
    }

    .not-tested {
        color: $color-error;
    }

    .editable-options {
        margin: 50px 0 30px;
        max-width: 900px;

        h3 {
            margin-bottom: 20px;
            color: #fff;
        }
    }

    .power-option {
        width: 60px;
    }

    .restore-options {

        padding-left: 0;
        width: 140px;

        &:disabled {
            background-color: inherit;
            cursor: not-allowed;
            color: gray;
        }
    }

    .worker-type {
        display: flex;
        align-items: end;
        margin: 15px 0;

        .icon {
            width: 35px;
            height: 35px;
            margin-right: 10px;
        }

        span {

        }
    }
}
</style>