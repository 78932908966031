<script setup lang="ts">
import { ref } from 'vue';
import { router, routeNames } from '@/router';
// components
import DeskTop from './DeskTop.vue';
import DeskTopHeader from './DeskTopHeader.vue';
import ChainsTable from '@/modules/pipeline/components/PipelineTable.vue';
import Loader from '@/components/Loader.vue';
// store
import { createPipeline } from '@/modules/pipeline/store';


// create chain
const createChainLoading = ref<boolean>(false);

const addBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const isCreateConfirmed = window.confirm('Сonfirm the creation of a new chain.')

    if (!isCreateConfirmed) { return }

    // create new chain
    createChainLoading.value = true;

    createPipeline()
        .then((newChainID)=>{
            router.push({ name: routeNames.chainEditor, params: { chainID: newChainID } })
        })
        .catch((e)=>{
            alert('An error occurred while creating a new chain!');
            console.log('An error occurred while creating a new chain!', e);
        })
        .finally(()=>{
            createChainLoading.value = false;
        })
}

</script>


<template>
    <DeskTop>
        <DeskTopHeader>
            <h1>Chains list</h1>
        </DeskTopHeader>

        <div class="options-bar">
            <div class="options-bar-box">
                <div class="box">
                    <div class="box-title">
                        <h2>Chains list</h2>
                    </div>

                    <div class="box-body">
                        
                        <ChainsTable v-show="!createChainLoading" />

                        <div class="create-chain-btn" v-show="!createChainLoading">
                            <button 
                                @click="addBtnClickHandler"
                                class="ui-btn"
                            >
                                <i class="icon-plus-squared-alt"></i>
                                <span>Сreate a new chain</span>
                            </button>
                        </div>
                        
                        <Loader v-if="createChainLoading" />
                    </div>
                </div>
            </div>
        </div>
    </DeskTop>
</template>

<style lang="scss">
.create-chain-btn {
    display: flex;
    margin: 20px 0;
}
</style>