import { 
    generateAsyncDataTableStore, 
    definePromiseQueue,
    TAsyncDataTableStore, 
} from '@/components/Table/tableStore';
import Service from '../service';


export type TTablePipeline = {
    id: string;
    status: boolean;
    name: string;
    version: string;
    error_counter: number;
}

type TTableGetPipelineListRes = {
    list: TTablePipeline[];
    pagination: {
        page: number;
        pageSize: number;
        pageCount: number;
        total: number;
    };
}


const asyncPromiseQueue = definePromiseQueue<TTableGetPipelineListRes>(300);


export const useTablePipelineStore = generateAsyncDataTableStore<TTablePipeline>({
    initialState: {    
        paginateInfoTpl: '_DATA_ of _TOTAL_',
        loading: true
    },

    onDataStateChange(useStore) {
        setTimeout(() => {
            
            const store = useStore();
            if (store.viewData.length === 0) { return }
            if (store.viewData.length < store.paginatePerPage) {
                const tbody = document.body.querySelector('.chainslist-page-table tbody');
                if (tbody) {
                    const colspan = store.columns.length + '';
                    const trNumber = store.paginatePerPage - store.viewData.length;

                    new Array(trNumber)
                        .fill(1)
                        .forEach(() => {
                            const td = document.createElement('td');
                            td.setAttribute('colspan', colspan);
                            td.innerHTML = '&nbsp;'
                            const tr = document.createElement('tr');
                            tr.className = 'border-color-unset';
                            tr.appendChild(td);
                            tbody!.appendChild(tr);
                        });
                }
            }
        })
    },

    dataGetter: async (useStore) => {
        const store = useStore();

        await asyncPromiseQueue.wait(getData, useStore)
        .then((r)=>{
            // устанавливаем данные стора
            store.data = r.list;
            store.paginateTotal = r.pagination.total;
            store.paginateFiltered = r.pagination.total; // no pagination.filtered from backend. Temp fix
        })
        .catch((e: Error)=>{
            // выводим ошибку
            // если ошибка не по таймауту, то чистим стор
            if (e) {
                store.data = [];
                store.paginateTotal = 0;
                store.paginateFiltered = 0;
            }
        })
    }
});


export const createPipeline = async (): Promise<string> => {
    console.log("create")
    return (await Service.createPipeline()).id;
}

const getData = async (useStore: TAsyncDataTableStore<any>): Promise<TTableGetPipelineListRes> => {
    // store
    const store = useStore();
    
    // get data
    const query = {
        page: store.paginatePage, 
        per_page: store.paginatePerPage
    }
    const data = await Service.getPipelineList(query);
    // mapping
    return {
        list: data.list.map(item => {
            return {
                id: item.id,
                name: item.name,
                status: item.enabled,
                error_counter: item.error_counter,
                version: item.version
            }
        }),
        pagination: {
            page: data.pagination.page,
            pageCount: data.pagination.page_count,
            pageSize: data.pagination.page_size,
            total: data.pagination.total
        }
    }
}