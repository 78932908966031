<script setup lang="ts">
import { defineProps, ref, defineEmits, watch } from 'vue';
import { getUniqId } from '@/utils';
import { Select } from '@/components/kit';


// utils
const getUsedValues = (): IQuestionViewerEditorSelect['options'] => {
    if (props.multiple) {
        return Object.entries(props.options)
            .filter(item => ~(value.value as TValue[]).indexOf(item[1].value))
            .map(item => item[1]);
    }
    const item = Object.entries(props.options).find(item => item[1].value === value.value)?.[1];
    return item ? [item] : [];
}

const getUnusedValues = (): string[] => {
    if (props.multiple) {
        if (!(value.value as TValue[]).length) {
            return [];
        }
    }
    else if (!value.value) {
        return [];
    }

    const used = getUsedValues();

    if (props.multiple) {
        return (value.value as TValue[])
            .filter(item => !used.find(i => i.value === item))
            .map(i => i + '')
    }
    else {
        if (used[0] && used[0].value === (value.value + '')) {
            return [];
        }
        return [value.value + ''];
    }
}

const validateSelect = (): boolean => {
    // валидно если не обязателен и пустой
    if (!props.required) {
        if (props.multiple && (value.value as TValue[])?.length === 0) {
            return true;
        }
        else if (value.value === null) {
            return true;
        }
    }
    // должен быть 1им из списка
    if (props.multiple) {
        return getUsedValues().length === (value.value as TValue[])?.length;
    }
    return Boolean(getUsedValues().length);
}


type TValue = string | boolean | number | null | undefined;

interface IQuestionViewerEditorSelect {
    isEditable?: boolean;
    label?: string;

    modelValue: TValue | TValue[];
    options: {
        label: string;
        value: TValue;
    }[];

    required?: boolean;
    multiple?: boolean;
    noSearchable?: boolean;
    nothingFoundText?: string;
}

const getValueHalper = (): TValue | TValue[] => {
    return props.modelValue === null ? null : (props.modelValue || (props.multiple ? [] : ''));
}

// props
const props = defineProps<IQuestionViewerEditorSelect>();
const inputId = getUniqId();
const value = ref<TValue | TValue[]>(getValueHalper());
const unusedValues = ref<string[]>(getUnusedValues());
const isValueValid = ref<boolean>(validateSelect());

// actions
const emits = defineEmits(['update:modelValue']);
watch(() => props.modelValue, () => {
    value.value = getValueHalper();
    isValueValid.value = validateSelect();
    unusedValues.value = getUnusedValues();
    isEditorOpened.value = false;
});

watch(value, () => {
    isValueValid.value = validateSelect();
    unusedValues.value = getUnusedValues();
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
}

const saveValue = () => {
    if (!isValueValid.value) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}


// // methods
// const filter = (options: IQuestionViewerEditorSelect['options'], search: string) => {
//     const regexp = new RegExp(search, 'i');

//     return options.filter((item) => {
//         if (typeof item.value === 'number' || typeof item.value === 'string') {
//             const itemValue = item.value + '';
//             return regexp.test(item.label) || regexp.test(itemValue)
//         }
//         return regexp.test(item.label);
//     })
// }

// const reduce = (option: IQuestionViewerEditorSelect['options'][number]) => {
//     if (option && !option?.value) { return option }
//     return option.value && (option.value + '') || '';
// }

const getValueLabel = (): string => {
    if (props.multiple) {
        return getUsedValues()
            .map(item => item.label)
            .join(', ');
    }
    const val = getUsedValues()?.[0];
    return val ? val.label : '';
}


const inputRef = ref<HTMLElement>();
watch(inputRef, ()=>{
  if (inputRef.value) {
    const element = document.body.querySelector('#' + inputId);
    if (element) {
      // eslint-disable-next-line
      element?.focus();
      element.addEventListener('keydown', function(event: any) {
        if (event.key === "Escape") {
          cancelBtnClickHandler(event as Event);
        }
      });
    }
  }
});
</script>
  
<template>
  <div class="value-editor value-editor-select" :class="!isValueValid && 'value-editor-error'">
    <label :for="inputId" :class="props.isEditable && isEditorOpened && 'value-editor-editable'">
      {{ props.label ?? ''}}
    </label>

    <template v-if="props.isEditable">
      <div v-if="!isEditorOpened" class="value-editor-preview">
        <input :value="getValueLabel()" disabled="true" class="form-control">
        <button v-if="props.isEditable" @click="editBtnClickHandler" class="btn btn-outline-secondary btn-icon"
          title="Edit">
          <i class="icon-pencil"></i>
        </button>
      </div>
      <form v-else @submit="submitHandler" class="value-editor-conrollers">
        <div class="value-editor-conrollers-input">
          <Select 
            v-model="value"
            :id="inputId"
            :options="props.options"
            :multiple="props.multiple"
            :noSearch="props.noSearchable"
            ref="inputRef"
          />
        </div>

        <div class="value-editor-conrollers-btn">
          <button @click="saveBtnClickHandler" :disabled="!isValueValid" class="btn btn-outline-success btn-icon"
            title="Apply">
            <i class="icon-ok"></i>
          </button>
          <button @click="cancelBtnClickHandler" class="btn btn-outline-danger btn-icon" title="Сancel">
            <i class="icon-cancel"></i>
          </button>
        </div>
      </form>
    </template>
    <template v-else>
      <input :value="getValueLabel()" disabled="true" class="form-control form-control-sm">
    </template>

    <p v-if="unusedValues.length">Invalid values: {{ unusedValues.join(', ') }}</p>
    <div class="value-editor-viewer">
      <slot />
    </div>
  </div>
</template>
  
<style lang="css">
@import 'vue-select/dist/vue-select.css';
</style>

<style lang="scss">
.value-editor-select {
  margin: 20px 0;

  .btn.btn-icon {
    height: 2.875rem;
  }

  .value-editor-viewer {
    display: none;
  }

  &.value-editor-error {
    color: #d10707;

    .value-editor-viewer {
      display: initial;
    }
  }

  label {
    color: #000007;
    margin-bottom: 5px;
  }

  label[for] {
    cursor: initial;
  }

  label.value-editor-editable[for] {
    cursor: pointer;
  }

  .value-editor-preview {
    display: flex;
  }

  .value-editor-conrollers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;

    .value-editor-conrollers-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: normal;
      align-content: normal;
    }

    .value-editor-conrollers-input {
      width: 100%;
      .vs__selected-options {
          flex-grow: initial;
          flex-wrap: initial;
      }

      .vs__dropdown-toggle {
        min-height: 46px;
      }

      .vs__selected {
        min-width: 100px;
      }
    }
  }

  

}
</style>