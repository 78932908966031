<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import Tr from './Tr.vue';
import Column from './Column.vue';
// types
import { ITableRow } from './types';


const props = defineProps<ITableRow>();
defineComponent({ name: 'table-row' });

const componentMode = Boolean(props.component);
</script>

<template>
    <component
        v-if="componentMode"
        :is="props.component"
        
        :class="props.class || ''"
        :rowData="props.rowData"
        :rowIndex="props.rowIndex"
    >
        <Column
            v-for="(colData, ck) of props.columns" 
            :key="ck"
            :class="colData?.cellClass || ''"

            :dataKey="colData.key"
            :component="colData.component"
            :render="colData.render"
            :value="colData.key && props.rowData?.[colData.key] || undefined"

            :rowData="props.rowData"
            :rowIndex="props.rowIndex"
            
            :devMode="props.devMode"
        />
    </component>

    <Tr 
        v-else
        :class="props.class || ''"
    >
        <Column
            v-for="(colData, ck) of props.columns" 
            :key="ck"
            :class="colData?.cellClass || ''"

            :dataKey="colData.key"
            :component="colData.component"
            :render="colData.render"
            :value="colData.key && props.rowData?.[colData.key] || undefined"

            :rowData="props.rowData"
            :rowIndex="props.rowIndex"
            
            :devMode="props.devMode"
        />
    </Tr>
</template>